import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const CFInput = ({
  type = "text",
  name,
  value: initialValue = "",
  placeholder,
  maxLength,
  onChange,
  onBlur,
  onFocus,
  alphanumeric,
  snakecase,
  numericWithPercentage,
  noCommas,
  updateOnInputEnd = false,
  inputEndDelay = 1000,
  className = "",
}) => {
  const [value, setValue] = useState(initialValue);
  const [timeoutId, setTimeoutId] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const formatSpacingValueInput = (value) => {
    let formattedValue = value.replace(/[^0-9+%-.]+/gi, "");

    if (typeof formattedValue === 'string' && isValuePresent(formattedValue)) {
      formattedValue = String(parseFloat(formattedValue));
    }

    const percentageIndex = value.indexOf('%');
    if (percentageIndex !== -1 && percentageIndex !== formattedValue.length - 1) {
      formattedValue = formattedValue.replace(/%/g, '') + '%';
    }

    const negativeIndex = formattedValue.indexOf('-');
    if (negativeIndex > 0) {
      formattedValue = formattedValue.replace(/-/g, '');
      formattedValue = '-' + formattedValue;
    }

    return formattedValue;
  };

  const formatValue = (inputValue) => {
    let formattedValue = inputValue;

    if (alphanumeric) {
      formattedValue = formattedValue.replace(/[^a-z0-9+ _-]+/gi, "_");
    } else if (snakecase) {
      formattedValue = formattedValue.replace(/[^a-z0-9+]+/gi, "_");
    } else if (numericWithPercentage) {
      formattedValue = formatSpacingValueInput(formattedValue);
    } else if (noCommas) {
      formattedValue = formattedValue.replace(/,/g, "");
    }

    return formattedValue;
  };

  const handleChange = (e) => {
    const formattedValue = formatValue(e.target.value);
    setValue(formattedValue);

    if (updateOnInputEnd) {
      clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(() => {
        onChange?.(formattedValue);
      }, inputEndDelay);
      setTimeoutId(newTimeoutId);
    } else {
      onChange?.(formattedValue);
    }
  };

  const handleBlur = (e) => {
    const formattedValue = formatValue(e.target.value);
    setValue(formattedValue);
    onBlur?.(formattedValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const formattedValue = formatValue(e.target.value);
      setValue(formattedValue);
      onChange?.(formattedValue);
    }
  };

  const defaultClassName = "w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none";

  return (
    <input
      ref={inputRef}
      type={type}
      name={name}
      value={value}
      maxLength={maxLength}
      className={`${defaultClassName} ${className}`.trim()}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
    />
  );
};

CFInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  alphanumeric: PropTypes.bool,
  snakecase: PropTypes.bool,
  numericWithPercentage: PropTypes.bool,
  noCommas: PropTypes.bool,
  updateOnInputEnd: PropTypes.bool,
  inputEndDelay: PropTypes.number,
  className: PropTypes.string,
};

export default CFInput;
