import React from "react";
import useActions from "../actions/useActions";
import ActionForm from "./ActionForm";

const ActionListItem = (props) => {
  const { currentActionsHook } = useActions(props);
  const Actions = currentActionsHook;

  const object = props.object;
  const actionsList = props.actionsList;
  const website = props.website;
  const cta = object.data && object.data.cta_id ? website.ctas[object.data.cta_id] : null;

  return (
    <React.Fragment>
      <div
        className="col-xs-12 no-padding-sides flex"
        data-object-type="actions"
        data-object-id={object.id}
      >
        <div className="flex-grow">
          <ActionForm
            actionsList={actionsList}
            website={website}
            action={object}
            cta={cta}
            {...props}
          />
        </div>

        <div className="w-[40px] pl-3 flex gap-x-2 cf-item-controls no-padding-right">
          <button
            title="Duplicate"
            className={`h-[30px] p-0 px-2 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
            onClick={() => props.onDuplicate(object)}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.25 9.84375L14.25 4.49994C14.25 3.25729 13.2426 2.24992 12 2.24994L6.65625 2.25M9.75 15.75L4.6875 15.75C3.75552 15.75 3 14.9945 3 14.0625L3 6.75C3 5.81802 3.75552 5.0625 4.6875 5.0625L9.75 5.0625C10.682 5.0625 11.4375 5.81802 11.4375 6.75L11.4375 14.0625C11.4375 14.9945 10.682 15.75 9.75 15.75Z"
                stroke={props.dark_mode ? "white" : "black"}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </button>

          <button
            title="Remove"
            className={`h-[30px] p-0 px-2 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
            onClick={() =>
              Actions.remove(object, true, () => {
                props.onRemove(object.id);
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 4.63235H15M6.75 2.25H11.25M7.5 12.5735V7.80882M10.5 12.5735V7.80882M11.625 15.75H6.375C5.54657 15.75 4.875 15.0389 4.875 14.1618L4.53255 5.45953C4.5148 5.00837 4.85544 4.63235 5.2819 4.63235H12.7181C13.1446 4.63235 13.4852 5.00837 13.4674 5.45953L13.125 14.1618C13.125 15.0389 12.4534 15.75 11.625 15.75Z"
                stroke={props.dark_mode ? "white" : "black"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActionListItem;
