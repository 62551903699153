import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import NewActionGroup from "../action_groups/NewActionGroup";
import ActionGroupSettings from "../action_groups/ActionGroupSettings";
import ActionsBuilder from "../actions/ActionsBuilder";
import FieldSettingsStepMapping from "../fields/FieldSettingsStepMapping";
import Tooltip from "../shared/Tooltip";

const ElementSettingsConfirmation = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const [displayAdvancedLogic, setDisplayAdvancedLogic] = useState(false);

  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];
  let action_groups = Object.values(variant.action_groups).filter(
    (action_group) =>
      action_group.element_id == element.id &&
      action_group.toBeDeleted !== true &&
      !action_group.field_id
  );

  let field;

  if (element.element_type == "survey") {
    field = Object.values(variant.fields).filter(
      (field) => field.element_id == element.id && field.toBeDeleted !== true
    )[0];
  }

  let redirectSteps;
  if (element.options["confirmation-type"] == "step" || element.options["products-button-action-after-add-to-cart"] == "step") {
    redirectSteps = [
      {
        value: "",
        text: "Select a step",
        disabled: true,
      },
    ];

    Object.values(variant.steps)
      .filter((s) => s.id !== step.id && s.toBeDeleted !== true)
      .sort((a, b) => {
        return a.position - b.position;
      })
      .forEach((s) => {
        redirectSteps.push({
          value: s.id + "",
          text:
            "Step " +
            s.position +
            (s.options["name"] ? " - " + s.options["name"] : ""),
        });
      });

    if (step.position > 1) {
      redirectSteps.push({
        value: "previous",
        text: "Jump back to previous step",
      });
    }
  }

  function integrationAdded() {
    document.addEventListener("integrationAdded", function (event) {
      let integration = event.detail.integration;
      let newContextData = { ...contextData };
      newContextData.objects.website.integrations[integration.id] = integration;
      setContextData(newContextData);
    });

    $(document).on("shown.bs.modal", ".integration-stacked-modal", function () {
      $("#integrations, #settingsModal").modal("hide");
    });

    $(document).on(
      "hidden.bs.modal",
      ".integration-stacked-modal",
      function () {
        $("#integrations").modal("show");
      }
    );
  }

  const [productHandlesDropdown, setProductHandlesDropdown] = useState([
    {
      value: "",
      text: "Select a product",
    },
  ]);

  const [productVariantsDropdown, setProductVariantsDropdown] = useState([
    {
      value: "",
      text: "Select product variant",
    },
  ]);

  useEffect(() => {
    const integration = ecommerce_integrations.find((integration) => {
      return integration.value == element.options["selected-store-integration"]
    })?.integration;

    if (["Shopify"].includes(element.options["selected-store-integration"])) {
      const cached_products = window.cf_shopify_products;
      if (cached_products) {
        loadShopifyGraphqlProducts(cached_products);
        return;
      }

      new ProductsIntegrationsService(element.options["selected-store-integration"], integration)
        .adapter()
        .getProducts({ sort: element.options["shopify-products-sorting"] })
        .then((response) => {
          window.cf_shopify_products = response;
          loadShopifyGraphqlProducts(response);
        })
    }
  }, [element.options["selected-store-integration"]]);

  const loadShopifyGraphqlProducts = (products) => {
    let tempProductsDropdown = [];
    let tempHandlesDropdown = [];
    let tempProductVariantsDropdown = [];
    let orderedProducts = products.sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    const defaultSelect = { value: "", text: "Select a product", disabled: true }
    tempProductsDropdown.push(defaultSelect)
    tempHandlesDropdown.push(defaultSelect)

    orderedProducts.forEach((shopifyAdapter) => {
      tempProductsDropdown.push({
        value: shopifyAdapter.id,
        text: shopifyAdapter.title,
      });

      tempHandlesDropdown.push({
        value: shopifyAdapter.handle,
        text: shopifyAdapter.title,
      });

      shopifyAdapter.variants.forEach((productVariant) => {
        tempProductVariantsDropdown.push({
          value: productVariant.id,
          text: `${shopifyAdapter.title} ${
            productVariant.title.includes("Default Title")
              ? ""
              : " - " + productVariant.title
          }`,
        });
      });
    });

    setProductHandlesDropdown(tempHandlesDropdown);
    setProductVariantsDropdown(tempProductVariantsDropdown);
  };

  let ecommerce_integrations = $.map(website.integrations, function (integration) {
    let ecommerce_integration_types = ["Shopify"];

    if (ecommerce_integration_types.includes(integration.integration_type)) {
      let output = { value: integration.integration_type, text: integration.integration_type, integration: integration }

      if (integration.integration_type == "Shopify" && integration.api_data["storefront-token"]) {
        return output;
      } else if (integration.integration_type == "Shopify" && !integration.api_data["storefront-token"]) {
        return { ...output, text: 'Shopify - Reauthentication Required', disabled: true }
      } else {
        return output;
      }
    }
  });

  let actionAfterAddToCart = "cart";

  if (element.options["products-button-action-after-add-to-cart"]) {
    actionAfterAddToCart =
      element.options["products-button-action-after-add-to-cart"];
  } else if (element.options["products-cart-redirect"] == "true") {
    actionAfterAddToCart = "cart";
  } else if (element.options["products-cart-redirect"] == "false") {
    actionAfterAddToCart = "stay";
  }

  let productsButtonActionAfterAddToCartOptions = [
    {
      value: "cart",
      text: "Redirect to cart",
    },
  ];

  if (
    element.options["products-source"] == "ShopifyGraphql" ||
    element.options["products-source"] == "Shopify"
  ) {
    productsButtonActionAfterAddToCartOptions.push(
      { value: "checkout", text: "Redirect to checkout" },
      { value: "redirect", text: "Redirect to a URL" },
      { value: "step", text: "Jump to a funnel step" },
      { value: "cta", text: "Display a popup campaign" }
    );
  }

  const warning = ElementWarningsService.getWarning(element, variant);

  return (
    <React.Fragment>
      <div className="col-xs-12 no-padding-sides">
        <div className="w-full mb-3 relative text-black dark:text-white">
          <span className="text-xl">Default Action:</span>

          <Tooltip
            label="Learn"
            tooltip={`Defaults are performed when your ${
              element.element_type == "form"
                ? "form is submitted"
                : element.element_type == "quiz"
                ? "quiz is completed"
                : element.element_type == "button"
                ? "button is clicked"
                : element.element_type == "survey"
                ? "survey is submitted"
                : ["products", "product_hero"].includes(element.element_type)
                ? "product is selected"
                : ""
            }.`}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          setting_name="[default-action]"
          setting_type="item_v2"
          object_type="elements"
          object_id={element.id}
          title={
            element.options["confirmation-type"] == "step"
              ? "Jump to funnel step"
              : element.options["confirmation-type"] == "redirect"
              ? "Redirect to a URL"
              : element.options["confirmation-type"] == "checkout"
              ? "Redirect to checkout"
              : element.options["confirmation-type"] == "cta"
              ? "Display a popup campaign"
              : element.options["confirmation-type"] == "sms"
              ? "Subscribe via SMS code"
              : element.options["confirmation-type"] == "message"
              ? "Show a thank you message"
              : element.options["confirmation-type"] == "cart" && element.options["products-source"] !== "ConvertFlow"
              ? "Add product to cart"
              : element.options["confirmation-type"] == "product" || element.options["products-source"] == "ConvertFlow"
              ? "Redirect to product"
              : ""
          }
          style={{ margin: "4px 0px" }}
          label_max_length={32}
          label_hover_max_length={20}
          panel_type="modal"
          warning={warning}
          warningBorder={false}
          label_icon={
            element.options["confirmation-type"] == "step"
              ? "fa-solid fa-code-branch"
              : element.options["confirmation-type"] == "redirect"
              ? "fa-solid fa-link"
              : element.options["confirmation-type"] == "cart"
              ? "fa-solid fa-shopping-cart"
              : element.options["confirmation-type"] == "checkout"
              ? "fa-solid fa-credit-card"
              : element.options["confirmation-type"] == "cta"
              ? "fa-solid fa-message"
              : element.options["confirmation-type"] == "sms"
              ? "fa-solid fa-mobile"
              : element.options["confirmation-type"] == "message"
              ? "fa-solid fa-quote-left"
              : element.options["confirmation-type"] == "cart"
              ? "fas fa-cart-plus"
              : element.options["confirmation-type"] == "product"
              ? "fas fa-file-import"
              : ""
          }
          hide_handle={true}
        >
          <div className="col-xs-12">
            <div className="flex my-5 relative">
              <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
                <div className="w-full mt-[13px]">First</div>
                <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
              </div>

              <div className="w-[560px]">
                <VariantBuilderSetting
                  object={element}
                  label="Perform confirmation action"
                  setting_name="[options][confirmation-type]"
                  setting_type="dropdown"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["confirmation-type"]
                      ? element.options["confirmation-type"]
                      : element.options["redirect-step"] &&
                        variant.steps[element.options["redirect-step"]]
                      ? "step"
                      : element.options["redirect-url"]
                      ? "redirect"
                      : element.options["confirmation-cta"]
                      ? "cta"
                      : element.element_type == "form"
                      ? "message"
                      : element.element_type == "survey"
                      ? "step"
                      : element.element_type == "button"
                      ? "redirect"
                      : ""
                  }
                  options={[
                    { value: "", text: "Select a confirmation type", disabled: true },
                    { value: "redirect", text: "Redirect to a URL" },
                    { value: "cart", text: "Redirect to cart", disabled: ecommerce_integrations.length == 0 },
                    { value: "checkout", text: "Redirect to checkout", disabled: ecommerce_integrations.length == 0 },
                    { value: "step", text: "Jump to a funnel step" },
                    { value: "cta", text: "Display a popup campaign" },
                    { value: "sms", text: "Subscribe via SMS code" },
                    { value: "message", text: "Show a thank you message", condition: ["form", "survey", "quiz"].includes(element.element_type) },
                  ]}
                  condition={["products", "product_hero"].includes(element.element_type) == false}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Selected Store Integration"
                  setting_name="[options][selected-store-integration]"
                  setting_type="dropdown"
                  object_type="elements"
                  object_id={element.id}
                  value={element.options["selected-store-integration"]}
                  options={[
                    {
                      value: "",
                      text: "Select a store integration",
                      disabled: true
                    },
                    ...ecommerce_integrations
                  ]}
                  warning={
                    ecommerce_integrations.length == 0 || !element.options["selected-store-integration"]
                      ? "No ecommerce platform selected"
                      : undefined
                  }
                  condition={
                    !['products', 'product_hero'].includes(element.element_type) &&
                    ["cart", "checkout"].includes(
                      element.options["confirmation-type"]
                    ) && ecommerce_integrations.length > 0
                  }
                />

                <div className="col-xs-12 no-padding-sides">
                  <div className="flex gap-x-3 relative">
                    <div className="flex-grow min-w-[25%] max-w-[80%]">
                      <VariantBuilderSetting
                        object={element}
                        label="Product variants to add to cart"
                        note={element.options["selected-store-integration"] == "Shopify" ? "Pro tip: To add subscriptions to cart, add Shopify automations below" : ""}
                        setting_name="[options][selected-product-variant-ids]"
                        setting_type="select"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          element.options["selected-product-variant-ids"]
                            ? element.options["selected-product-variant-ids"]
                            : ""
                        }
                        options={productVariantsDropdown}
                        multi_select={true}
                        warning={
                          !element.options["selected-product-variant-ids"]
                            ? "No product variants selected to add to cart"
                            : undefined
                        }
                        condition={
                          !['products', 'product_hero'].includes(element.element_type) &&
                          ['cart', 'checkout'].includes(element.options["confirmation-type"]) &&
                          ecommerce_integrations.length > 0 &&
                          element.options["selected-store-integration"] !== null
                        }
                      />
                    </div>

                    <div className="flex-grow min-w-[25%] max-w-[20%]">
                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][selected-products-quantity]"
                        setting_type="number"
                        label="Quantity"
                        object_type="elements"
                        object_id={element.id}
                        condition={
                          !['products', 'product_hero'].includes(element.element_type) &&
                          ['cart', 'checkout'].includes(element.options["confirmation-type"]) &&
                          ecommerce_integrations.length > 0 &&
                          element.options["selected-store-integration"] !== null
                        }
                        value={element.options["selected-products-quantity"]}
                        placeholder="1"
                        min={1}
                        allow_empty={true}
                      />
                    </div>
                  </div>
                </div>

                <VariantBuilderSetting
                  object={element}
                  label="Jump to funnel step"
                  setting_name="[options][redirect-step]"
                  setting_type="dropdown"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["redirect-step"] &&
                    (element.options["redirect-step"] == "previous" ||
                      Object.values(variant.steps).filter(
                        (s) =>
                          s.id + "" == element.options["redirect-step"] &&
                          s.toBeDeleted !== true
                      ).length > 0)
                      ? element.options["redirect-step"]
                      : ""
                  }
                  options={redirectSteps}
                  condition={
                    (element.options["confirmation-type"] &&
                      element.options["confirmation-type"] == "step") ||
                    (!element.options["confirmation-type"] &&
                      element.options["redirect-step"])
                  }
                  warning={
                    !element.options["redirect-step"]
                      ? "Step to jump to not yet set"
                      : undefined
                  }
                  note={
                    field && field.multi_select + "" == "true"
                      ? "To enable selection paths for this survey, disable multi-select or use conditional actions."
                      : undefined
                  }
                />

                {element.element_type == "survey" &&
                  element.options["confirmation-type"] == "step" &&
                  field &&
                  field.multi_select + "" !== "true" && (
                    <React.Fragment>
                      <div className="col-xs-12 some-vertical-space">
                        <div className="row hard-center">
                          <div className="col-xs-5 !w-[190px] no-padding-sides relative mt-5 mb-5 text-black dark:text-white">
                            <span className="text-xl">If Answer Selected:</span>
                          </div>
                          <div className="col-xs-1 w-[50px]"></div>
                          <div className="col-xs-5 no-padding-sides relative mt-5 mb-5 text-black dark:text-white">
                            <span className="text-xl">
                              Jump To Funnel Step:
                            </span>
                          </div>
                        </div>
                        <div className="row hard-center">
                          {displayAdvancedLogic !== true && (
                            <FieldSettingsStepMapping
                              variant={variant}
                              step={step}
                              element={element}
                              field={field}
                            />
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                <VariantBuilderSetting
                  object={element}
                  label="Redirect to a URL"
                  setting_name="[options][redirect-url]"
                  setting_type="text"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["redirect-url"]
                      ? element.options["redirect-url"]
                      : ""
                  }
                  condition={element.options["confirmation-type"] == "redirect"}
                  placeholder="https://"
                  warning={
                    !element.options["redirect-url"] &&
                    element.options["confirmation-close"] !== "true"
                      ? "Redirect URL not yet set"
                      : undefined
                  }
                />

                <VariantBuilderSetting
                  object={element}
                  label="Add field data to redirect URL?"
                  setting_name="[options][query-string]"
                  setting_type="checkbox"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["query-string"]
                      ? element.options["query-string"]
                      : "false"
                  }
                  condition={
                    ["form", "survey"].includes(element.element_type) &&
                    element.options["confirmation-type"] == "redirect"
                  }
                  placeholder="https://"
                />

                <VariantBuilderSetting
                  object={element}
                  label="Campaign to trigger"
                  setting_name="[options][confirmation-cta]"
                  setting_type="dropdown"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["confirmation-cta"]
                      ? element.options["confirmation-cta"]
                      : ""
                  }
                  options={[
                    {
                      value: "",
                      text: "Select a popup campaign",
                      disabled: true,
                    },
                    ...Object.values(website.ctas)
                      .filter(
                        (c) =>
                          ["inline", "page"].includes(c.cta_type) == false &&
                          c.id !== cta.id
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((c) => {
                        return {
                          value: c.id,
                          text:
                            (c.name || "Campaign " + c.id) +
                            " – " +
                            c.cta_type.substr(0, 1).toUpperCase() +
                            c.cta_type.substr(1),
                        };
                      }),
                  ]}
                  condition={element.options["confirmation-type"] == "cta"}
                  tooltip="Enabling this will trigger the select popup campaign"
                  warning={
                    !element.options["confirmation-cta"]
                      ? "Campaign to display not yet set"
                      : undefined
                  }
                />

                <VariantBuilderSetting
                  object={element}
                  label="Thank you message"
                  setting_name="[options][confirmation]"
                  setting_type="text"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["confirmation"]
                      ? element.options["confirmation"]
                      : ""
                  }
                  condition={element.options["confirmation-type"] == "message"}
                  placeholder="Thanks!"
                />

                <VariantBuilderSetting
                  object={element}
                  label="Thank you message background color"
                  setting_name="[options][confirmation-background-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["confirmation-background-color"]
                      ? element.options["confirmation-background-color"]
                      : "#27ae60"
                  }
                  condition={
                    ["form", "survey"].includes(element.element_type) &&
                    element.options["confirmation-type"] == "message"
                  }
                  gradient={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Thank you message text color"
                  setting_name="[options][confirmation-text-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["confirmation-text-color"]
                      ? element.options["confirmation-text-color"]
                      : "#fff"
                  }
                  condition={
                    ["form", "survey"].includes(element.element_type) &&
                    element.options["confirmation-type"] == "message"
                  }
                />

                <VariantBuilderSetting
                  object={element}
                  label="Phone number / shortcode to text"
                  setting_name="[options][sms-number]"
                  setting_type="text"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["sms-number"]
                      ? element.options["sms-number"]
                      : ""
                  }
                  condition={element.options["confirmation-type"] == "sms"}
                  placeholder="55555"
                  warning={
                    !element.options["sms-number"]
                      ? "SMS opt-in requires a phone number or shortcode to text"
                      : undefined
                  }
                />

                <VariantBuilderSetting
                  object={element}
                  label="SMS opt-in keyword"
                  setting_name="[options][sms-keyword]"
                  setting_type="text"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["sms-keyword"]
                      ? element.options["sms-keyword"]
                      : ""
                  }
                  condition={element.options["confirmation-type"] == "sms"}
                  placeholder="CAMPAIGN"
                />

                <VariantBuilderSetting
                  object={element}
                  label="Close out current campaign?"
                  setting_name="[options][confirmation-close]"
                  setting_type="checkbox"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["confirmation-close"]
                      ? element.options["confirmation-close"]
                      : "false"
                  }
                  condition={["redirect", "cta", "sms"].includes(
                    element.options["confirmation-type"]
                  )}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Disable conversion tracking"
                  setting_name="[options][disable-conversion]"
                  setting_type="checkbox"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["disable-conversion"]
                      ? element.options["disable-conversion"]
                      : "false"
                  }
                  condition={
                    element.element_type == "button" &&
                    ["redirect"].includes(
                      element.options["confirmation-type"]
                    ) &&
                    element.options["confirmation-close"] == "true"
                  }
                />

                <VariantBuilderSetting
                  object={element}
                  label="Open in new tab?"
                  setting_name="[options][target-blank]"
                  setting_type="checkbox"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["target-blank"]
                      ? element.options["target-blank"]
                      : "false"
                  }
                  condition={["redirect"].includes(
                    element.options["confirmation-type"]
                  )}
                  placeholder="https://"
                />

                {["products", "product_hero"].includes(element.element_type) && (
                  <React.Fragment>
                    {element.options["confirmation-type"] == "cart" && (
                      <VariantBuilderSetting
                        object={element}
                        setting_name="[html]"
                        setting_type="editor"
                        label="Button Text"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          element.html ? element.html : "<p>Add To Cart</p>"
                        }
                      />
                    )}

                    {element.options["confirmation-type"] == "product" && (
                      <VariantBuilderSetting
                        object={element}
                        setting_name="[html]"
                        setting_type="editor"
                        label="Button Text"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          element.html ? element.html : "<p>Go To Product</p>"
                        }
                      />
                    )}

                    <VariantBuilderSetting
                      object={element}
                      label="Upon button click"
                      setting_name="[options][confirmation-type]"
                      setting_type="dropdown"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["confirmation-type"]
                          ? element.options["confirmation-type"]
                          : ""
                      }
                      options={[
                        {
                          value: "product",
                          text: "Redirect to product",
                        },
                        ...(["Shopify", "ShopifyGraphql"].includes(
                          element.options["products-source"]
                        )
                          ? [
                              {
                                value: "cart",
                                text: "Add product to cart",
                              },
                            ]
                          : []),
                      ]}
                      note={
                        ["Shopify", "ShopifyGraphql"].includes(
                          element.options["products-source"]
                        ) && element.options["confirmation-type"] == "cart"
                          ? "The product selected will be added to cart automatically"
                          : element.options["products-source"] ==
                              "ConvertFlow" ||
                            element.options["confirmation-type"] == "product"
                          ? "Visitors will be automatically redirected to the selected product"
                          : ""
                      }
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Sold Out Product Button Text"
                      setting_name="[options][products-sold-out-button-text]"
                      setting_type="text"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-sold-out-button-text"]
                          ? element.options["products-sold-out-button-text"]
                          : undefined
                      }
                      placeholder="Sold Out"
                      condition={
                        ["ShopifyGraphql"].includes(
                          element.options["products-source"]
                        ) && element.options["confirmation-type"] !== "product"
                      }
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Redirect to cart upon add to cart"
                      setting_name="[options][products-cart-redirect]"
                      setting_type="checkbox"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-cart-redirect"]
                          ? element.options["products-cart-redirect"]
                          : "false"
                      }
                      condition={
                        element.options["products-checkout-redirect"] !== "true" &&
                        ["products", "product_hero"].includes(element.element_type) &&
                        ["Shopify", "ShopifyGraphql"].includes(
                          element.options["products-source"]
                        ) &&
                        element.options["confirmation-type"] == "cart"
                      }
                      note={["products"].includes(element.element_type) ? "Leave this untoggled if you want shoppers to be able to add multiple products to cart" : null}
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Redirect to checkout upon add to cart"
                      setting_name="[options][products-checkout-redirect]"
                      setting_type="checkbox"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-checkout-redirect"]
                          ? element.options["products-checkout-redirect"]
                          : "false"
                      }
                      condition={
                        element.options["products-cart-redirect"] !== "true" &&
                        ["products", "product_hero"].includes(element.element_type) &&
                        ["Shopify", "ShopifyGraphql"].includes(
                          element.options["products-source"]
                        ) &&
                        element.options["confirmation-type"] == "cart"
                      }
                      note={["products"].includes(element.element_type) ? "Leave this untoggled if you want shoppers to be able to add multiple products to cart" : null}
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Open in new tab?"
                      setting_name="[options][target-blank]"
                      setting_type="checkbox"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["target-blank"]
                          ? element.options["target-blank"]
                          : "false"
                      }
                      condition={
                        ["products", "product_hero"].includes(element.element_type) &&
                        ["redirect", "product", "cart"].includes(
                          element.options["confirmation-type"]
                        ) &&
                        (element.options["confirmation-type"] !== "cart" ||
                          element.options["products-cart-redirect"] == "true" ||
                          element.options["products-checkout-redirect"] == "true")
                      }
                      placeholder="https://"
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          {false ==
            (element.options["confirmation-type"] == "redirect" &&
              element.options["confirmation-close"] == "true" &&
              element.options["disable-conversion"] == "true") && (
            <div className="col-xs-12">
              <div className="flex my-5 relative">
                <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
                  <div className="w-full mt-[13px]">Then</div>
                  <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
                </div>

                <div className="w-[560px]">
                  <VariantBuilderSetting
                    object={element}
                    label={"Track Conversion When"}
                    setting_name="[options][track-conversion-when]"
                    setting_type="dropdown"
                    object_type="elements"
                    object_id={element.id}
                    value={
                      element.options["track-conversion-when"]
                        ? element.options["track-conversion-when"]
                        : "cf-added-to-cart"
                    }
                    condition={
                      ["products", "product_hero"].includes(element.element_type) &&
                      element.options["confirmation-type"] !== "product"
                    }
                    options={[
                      {
                        value: "cf-added-to-cart",
                        text: "Product added to cart",
                      },
                      {
                        value: "cf-link-clicked-or-added-to-cart",
                        text: "Product link clicked or added to cart",
                      },
                      {
                        value: "cf-clicking-through-to-cart",
                        text: "Clicking through after adding to cart",
                      },
                    ]}
                  />

                  <ActionsBuilder
                    cta={cta}
                    variant={variant}
                    website={website}
                    contactable_type="Element"
                    contactable_id={element.id}
                    element={element}
                    dark_mode={contextData.dark_mode}
                    builder={true}
                    title="Run automations"
                  />

                  <VariantBuilderSetting
                    object={element}
                    setting_name="[integrations-modal]"
                    setting_type="html"
                    object_type="elements"
                    object_id={element.id}
                    condition={
                      ["form", "survey"].includes(element.element_type) &&
                      Object.keys(contextData.objects.website.integrations)
                        .length == 0
                    }
                    onload={integrationAdded}
                  >
                    <div
                      className="row hard-center p-[20px] integration-first-message bg-slate-100 rounded dark:bg-slate-800"
                      data-element-id={element.id}
                    >
                      <p className="font-bold text-xl text-black dark:text-white">
                        Add your first integration
                      </p>
                      <p className="text-lg text-black dark:text-white">
                        Sync form submission data and trigger actions in your
                        tools
                      </p>
                      <button
                        className="text-lg px-3 py-2 font-bold text-white bg-black dark:bg-black border-transparent rounded-lg"
                        data-toggle="modal"
                        data-target="#integrations"
                      >
                        + Integration
                      </button>
                    </div>
                  </VariantBuilderSetting>

                  <VariantBuilderSetting
                    object={element}
                    setting_name="[integrations-modal]"
                    setting_type="html"
                    object_type="elements"
                    object_id={element.id}
                    condition={
                      ["form", "survey", "button"].includes(
                        element.element_type
                      ) &&
                      (Object.keys(contextData.objects.website.integrations)
                        .length > 0 ||
                        element.element_type == "button")
                    }
                    onload={integrationAdded}
                  >
                    <div className="row hard-center">
                      <a
                        href="#"
                        className="smooth semibold builder-link"
                        style={{ fontSize: "14px" }}
                        data-toggle="modal"
                        data-target="#integrations"
                      >
                        Manage Integrations →
                      </a>
                    </div>
                  </VariantBuilderSetting>

                  <VariantBuilderSetting
                    object={element}
                    label="Fire script"
                    setting_name="[options][script]"
                    setting_type="code"
                    object_type="elements"
                    object_id={element.id}
                    value={
                      element.options["script"] ? element.options["script"] : ""
                    }
                    tooltip={
                      element.element_type == "form"
                        ? "Custom scripts will fire upon successful submission of form, before redirect actions are taken"
                        : element.element_type == "button"
                        ? "Custom scripts will fire upon clicking button, before redirect actions are taken"
                        : element.element_type == "survey"
                        ? "Custom scripts will fire upon selecting an option, before redirect actions are taken"
                        : ""
                    }
                    feature_gate_type={"Custom Scripts"}
                    col={12}
                  />
                </div>
              </div>
            </div>
          )}

          {["products", "product_hero"].includes(element.element_type) &&
            element.options["confirmation-type"] !== "product" && (
              <div className="col-xs-12">
                <div className="flex my-5 relative">
                  <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
                    <div className="w-full mt-[13px]">Next</div>
                    <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
                  </div>

                  <div className="w-[560px]">
                    <VariantBuilderSetting
                      object={element}
                      label="Button Text After Add To Cart"
                      setting_name="[options][products-cart-button]"
                      setting_type="editor"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-cart-button"]
                          ? element.options["products-cart-button"]
                          : "<p>View Cart</p>"
                      }
                      condition={
                        element.options["products-cart-redirect"] !== "true" &&
                        element.options["products-checkout-redirect"] !== "true" &&
                        ["Shopify", "ShopifyGraphql"].includes(
                          element.options["products-source"]
                        ) &&
                        element.options["confirmation-type"] !== "product"
                      }
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Upon button click"
                      setting_name="[options][products-button-action-after-add-to-cart]"
                      setting_type="dropdown"
                      object_type="elements"
                      object_id={element.id}
                      value={actionAfterAddToCart}
                      condition={
                        element.options["products-cart-redirect"] !== "true" &&
                        element.options["products-checkout-redirect"] !== "true" &&
                        ["ShopifyGraphql", "Shopify"].includes(
                          element.options["products-source"]
                        ) &&
                        element.options["confirmation-type"] == "cart"
                      }
                      options={productsButtonActionAfterAddToCartOptions}
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Default funnel step to jump to"
                      setting_name="[options][products-button-redirect-step]"
                      setting_type="dropdown"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-button-redirect-step"] &&
                        (element.options["products-button-redirect-step"] ==
                          "previous" ||
                          Object.values(variant.steps).filter(
                            (s) =>
                              s.id + "" ==
                                element.options[
                                  "products-button-redirect-step"
                                ] && s.toBeDeleted !== true
                          ).length > 0)
                          ? element.options["products-button-redirect-step"]
                          : ""
                      }
                      options={redirectSteps}
                      condition={
                        element.options["confirmation-type"] == "cart" &&
                        element.options["products-cart-redirect"] !== "true" &&
                        element.options[
                          "products-button-action-after-add-to-cart"
                        ] == "step"
                      }
                      warning={
                        !element.options["products-button-redirect-step"]
                          ? "Step to jump to not yet set"
                          : undefined
                      }
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Redirect to a URL"
                      setting_name="[options][products-button-redirect-url]"
                      setting_type="text"
                      object_type="elements"
                      object_id={element.id}
                      value={element.options["products-button-redirect-url"]}
                      condition={
                        element.options["confirmation-type"] == "cart" &&
                        element.options["products-cart-redirect"] !== "true" &&
                        element.options[
                          "products-button-action-after-add-to-cart"
                        ] == "redirect"
                      }
                      placeholder="https://"
                      warning={
                        !element.options["products-button-redirect-url"]
                          ? "Redirect URL not yet set"
                          : undefined
                      }
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Campaign to trigger"
                      setting_name="[options][products-button-confirmation-cta]"
                      setting_type="dropdown"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-button-confirmation-cta"]
                      }
                      options={[
                        {
                          value: "",
                          text: "Select a popup campaign",
                          disabled: true,
                        },
                        ...Object.values(website.ctas)
                          .filter(
                            (c) =>
                              ["inline", "page"].includes(c.cta_type) ==
                                false && c.id !== cta.id
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((c) => {
                            return {
                              value: c.id,
                              text:
                                (c.name || "Campaign " + c.id) +
                                " – " +
                                c.cta_type.substr(0, 1).toUpperCase() +
                                c.cta_type.substr(1),
                            };
                          }),
                      ]}
                      condition={
                        element.options["confirmation-type"] == "cart" &&
                        element.options["products-cart-redirect"] !== "true" &&
                        element.options[
                          "products-button-action-after-add-to-cart"
                        ] == "cta"
                      }
                      tooltip="Enabling this will trigger the select popup campaign"
                      warning={
                        !element.options["products-button-confirmation-cta"]
                          ? "Campaign to display not yet set"
                          : undefined
                      }
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Close current popup"
                      setting_name="[options][products-button-close-popup]"
                      setting_type="checkbox"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-button-close-popup"]
                          ? element.options["products-button-close-popup"]
                          : "false"
                      }
                      condition={
                        element.options[
                          "products-button-action-after-add-to-cart"
                        ] == "cta" &&
                        ["overlay", "hook", "bar"].includes(cta.cta_type) &&
                        ["Shopify", "ShopifyGraphql"].includes(
                          element.options["products-source"]
                        ) &&
                        element.options["confirmation-type"] == "cart"
                      }
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Open in new tab?"
                      setting_name="[options][target-blank]"
                      setting_type="checkbox"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["target-blank"]
                          ? element.options["target-blank"]
                          : "false"
                      }
                      condition={
                        element.options["products-cart-redirect"] !== "true" &&
                        ["redirect", "product", "cart"].includes(
                          element.options["confirmation-type"]
                        ) &&
                        (element.options["confirmation-type"] !== "cart" ||
                          (actionAfterAddToCart == "cart" &&
                            element.options["products-cart-redirect"] !==
                              "false"))
                      }
                      placeholder="https://"
                    />

                    <VariantBuilderSetting
                      object={element}
                      label="Apply discount code upon redirect"
                      setting_name="[options][products-cart-redirect-discount]"
                      setting_type="text"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        element.options["products-cart-redirect-discount"]
                          ? element.options["products-cart-redirect-discount"]
                          : ""
                      }
                      placeholder="BFCMSALE"
                      condition={
                        ["Shopify", "ShopifyGraphql"].includes(
                          element.options["products-source"]
                        ) &&
                        ["product", "cart"].includes(
                          element.options["confirmation-type"]
                        ) &&
                        ((element.options[
                          "products-button-action-after-add-to-cart"
                        ] !== "step" &&
                          element.options[
                            "products-button-action-after-add-to-cart"
                          ] !== "cta") ||
                          element.options["products-cart-redirect"] == "true" ||
                          element.options["products-checkout-redirect"] ==
                            "true")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
        </VariantBuilderSetting>
      </div>

      <div className="col-xs-12 no-padding-sides">
        <div className="w-full relative mt-5 mb-3 text-black dark:text-white">
          <span className="text-xl">Conditional Actions:</span>

          <Tooltip
            label="Learn"
            tooltip={`Conditional actions are performed if conditions are met when your ${
              element.element_type == "form"
                ? "form is submitted"
                : element.element_type == "quiz"
                ? "quiz is completed"
                : element.element_type == "button"
                ? "button is clicked"
                : element.element_type == "survey"
                ? "survey is submitted"
                : ""
            }.`}
          />
        </div>

        {action_groups.map((action_group) => {
          return (
            <ActionGroupSettings
              key={"action-group-" + action_group.id}
              step={step}
              element={element}
              action_group={action_group}
            />
          );
        })}
        <NewActionGroup element={element} />
      </div>
    </React.Fragment>
  );
};

export default ElementSettingsConfirmation;
