import React, { useContext, useEffect, useState } from "react";

import { RemixBuilderContext } from "./RemixBuilderContext";
import useRemix from "./useRemix";

import CFInput from "../shared/forms/CFInput";
import CFColorPicker from "../shared/forms/CFColorPicker";
import CFSelect from "../shared/forms/CFSelect";

const RemixBuilderSettingsTheme = () => {
  const [contextData, builder, setContextData] = useContext(RemixBuilderContext);
  const { Remix } = useRemix();

  const [sectionsList, setSectionsList] = useState(contextData.matched_sections);
  const [remixSettings, setRemixSettings] = useState(contextData.remix_settings);
  const [loadingNextStep, setLoadingNextStep] = useState(false);

  useEffect(() => {
    setSectionsList(contextData.matched_sections);
  }, [contextData.matched_sections]);

  const handleNextStep = async () => {
    if (loadingNextStep === true) return;

    setLoadingNextStep(true);

    const updatedBuilder = { ...contextData };
    updatedBuilder.remix_settings = remixSettings;

    setContextData(updatedBuilder);

    console.log('Creating CTA with:', updatedBuilder);

    const cta = await Remix.createCTA();
    window.location.href = cta.builder_url;
  };

  const handlePreviousStep = () => {
    if (loadingNextStep === true) return;

    builder.goToPreviousStep();
  };

  return (
    <React.Fragment>
      {sectionsList && (
        <>
          <div className="remix-editor-settings-layouts flex flex-column overflow-scroll grow-1">
            <h2 className="text-[18px] font-medium">
              Remix your template
            </h2>
            <p className="text-[12px] text-gray-400">
              Give your page a name, remix the theme styles and click to use the AI generated template
            </p>

            <div>
              <div className="mb-[5px] col-xs-9 no-padding-sides">
                <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                  Name
                </span>
              </div>

              <CFInput
                name="campaign_name"
                placeholder="Campaign name"
                value={remixSettings.campaign_name}
                onChange={(value) => {
                  builder.updateRemixSettings('campaign_name', value);
                }}
              />
            </div>

            <div className="flex gap-4 mt-5">
              <div className="basis-1-2">
                <div className="mb-[5px] col-xs-9 no-padding-sides">
                  <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                    Heading Font
                  </span>
                </div>

                <CFSelect
                  name="heading-font-family"
                  options={[
                    {
                      value: "",
                      text: "Theme font",
                      group: "System fonts",
                    },
                    ...window?.builder_fonts_dropdown,
                  ]}
                  value={remixSettings['heading-font-family']}
                  onChange={(fontFamily) => {
                    builder.updateRemixSettings('heading-font-family', fontFamily.value);
                    ctas.builder.fonts.load_font_family(fontFamily.value);
                  }}
                />
              </div>

              <div className="basis-1-2">
                <div className="mb-[5px] col-xs-9 no-padding-sides">
                  <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                    Heading Color
                  </span>
                </div>

                <CFColorPicker
                  name="heading-font-color"
                  placeholder="#000000"
                  website={contextData.website}
                  value={remixSettings['heading-font-color']}
                  onChange={(value) => {
                    builder.updateRemixSettings('heading-font-color', value);
                  }}
                />
              </div>
            </div>

            <div className="flex gap-4 mt-5">
              <div className="basis-1-2">
                <div className="mb-[5px] col-xs-9 no-padding-sides">
                  <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                    Paragraph Font
                  </span>
                </div>

                <CFSelect
                  name="paragraph-font-family"
                  options={[
                    {
                      value: "",
                      text: "Theme font",
                      group: "System fonts",
                    },
                    ...window?.builder_fonts_dropdown,
                  ]}
                  value={remixSettings['paragraph-font-family']}
                  onChange={(fontFamily) => {
                    builder.updateRemixSettings('paragraph-font-family', fontFamily.value);
                    ctas.builder.fonts.load_font_family(fontFamily.value);
                  }}
                />
              </div>

              <div className="basis-1-2">
                <div className="mb-[5px] col-xs-9 no-padding-sides">
                  <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                    Paragraph Color
                  </span>
                </div>

                <CFColorPicker
                  name="paragraph-font-color"
                  placeholder="#000000"
                  website={contextData.website}
                  value={remixSettings['paragraph-font-color']}
                  onChange={(value) => {
                    builder.updateRemixSettings('paragraph-font-color', value);
                  }}
                />
              </div>
            </div>

            <div className="flex gap-4 mt-5">
              <div className="basis-1-2">
                <div className="mb-[5px] col-xs-9 no-padding-sides">
                  <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                    Button Color
                  </span>
                </div>

                <CFColorPicker
                  name="button-color"
                  placeholder="#000000"
                  website={contextData.website}
                  value={remixSettings['button-color']}
                  onChange={(value) => {
                    builder.updateRemixSettings('button-color', value);
                  }}
                />
              </div>

              <div className="basis-1-2">
                <div className="mb-[5px] col-xs-9 no-padding-sides">
                  <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                    Accent Color
                  </span>
                </div>

                <CFColorPicker
                  name="accent-color"
                  placeholder="#000000"
                  website={contextData.website}
                  value={remixSettings['accent-color']}
                  onChange={(value) => {
                    builder.updateRemixSettings('accent-color', value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="pt-[30px] gap-2 flex flex-column justify-between">
            <div
              onClick={handleNextStep}
              className={`cursor-pointer rounded-xl w-full p-[10px] flex justify-center items-center text-lg border-solid border-1 border-slate-300 bg-blue-600 text-white ${loadingNextStep ? "disabled" : ""}`}>
              Use Template →
            </div>

            <div
              onClick={handlePreviousStep}
              className={`cursor-pointer rounded-xl w-full p-[10px] flex justify-center items-center text-lg border-solid border-1 border-slate-300 ${loadingNextStep ? "disabled" : ""}`}
            >
              Back
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default RemixBuilderSettingsTheme;
