import React, { useState, useEffect } from "react";
import CFSelect from "../shared/forms/CFSelect";

const ActionSettingsInput = (props) => {
  const [selectSettingValue, setSelectSettingValue] = useState(undefined);

  const setting = props.setting;
  const integration = props.integration;
  const website = props.website;

  let setting_value;
  let value_paths = setting.attribute
    .replace("][", ".")
    .replace("[", "")
    .replace("]", "")
    .split(".");

  value_paths.forEach((path) => {
    if (setting_value) {
      setting_value = setting_value[path];
    } else if (props.action) {
      setting_value =
        props.action[path] || (props.action.data && props.action.data[path]);
    }
  });

  const [inputValue, setInputValue] = useState(setting_value);

  const settingSelectOptions = () => {
    let selectOptions = [
      {
        value: "",
        label: setting.placeholder,
        isDisabled: true,
      },
    ];

    if (
      !integration &&
      setting.dropdown == "fields" &&
      props.builder !== false
    ) {
      selectOptions.push({
        value: "CURRENT_SCORE",
        label: "Current score",
      });
    }

    {
      integration &&
        integration.api_data[setting.dropdown] &&
        Object.values(integration.api_data[setting.dropdown]).forEach(
          (option) => {
            selectOptions.push({
              value: option.id,
              label: option.name
            });
          }
        );
    }

    if (
      integration &&
      integration.integration_type == "Shopify" &&
      setting.dropdown == "product_variants" &&
      Object.values(integration.api_data[setting.dropdown]).length == 0
    ) {
      const populateProducts = (products) => {
        const productsVariants = products.map(product => product.variants).flat();
        productsVariants.forEach((variant) => {
          selectOptions.push({
            value: variant.id,
            label: variant.variant_title
          });
        });
      };

      if (!window.cf_shopify_products) {
        new ProductsIntegrationsService("Shopify", integration)
          .adapter()
          .getProducts()
          .then((response) => {
            window.cf_shopify_products = response;
            populateProducts(response);
          })
      } else {
        populateProducts(window.cf_shopify_products);
      }
    }

    if (
      integration &&
        integration.integration_type == "Shopify" &&
        setting.dropdown == "selling_plans" &&
        props.parent_value
    ) {
      if (!window.cf_shopify_products) {
        new ProductsIntegrationsService("Shopify", integration)
          .adapter()
          .getProducts()
          .then((response) => {
            window.cf_shopify_products = response;
          })
      } else {
        const selectedProductVariant = window.cf_shopify_products.map(product => product.variants).flat().find(variant => variant.id == props.parent_value.value);
        const selectedProduct = window.cf_shopify_products.find(product => product.id == selectedProductVariant?.product_id);
        const productSellingPlans = selectedProduct?.subscriptions?.map(plan => plan.sellingPlans).flat() || [];

        if (selectedProduct && (selectedProduct.requires_selling_plan !== true || productSellingPlans?.length == 0) && setting.hide_if_empty !== true) {
          selectOptions.push({
            value: "",
            label: "One-time purchase"
          });

          if (!selectSettingValue) {
            setSelectSettingValue({
              value: "",
              label: "One-time purchase"
            });
          }
        }

        productSellingPlans.forEach((plan) => {
          selectOptions.push({
            value: plan.id.replace('gid://shopify/SellingPlan/', ''),
            label: plan.name
          });
        });
      }
    }

    {
      !integration &&
        setting.dropdown == "fields" &&
        Object.values(website.fields)
          .filter((f) => !f.service || f.service == "ConvertFlow")
          .forEach((field) => {
            selectOptions.push({
              value: field.slug,
              label: field.slug,
            });
          });
    }

    {
      !integration &&
        setting.dropdown == "tags" &&
        Object.values(website.tags).forEach((tag) => {
          selectOptions.push({
            value: tag.id,
            label: tag.name,
          });
        });
    }

    {
      !integration &&
        setting.dropdown == "ctas" &&
        Object.values(website.ctas).forEach((cta) => {
          selectOptions.push({
            value: cta.id,
            label: cta.name,
          });
        });
    }

    if (!integration && setting.dropdown == "ctas") {
      selectOptions.push({
        value: null,
        label: `This campaign (${props.cta.name})`,
      });
      Object.values(website.ctas).forEach((cta) => {
        if (cta.id !== props.cta.id) {
          selectOptions.push({
            value: cta.id,
            label: `${cta.name} (${cta.cta_type})`,
          });
        }
      });
    }

    {
      !integration &&
        setting.dropdown == "webhooks" &&
        Object.values(website.webhooks).forEach((webhook) => {
          selectOptions.push({
            value: webhook.id,
            label: (webhook.name ? webhook.name : webhook.target_url),
          });
        });
    }

    {
      !integration &&
        setting.options &&
        setting.options
          .filter((option) => {
            return (
              option &&
              option.text &&
              (!option.parent || option.parent == props.parent_value?.value)
            );
          })
          .sort(function (a, b) {
            return a.text.localeCompare(b.text);
          })
          .forEach((dropdown_option, i) => {
            var custom_attributes = {};

            dropdown_option.custom_attributes &&
              dropdown_option.custom_attributes.forEach((custom_attribute) => {
                custom_attributes[custom_attribute.name] =
                  custom_attribute.value;
              });

            selectOptions.push({
              value: dropdown_option.value,
              label: dropdown_option.text,
              ...custom_attributes,
            });
          });
    }

    return selectOptions;
  };

  const changeCallback = (e) => {
    if (setting.input_type == "select") {
      setSelectSettingValue({ label: e.label, value: e.value });
    } else {
      setInputValue(e.target?.value);
    }

    if (props.onChange) {
      props.onChange(e);
    }

    if (props.onDone && setting.input_type == "select") {

      if (setting.child_setting && setting.child_setting.reset_on_parent_change == true) {
        updateFormData(setting.child_setting.attribute, "");
      }

      props.onDone(e);
    }

    if (setting.callback) {
      setting.callback(e);
    }
  };

  const updateFormData = (attribute, value) => {
    let newFormData = props.formData;
    _.set(
      newFormData,
      `${
        !integration
          ? attribute
          : `[data][${attribute}]`
      }`,
      value
    );
    props.setFormData(newFormData);
  };

  useEffect(() => {
    let selectOption = settingSelectOptions().find(
      (option) => option.value == setting_value
    );

    if (selectOption || setting_value) {
      setSelectSettingValue(
        selectOption || {
          label: setting_value,
          value: setting_value,
        }
      );
    }
  }, [setting_value, window?.cf_shopify_products]);

  return (
    <React.Fragment>
      {settingSelectOptions().length <= 1 && setting.hide_if_empty == true ? "" : (
        <React.Fragment>
          <div className={`flex-grow min-w-[100px] ${setting.note ? 'pb-[10px]' : ''}`}>
            {setting.input_type !== "select" && (
              <input
                className="w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none"
                placeholder={setting.placeholder}
                type={setting.input_type}
                name={
                  integration
                    ? `action_object[data][${setting.attribute}]`
                    : `action_object${setting.attribute}`
                }
                required="required"
                min={setting.min}
                defaultValue={setting_value || setting.value}
                onChange={changeCallback}
                onBlur={props.onDone}
              ></input>
            )}

            {setting.input_type == "select" && (
              <CFSelect
                isSearchable={true}
                resetIfInvalidOption={setting.reset_if_invalid_option}
                required="required"
                name={
                  integration
                    ? `action_object[data][${setting.attribute}]`
                    : `action_object${setting.attribute}`
                }
                options={settingSelectOptions()}
                value={selectSettingValue}
                onChange={changeCallback}
              />
            )}
          </div>

          {setting.input_type == "select" && setting.child_setting && (
            <ActionSettingsInput
              action={props.action}
              setting={setting.child_setting}
              integration={integration}
              setFormData={props.setFormData}
              parent_value={selectSettingValue || { value: setting_value }}
              onChange={(e) => {
                updateFormData(setting.child_setting.attribute, e.value || e.target?.value);

                if (
                  props.action &&
                  !props.builder &&
                  setting.child_setting.input_type == "select"
                ) {
                  props.handleSubmit(newFormData);
                }
              }}
              onDone={(e) => {
                if (props.action) {
                  if (props.builder) {
                    const value = e.value || e.target?.value;
                    dispatchCustomEvent("updateBuilder", {
                      updates: [
                        {
                          object_type: "actions",
                          object_id: props.action.id,
                          setting_name: setting.child_setting.attribute,
                          value: value,
                        },
                      ],
                    });
                  } else if (setting.child_setting.input_type !== "select") {
                    props.handleSubmit();
                  }
                }
              }}
            />
          )}

          {setting.note && (
            <div className="text-black dark:text-white whitespace-nowrap text-sm absolute -bottom-[6px] max-w-[566px]">
              {setting.note}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ActionSettingsInput;
