import React, { useContext, useState, useRef, useEffect } from "react";
import { RemixBuilderContext } from "./RemixBuilderContext";
import useRemix from "./useRemix";

const RemixUploader = (props) => {
  const { Remix } = useRemix();

  const [contextData] = useContext(RemixBuilderContext);

  const [remixLoading, setRemixLoading] = useState(false);
  const remixSubmitBtn = useRef(null);
  const inputError = useRef(null);
  const imageInput = useRef(null);
  const subtitleRef = useRef(null);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const remixSubmit = () => {
    if (props.remixModal?.current?.getAttribute("data-image-url") === imageInput.current.value) {
      props.onSubmit();
      return;
    }

    setRemixLoading(true);
    remixSubmitBtn.current.disabled = true;
    inputError.current.style.display = "none";

    validateImageURL(imageInput.current?.value)
      .then((valid) => {
        if (!mountedRef.current) return;
        if (valid) {
          subtitleRef.current.textContent = "Detecting Sections...";
          return Remix.detectLayoutSections(imageInput.current.value);
        } else {
          inputError.current.style.display = "block";
          throw new Error("Invalid Image URL");
        }
      })
      .then((detected_sections) => {
        if (!mountedRef.current) return;
        const sectionsWithTempIDs = detected_sections.map((section) => {
          return {
            ...section,
            id: crypto.randomUUID(),
            selected: true,
          };
        });

        return sectionsWithTempIDs;
      })
      .then((detected_sections) => {
        if (!mountedRef.current) return;
        subtitleRef.current.textContent = `Identifying key section tags... (0/${detected_sections.length})`;
        const promises = detected_sections.map((section) => Remix.detectSectionTags(section));

        let counter = 1;

        return Promise.allSettled(promises.map((p) => {
          return p.then((result) => {
            if (!mountedRef.current) return;
            return result;
          }).finally(() => {
            if (mountedRef.current) {
              subtitleRef.current.textContent = `Identifying key section tags... (${counter++}/${detected_sections.length})`;
            }
          });
        })).then((sectionPromises) => {
          if (!mountedRef.current) return;
          const sectionTags = sectionPromises.filter(r => r.status == "fulfilled").map(result => result.value);
          return sectionTags;
        });
      })
      .then((results) => {
        if (!mountedRef.current) return;
        props.onSubmit(results, imageInput.current.value);
      })
      .catch(() => {
        if (!mountedRef.current) return;
        subtitleRef.current.textContent = "Something went wrong";
      })
      .finally(() => {
        if (!mountedRef.current) return;
        setRemixLoading(false);
        remixSubmitBtn.current.disabled = false;
        props.remixModal?.current.setAttribute(
          "data-image-url",
          imageInput.current.value
        );
        subtitleRef.current.textContent =
          "Paste a website or upload an image to remix an existing design into an AI generated page template";
      });
  };

  const validateImageURL = (url) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  };

  return (
    <React.Fragment>
      <div className="remix-uploader">
        <div className="input-box">
          <div className="headline">Remix a design</div>
          <p ref={subtitleRef} className="subtitle">
            Paste a website or upload an image to remix an existing design into an AI generated page template
          </p>

          <div className="inline-form">
            <div className="input-selectors w-[218px]">
              <div className="selector-btn active">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.03376 8.14282L4.35867 9.81791C3.73307 10.4435 3.37331 11.2947 3.37988 12.1893C3.38646 13.084 3.73835 13.9404 4.39363 14.5754C5.02864 15.2307 5.88524 15.5826 6.77971 15.5892C7.69457 15.5959 8.52565 15.2564 9.15129 14.6308L10.8264 12.9557M12.966 10.857L14.6411 9.18191C15.2667 8.55631 15.6264 7.70509 15.6199 6.81048C15.6133 5.91587 15.2614 5.05946 14.6061 4.42441C13.9713 3.78954 13.1148 3.43764 12.2202 3.43106C11.3256 3.42449 10.4742 3.76383 9.84861 4.38944L8.17352 6.06453M6.95971 11.9953L11.985 6.97001"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="label">URL</div>
              </div>

              <div
                onClick={() => {
                  uploads.select("callback", {
                    environment: contextData.environment,
                    path: `/websites/${contextData.website.id}/`,
                    website_id: contextData.website.id,
                    library: false,
                    callback: function (file_url) {
                      if (mountedRef.current) {
                        imageInput.current.value = file_url;
                      }
                    },
                  });
                }}
                className="selector-btn"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.10078 16.7H14.9008C15.8949 16.7 16.7008 15.8517 16.7008 14.8053V4.19479C16.7008 3.14835 15.8949 2.30005 14.9008 2.30005H4.10078C3.10667 2.30005 2.30078 3.14835 2.30078 4.19478V14.8053C2.30078 15.8517 3.10667 16.7 4.10078 16.7Z"
                    stroke="#A1A8C1"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.00078 12.2H14.0008L11.0008 6.94996L8.75078 10.325L7.25078 8.82496L5.00078 12.2Z"
                    stroke="#A1A8C1"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="label">Image</div>
              </div>
            </div>

            <div className="input-url">
              <small
                ref={inputError}
                className="error-text"
                style={{
                  position: "absolute",
                  fontSize: "10px",
                  top: "-17px",
                  color: "orangered",
                  display: "none",
                }}
              >
                Please enter a valid URL or Upload Image
              </small>
              <input
                ref={imageInput}
                type="text"
                placeholder="Paste URL"
                className="input"
              />
            </div>

            <button
              ref={remixSubmitBtn}
              onClick={remixSubmit}
              className="remix-btn"
            >
              {remixLoading ? (
                <span className="cf-loading-spinner" />
              ) : (
                <React.Fragment>
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6184 2.30005L12.9911 6.00971L16.7008 7.3824L12.9911 8.7551L11.6184 12.4648L10.2457 8.7551L6.53608 7.3824L10.2457 6.00971L11.6184 2.30005Z"
                      stroke="white"
                      strokeWidth="1.3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.26549 10.7706L6.46461 12.5362L8.23019 13.7353L6.46461 14.9345L5.26549 16.7L4.06637 14.9345L2.30078 13.7353L4.06637 12.5362L5.26549 10.7706Z"
                      stroke="white"
                      strokeWidth="1.3"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Remix
                </React.Fragment>
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RemixUploader;
