import React, { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";

import SectionTemplateItem from "../sections/SectionTemplateItem";

const RemixTemplatesModal = (props) => {
  const modalRef = useRef(null);
  const [internalVisibility, setInternalVisibility] = useState(false);
  const [searchText, setSearchText] = useState("");

  const isControlled = !props.children;
  const isVisible = isControlled ? props.isOpen : internalVisibility;
  let templates = props.templates || [];
  if (props.template) {
    templates = templates.filter(function (temp) {
      return temp.id !== props.template.id;
    });
  }

  const templatesItemFlexSpacing = '0 0 calc(25% - 1.5rem)';

  const handleClose = () => {
    if (isControlled) {
      props.onClose?.();
    } else {
      setInternalVisibility(false);
    }
  };

  const searchedTemplates = templates.filter(function (template) {
    return template.name.toLowerCase().includes(searchText.toLowerCase());
  });

  useEffect(() => {
    if (!modalRef.current) return;

    let parentModal = modalRef.current.closest(".modal");

    if (parentModal) {
      $(modalRef.current)
        .off("shown.bs.modal")
        .on("shown.bs.modal", function () {
          $(parentModal).animate({ scrollTop: 0 });
        });
    }

    if (isVisible) {
      $(modalRef.current).modal("show");
      $(modalRef.current).addClass("in").css("display", "block");
      $(modalRef.current).parents(".modal").scrollTop(0);

      modalRef.current.onclick = function (e) {
        if (
          e.target == modalRef.current ||
          $(e.target).parent()[0] == modalRef.current
        ) {
          if (
            $(modalRef.current).is(":visible") &&
            $(modalRef.current).find(".modal:visible").length == 0
          ) {
            handleClose();
          }
        }
      };
    } else {
      $(modalRef.current).removeClass("in").css("display", "none");
    }
  }, [isVisible]);

  const modalContent = (
    <div
      ref={modalRef}
      className="modal fade cf-modal-centered"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      data-backdrop="false"
      style={{
        background: "rgb(0, 0, 0, 0.21)",
        overflow: "visible",
      }}
    >
      <div
        className="modal-dialog !mt-10 !w-[80%] !h-[100%] ml-auto mr-auto"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" onClick={handleClose} className="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row hard-center">
              <div className="col-xs-5 text-align-center">
                <div className="row hard-center">
                  <h3 className="very-bold black margin-top-zero">
                    {props.title || "Select a template"}
                  </h3>
                </div>
              </div>

              <div className="col-xs-3 col-xs-offset-4">
                <input
                  type="text"
                  name="name"
                  className="form-control text-field"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-wrap gap-4 mt-6 m-l-[15px] m-r-[-15px]">
              {!props.template && (
                <div
                  className="cursor-pointer flex rounded-lg items-center text-center h-[152px] p-4 mt-7 text-slate-500 hover:text-blue-600 dark:hover:text-white bg-white hover:bg-white dark:bg-slate-950 dark:hover:bg-slate-700 border-dashed border-[1px] border-slate-300 hover:border-blue-600 dark:border-slate-500 dark:hover:border-white"
                  style={{ flex: templatesItemFlexSpacing }}
                  onClick={() => {
                    props.onSelectTemplate();
                    handleClose();
                  }}
                >
                  <div className="w-full mt-3 mb-3 text-lg  whitespace-nowrap text-ellipsis overflow-hidden">
                    Create From Scratch
                  </div>
                </div>
              )}

              {searchedTemplates.map((item) => (
                <div key={item.id} style={{ flex: templatesItemFlexSpacing }}>
                  <SectionTemplateItem
                    key={item.id}
                    item={item}
                    selectTemplate={() => {
                      props.onSelectTemplate(item);
                      handleClose();
                    }}
                    selectLabel={props.template ? "Select" : null}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {props.children && (
        <span onClick={() => setInternalVisibility(true)}>
          {props.children}
        </span>
      )}

      {(isVisible || !isControlled) &&
        createPortal(modalContent, document.body)}
    </React.Fragment>
  );
};

export default RemixTemplatesModal;
