import React, { useState, useEffect, useRef } from "react";
import ReportsHelpers from "../../utils/ReportsHelpers";
import withLazyLoad from "../../utils/withLazyLoad";
const StepReport = withLazyLoad(() => import("../steps/StepReport"));

const VariantReport = (props) => {
  const cta = props.cta;
  const [tab, setTab] = useState("conversions");
  const [timeframeType, setTimeframeType] = useState("All Time");
  const [rangeStartValue, setRangeStartValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Last 24 Hours")[0].range_start
  );
  const [rangeEndValue, setRangeEndValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Last 24 Hours")[0].range_end
  );
  const rangeStartInput = useRef(null);
  const rangeEndInput = useRef(null);
  const [variantId, setVariantId] = useState(props.variantId);
  const variant = variantId
    ? Object.values(props.variants).filter((v) => v.id + "" == variantId)[0]
    : null;
  const wistia = useRef(null);
  const gateReports = props.gateReports;

  let group_by = ReportsHelpers.getGroupBy(timeframeType);
  let range_start = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_start;
  let range_end = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_end;

  if (timeframeType == "Date Range") {
    range_start = rangeStartValue;
    range_end = rangeEndValue;
    group_by = ReportsHelpers.getGroupBy(null, range_start, range_end);
  }

  useEffect(() => {
    $(rangeStartInput.current)
      .datetimepicker({
        defaultDate: new Date().setHours(0, 0, 0, 0),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date(
          $(e.currentTarget).find("input").val()
        ).setHours(0, 0, 0, 0);
        const value = new Date(timestamp).toISOString();
        setRangeStartValue(value);
      });

    $(rangeEndInput.current)
      .datetimepicker({
        defaultDate: new Date().setHours(23, 59, 59, 999),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date(
          $(e.currentTarget).find("input").val()
        ).setHours(23, 59, 59, 999);
        const value = new Date(timestamp).toISOString();
        setRangeEndValue(value);
      });
  }, [tab, timeframeType, variantId, rangeStartValue, rangeEndValue]);

  useEffect(() => {
    if (gateReports) {
      $.getScript('https://fast.wistia.com/assets/external/E-v1.js');
      $.getScript('https://fast.wistia.com/embed/medias/s1hrpbbk2u.jsonp');
    }
  }, [props.cta.id])

  return (
    <React.Fragment>
      <div className="row hard-center smooth VariantReport" style={{minWidth: '765px'}}>
        <div className="row hard-center some-vertical-space">
          <div className="col-xs-5">
            <h4 className="bold">Funnel Breakdown </h4>
            <p className="light ellipsis">
              See how people progress through variant{" "}
              <span style={{ textTransform: "capitalize" }}>
                {variant.variation}
                {variant.name ? ` - ${variant.name}` : ""}
              </span>
            </p>
          </div>
          <div className="col-xs-7">
            <div className="col-xs-3 timeframe-date-selector">
              <select
                className="text-field"
                style={{ float: "right" }}
                onChange={(event) => setTimeframeType(event.target.value)}
                name="timeframe"
                defaultValue={
                  props.timeframeTypes.filter(
                    (option) => option.selected == true
                  )[0].value
                }
              >
                {props.timeframeTypes.map((option) => (
                  <option
                    value={option.value}
                    key={`timeframe-option-${option.value}`}
                  >
                    {['Today', 'Yesterday', 'This Week', 'This Month', 'This Quarter', 'This Year'].includes(option.value) ? `${option.value} (Timezone: UTC)` : option.value == 'Date Range' ? 'Date Range (UTC)' : option.value}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-end"
                ref={rangeEndInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field cursor-default"
                  placeholder="End"
                  value={new Date(rangeEndValue).toLocaleString()}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => {
                    let value = event.target.value;
                    const date = new Date(value);
                    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                    value = utcDate.toISOString();
                    setRangeEndValue(value);
                  }}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-start"
                ref={rangeStartInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field cursor-default"
                  placeholder="Start"
                  value={new Date(rangeStartValue).toLocaleString()}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => {
                    let value = event.target.value;
                    const date = new Date(value);
                    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                    value = utcDate.toISOString();
                    setRangeStartValue(value);
                  }}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3"
              style={{ float: "right", paddingTop: "10px" }}
            >
              <select
                className="text-field"
                onChange={(event) => {
                  setVariantId(event.target.value);
                }}
                value={variantId}
              >
                {" "}
                {props.cta.variants.map((variant) => (
                  <option
                    key={`variant-option-${variant.id}`}
                    value={variant.id}
                  >{`Variant ${variant.variation.toUpperCase()} ${
                    variant.name ? `(${variant.name})` : ""
                  }`}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="relative row hard-center steps-reports-container border-solid border-slate-100 border-0 border-t-[1px]" style={{width: '100%'}}>
            {variant.steps.map((step) => {
              return (
                <StepReport
                  key={`step-${step.id}`}
                  cta={cta}
                  step={step}
                  timeframeType={timeframeType}
                  range_start={range_start}
                  range_end={range_end}
                  firstStep={variant.steps[0]}
                  externalProductValues={props.externalProductValues}
                  currency={props.currency}
                  gateReports={gateReports}
                />
              );
            })}

            {gateReports && (
              <div className="row absolute top-0 bottom-0 right-0 left-0 w-auto h-full p-5" style={{
                backdropFilter: 'blur(4px)'
              }}>
              </div>
            )}
          </div>
          {gateReports && (
            <div className="p-7 mt-10 mb-5 z-20">
              <div className="row hard-center bg-slate-100 rounded-lg p-7 !pb-0">
                <div className="row hard-center some-vertical-space">
                  <div
                    className="wistia_responsive_padding"
                    data-id="s1hrpbbk2u"
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  >
                    <div
                      className="wistia_responsive_wrapper"
                      style={{
                        height: "100%",
                        left: "0",
                        position: "absolute",
                        top: "0",
                        width: "100%",
                      }}
                    >
                      <div
                        className="wistia_embed wistia_async_s1hrpbbk2u popover=true videoFoam=true"
                        style={{
                          display: "inline-block",
                          height: "100%",
                          position: "relative",
                          width: "100%",
                        }}
                        ref={wistia}
                      >
                        <img
                          src="https://fast.wistia.com/embed/medias/s1hrpbbk2u/swatch"
                          style={{
                            filter: "blur(5px)",
                            height: "100%",
                            objectFit: "contain",
                            width: "100%",
                          }}
                          alt=""
                          aria-hidden="true"
                          onLoad={() => {
                            wistia.current.style.opacity = 1;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row hard-center">
                  <p className="bold">Get funnel breakdowns</p>
                  <p>
                    For each funnel step, element and answer, get insights:
                  </p>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Drop off rates
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Element reports
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Answer statistics
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                        stroke="#003FFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Revenue insights
                  </div>
                </div>
                <div className="row mb-5 mt-2">
                  <div className="col-xs-12">
                    <button className="btn bg-black !text-white font-bold py-2 px-4" onClick={(event) => {
                      subscriptions.upgrade_modal(
                        event,
                        false,
                        "get funnel breakdowns",
                        "2024_pro",
                        "Triggered feature CTA – Full Breakdown"
                      );
                    }}>
                      <svg
                        className="mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M3.30039 4.40019V3.94305C3.30039 2.4236 4.50468 1.2002 6.00039 1.2002C7.49611 1.2002 8.70039 2.4236 8.70039 3.94305V4.40019M3.30039 4.40019C2.80539 4.40019 2.40039 4.81162 2.40039 5.31448V9.88591C2.40039 10.3888 2.80539 10.8002 3.30039 10.8002H8.70039C9.19539 10.8002 9.60039 10.3888 9.60039 9.88591V5.31448C9.60039 4.81162 9.19539 4.40019 8.70039 4.40019M3.30039 4.40019H8.70039"
                          stroke="white"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                        />
                      </svg>
                      Upgrade To Unlock
                    </button>
                    <p className="text-xl mt-5">
                      <a href="https://support.convertflow.com/en/articles/2514470-conversion-reporting-funnel-breakdowns" target="_blank">
                        Learn more →
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantReport;
