import React, { useContext, useState, memo, useEffect } from "react";

import { RemixBuilderContext } from "./RemixBuilderContext";
import RemixTemplatesModal from "./RemixTemplatesModal";
import TemplatePreview from "../templates/TemplatePreview";

const UploadLinkTag = memo(() => {
  const [contextData] = useContext(RemixBuilderContext);

  return (
    <span
      onClick={() => {
        window.open(contextData.uploaded_image_url, "_blank");
      }}
      className="bg-white py-[3px] px-[11px] rounded-xl gap-1 flex items-center text-xl w-fit max-w-[200px] cursor-pointer">
      <svg className="w-[35px]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.47272 7.23809L2.98375 8.72705C2.42766 9.28314 2.10787 10.0398 2.11372 10.835C2.11956 11.6302 2.43235 12.3915 3.01482 12.956C3.57928 13.5384 4.3407 13.8512 5.13578 13.8571C5.949 13.863 6.68773 13.5613 7.24385 13.0052L8.73282 11.5162M10.6347 9.6507L12.1237 8.16173C12.6798 7.60564 12.9995 6.849 12.9937 6.05379C12.9879 5.25858 12.6751 4.49732 12.0926 3.93283C11.5283 3.36851 10.767 3.0557 9.97177 3.04986C9.17656 3.04402 8.41982 3.34565 7.8637 3.90176L6.37473 5.39072M5.29578 10.6625L9.76269 6.19559" stroke="#7F7D83" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
      <span className="text-ellipsis overflow-hidden whitespace-nowrap text-slate-500">
        {contextData.uploaded_image_url}
      </span>
    </span>
  );
});

const PreviewStepOne = memo(({ sortedSelectedSections, websiteName }) => {
  return (
    <>
      <div className="mt-5 p-7 rounded-xl bg-slate-200 overflow-y-auto flex-1">
        <div className="flex flex-column flex-1 grow-1 items-center gap-3 justify-center self-start">
          <UploadLinkTag />

          {sortedSelectedSections.map((section, index) => (
            <div className="border-solid border-2 border-slate-300 overflow-hidden flex justify-center items-center" key={index}>
              <img className="w-full h-full" src={`data:image/png;base64,${section.image_bytes}`} alt={`Section ${index}`} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

const PreviewStepTwo = memo(({ sortedMatchedSections, remixSettings, websiteName, selectedSection, onSelectSection, onOpenTemplatesModal }) => {
  return (
    <>
      <div className="mt-5 p-7 rounded-xl bg-slate-200 overflow-y-auto flex-1 flex">
        <div className="flex-1 flex flex-column grow-1 gap-3 items-center pr-[10px] border-solid border-0 border-r-[1px] border-slate-300">
          <p className="font-medium m-0 text-xl text-center">Uploaded sections</p>
          <UploadLinkTag />

          {sortedMatchedSections.map((section, index) => (
            <div className="flex gap-3 text-xl cursor-pointer" key={`section-uploaded-${index}`}>
              <span>{index + 1}</span>
              <div
                onClick={() => onSelectSection(section)}
                className={`border-solid border-2 overflow-hidden flex justify-center items-center rounded-xl ${
                  selectedSection?.id === section.id ? "border-blue-600" : "border-slate-300"
                }`}
              >
                {section.image_bytes && (
                  <img className="w-full h-full" src={`data:image/png;base64,${section.image_bytes}`} alt={`Section ${index}`} />
                )}

                {!section.image_bytes && section.screenshot && (
                  <img className="w-full h-full" src={section.screenshot} alt={`Section ${index}`} />
                )}

                {!section.image_bytes && !section.screenshot && (
                  <div className="flex justify-center items-center">
                    <p className="m-0 p-2 text-slate-500">No screenshot available</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="basis-2-3 ml-5">
          <p className="basis-full font-medium m-0 text-xl text-center mb-5">Template preview</p>

          {sortedMatchedSections.map((section, index) => (
            <React.Fragment key={`section-uploaded-preview-${section.id}-${section?.templates?.[0]?.id}`}>
              <div
                onClick={() => onSelectSection(section)}
                className={`flex flex-column gap-3 mb-5 cursor-pointer ${selectedSection?.id === section.id ? "border-solid border-2 border-blue-600" : ""}`}
              >
                <TemplatePreview
                  key={`section-uploaded-preview-${section?.templates?.[0]?.id}`}
                  template={section?.templates?.[0]}
                  options={remixSettings}
                >
                  <div className="absolute inset-0 m-[10px] flex flex-wrap text-lg items-end justify-end">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                          onSelectSection(section);
                        onOpenTemplatesModal();
                      }}
                      className="bg-white py-[7px] px-[20px] border-solid border-1 border-slate-300 rounded-xl flex gap-1 items-center cursor-pointer text-xl"
                    >
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.59844 1.8999C6.59844 1.54092 6.30742 1.2499 5.94844 1.2499C5.58945 1.2499 5.29844 1.54092 5.29844 1.8999H6.59844ZM5.29844 13.0999C5.29844 13.4589 5.58945 13.7499 5.94844 13.7499C6.30742 13.7499 6.59844 13.4589 6.59844 13.0999H5.29844ZM3.49844 2.5499H10.4984V1.2499H3.49844V2.5499ZM11.9484 3.9999V10.9999H13.2484V3.9999H11.9484ZM10.4984 12.4499H3.49844V13.7499H10.4984V12.4499ZM2.04844 10.9999V3.9999H0.748438V10.9999H2.04844ZM3.49844 12.4499C2.69762 12.4499 2.04844 11.8007 2.04844 10.9999H0.748438C0.748438 12.5187 1.97965 13.7499 3.49844 13.7499V12.4499ZM11.9484 10.9999C11.9484 11.8007 11.2992 12.4499 10.4984 12.4499V13.7499C12.0172 13.7499 13.2484 12.5187 13.2484 10.9999H11.9484ZM10.4984 2.5499C11.2992 2.5499 11.9484 3.19909 11.9484 3.9999H13.2484C13.2484 2.48112 12.0172 1.2499 10.4984 1.2499V2.5499ZM3.49844 1.2499C1.97965 1.2499 0.748438 2.48112 0.748438 3.9999H2.04844C2.04844 3.19909 2.69762 2.5499 3.49844 2.5499V1.2499ZM5.29844 1.8999V13.0999H6.59844V1.8999H5.29844ZM5.94844 8.1499H12.2484V6.8499H5.94844V8.1499Z" fill="black"/>
                      </svg>
                      Change Layout
                    </span>
                  </div>
                </TemplatePreview>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
});

const RemixBuilderPreview = () => {
  const [contextData, builder] = useContext(RemixBuilderContext);

  const sortedSelectedSections = contextData.uploaded_sections
    ?.filter((section) => section.selected)
    ?.sort((a, b) => a.position - b.position) || []

  const sortedMatchedSections = contextData.matched_sections
    ?.filter((section) => section.selected)
    ?.sort((a, b) => a.position - b.position) || []

  const [selectedSection, setSelectedSection] = useState(null);
  const [remixTemplatesModalOpen, setRemixTemplatesModalOpen] = useState(false);

  useEffect(() => {
    if (sortedMatchedSections.length > 0 && !selectedSection) {
      setSelectedSection(sortedMatchedSections[0]);
    }
  }, [sortedMatchedSections]);

  return (
    <React.Fragment>
      {contextData.uploaded_sections && (
        <div className="remix-editor-preview flex flex-column overflow-scroll grow-1">
          {contextData.current_step === 1 && (
            <PreviewStepOne
              sortedSelectedSections={sortedSelectedSections}
              websiteName={contextData.website.name}
            />
          )}
          {(contextData.current_step === 2 || contextData.current_step === 3) && (
            <>
              <PreviewStepTwo
                sortedMatchedSections={sortedMatchedSections}
                remixSettings={contextData.remix_settings}
                websiteName={contextData.website.name}
                selectedSection={selectedSection}
                onSelectSection={setSelectedSection}
                onOpenTemplatesModal={() => setRemixTemplatesModalOpen(true)}
              />
              <RemixTemplatesModal
                isOpen={remixTemplatesModalOpen}
                onClose={() => setRemixTemplatesModalOpen(false)}
                title="Change template"
                templates={contextData.section_templates}
                template={selectedSection?.templates?.[0]}
                onSelectTemplate={(sectionTemplate) => builder.changeMatchedSectionTemplate(selectedSection, sectionTemplate)}
              />
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default memo(RemixBuilderPreview);
