import cheerio from 'cheerio';

const fixUnclosedHtmlTags = (htmlString) => {
  try {
    if (typeof htmlString !== 'string') {
      throw new Error("Invalid input: HTML string is required");
    }

    const $ = cheerio.load(htmlString, { xmlMode: false });

    $('table tbody').each(function () {
      $(this).replaceWith($(this).html());
    });

    let fixedHtml = $.root().html().trim();
    fixedHtml = fixedHtml.replace(/<\/?(html|head|body)>/g, '');

    if (htmlString !== fixedHtml) {
      console.warn(`CF: Fixed missing tags detected.`, {
        original: htmlString,
        fixed: fixedHtml,
      });
    }

    return fixedHtml;
  } catch (error) {
    console.error("Error processing HTML:", error);
    return "";
  }
};

export default fixUnclosedHtmlTags;
