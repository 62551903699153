import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsIconDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Icon`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][icon-max-width-style]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-max-width-style"]
              ? element.options["icon-max-width-style"]
              : undefined
          }
          options={[
            { value: "none", text: "Auto" },
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][icon-max-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Icon Maximum Width"}
          value={
            element.options["icon-max-width-value"]
              ? element.options["icon-max-width-value"]
              : undefined
          }
          placeholder={300}
          condition={element.options["icon-max-width-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][icon-max-width-style-mobile]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-max-width-style-mobile"]
              ? element.options["icon-max-width-style-mobile"]
              : element.options["icon-max-width-style"]
              ? element.options["icon-max-width-style"]
              : undefined
          }
          options={[
            { value: "none", text: "Auto" },
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="mobile"
          show_reset={element.options["icon-max-width-style-mobile"] ? true : false}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][icon-max-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Icon Maximum Width"}
          value={
            element.options["icon-max-width-value-mobile"]
              ? element.options["icon-max-width-value-mobile"]
              : undefined
          }
          placeholder={300}
          condition={element.options["icon-max-width-style-mobile"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Icon size"
          setting_name="[options][icon-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-font-size"]
              ? element.options["icon-font-size"]
              : undefined
          }
          placeholder={
            variant.options["p-font-size"] ? variant.options["p-font-size"] : 16
          }
          min={0}
          max={200}
          range_step={1}
          device="desktop"
          allow_empty={true}
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="font-size"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("font-size");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Icon size"
          setting_name="[options][icon-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-font-size-mobile"]
              ? element.options["icon-font-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["p-font-size-mobile"]
              ? variant.options["p-font-size-mobile"]
              : variant.options["p-font-size"]) || 16
          }
          min={0}
          max={200}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Icon line height"
          setting_name="[options][icon-font-line-height]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-font-line-height"]
              ? element.options["icon-font-line-height"]
              : undefined
          }
          min={0}
          max={500}
          range_step={1}
          device="desktop"
          placeholder={50}
          allow_empty={true}
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $("<div />");
              $(elementHtml).append(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="line-height"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("line-height");
                const new_value = $(elementHtml).html();

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Icon line height"
          setting_name="[options][icon-font-line-height-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          placeholder={50}
          value={
            element.options["icon-font-line-height-mobile"]
              ? element.options["icon-font-line-height-mobile"]
              : undefined
          }
          min={0}
          max={500}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Font color"
          setting_name="[options][icon-font-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-font-color"]
              ? element.options["icon-font-color"]
              : undefined
          }
          placeholder={
            variant.options["p-font-color"]
              ? variant.options["p-font-color"]
              : "#000"
          }
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="color"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("color");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Font color"
          setting_name="[options][icon-font-color-mobile]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-font-color-mobile"]
              ? element.options["icon-font-color-mobile"]
              : undefined
          }
          placeholder={
            variant.options["p-font-color"]
              ? variant.options["p-font-color"]
              : "#000"
          }
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Border Style"
          setting_name="[options][icon-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-border-style"]
              ? element.options["icon-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Border color"
            setting_name="[options][icon-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["icon-border-color"]
                ? element.options["icon-border-color"]
                : undefined
            }
            placeholder={
              variant.options["icon-border-color"]
                ? variant.options["icon-border-color"]
                : element.options["icon-font-color"]
                ? element.options["icon-font-color"]
                : "#000"
            }
            condition={
              element.options["icon-border-style"] &&
              element.options["icon-border-style"] !== "" &&
              element.options["icon-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][icon-hover-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["icon-hover-border-color"]
                ? element.options["icon-hover-border-color"]
                : undefined
            }
            placeholder={
              variant.options["icon-hover-border-color"]
                ? variant.options["icon-hover-border-color"]
                : element.options["icon-border-color"]
                ? element.options["icon-border-color"]
                : undefined
            }
            condition={
              element.options["icon-border-style"] &&
              element.options["icon-border-style"] !== "" &&
              element.options["icon-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {element.options["icon-border-style"] &&
          element.options["icon-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][icon-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[`icon-border-width-${side}`]
                          ) == false
                            ? element.options[`icon-border-width-${side}`]
                            : element.options["icon-border-width"]
                            ? element.options["icon-border-width"].replace(
                                "px",
                                ""
                              )
                            : undefined
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Border Corner Radius"
          setting_name="[options][icon-border-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-border-radius"]
              ? element.options["icon-border-radius"]
              : undefined
          }
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][icon-border-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`icon-border-radius-${side}`]) ==
                    false
                      ? element.options[`icon-border-radius-${side}`]
                      : undefined
                  }
                  condition={element.options["icon-border-radius"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={element}
          label="Border Shadow"
          setting_name="[options][icon-border-shadow]"
          setting_type="shadow"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-border-shadow"]
              ? element.options["icon-border-shadow"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][icon-border-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["icon-border-shadow-horizontal-distance"]
              ? element.options["icon-border-shadow-horizontal-distance"]
              : undefined
          }
          condition={element.options["icon-border-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][icon-border-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["icon-border-shadow-horizontal-distance"]
              ? element.options["icon-border-shadow-horizontal-distance"]
              : undefined
          }
          condition={element.options["icon-border-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][icon-border-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["icon-border-shadow-blur-radius"]
              ? element.options["icon-border-shadow-blur-radius"]
              : undefined
          }
          condition={element.options["icon-border-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][icon-border-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["icon-border-shadow-spread-radius"]
              ? element.options["icon-border-shadow-spread-radius"]
              : undefined
          }
          condition={element.options["icon-border-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Shadow Color"
            setting_name="[options][icon-border-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["icon-border-shadow-color"]
                ? element.options["icon-border-shadow-color"]
                : undefined
            }
            condition={element.options["icon-border-shadow"] == "custom"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][icon-hover-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["icon-hover-shadow-color"]
                ? element.options["icon-hover-shadow-color"]
                : undefined
            }
            placeholder={
              element.options["icon-border-shadow-color"]
                ? element.options["icon-border-shadow-color"]
                : undefined
            }
            condition={element.options["icon-border-shadow"] == "custom"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label="Inset shadow position"
          setting_name="[options][icon-border-shadow-inset]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["icon-border-shadow-inset"]
              ? element.options["icon-border-shadow-inset"]
              : "false"
          }
          condition={element.options["icon-border-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsIconDesign;
