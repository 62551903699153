import React, { createContext, useState, useEffect } from "react";

export const RemixBuilderContext = createContext();

export const RemixBuilderProvider = (props) => {
  let context = {
    website: props.website,
    currentMonthYear: moment().format("MMMM YYYY"),
    environment: props.environment,
    python_service_url: props.PYTHON_SERVICE_URL,
    python_service_auth_token: props.PYTHON_SERVICE_AUTH_TOKEN,
    uploaded_sections: [],
    matched_sections: [],
    section_templates: [],
    current_step: 0,
    remix_settings: {
      cta_type: "page",
    },
    settings: {
      max_section_remixes: 50,
      used_section_remixes: 0,
    },
  };

  const [contextData, setContextData] = useState(context);

  let builder = {
    toggleSelectSection: function (section, targetKey = "uploaded_sections") {
      let updatedBuilder = { ...contextData };

      updatedBuilder[targetKey] = updatedBuilder[targetKey].map(
        (uploadedSection) => {
          if (uploadedSection.id === section.id) {
        if (uploadedSection.selected) {
          uploadedSection.selected = false;
        } else if (
          updatedBuilder.settings.used_section_remixes <
          updatedBuilder.settings.max_section_remixes
        ) {
          uploadedSection.selected = true;
        }
          }
          return uploadedSection;
        }
      );

      setContextData(updatedBuilder);
    },

    toggleAllUploadedSections: function () {
      let updatedBuilder = { ...contextData };

      if (
        updatedBuilder.uploaded_sections.some((section) => section.selected)
      ) {
        updatedBuilder.uploaded_sections = updatedBuilder.uploaded_sections.map(
          (section) => ({ ...section, selected: false })
        );
      } else {
        const limitToUse =
          updatedBuilder.settings.max_section_remixes -
          updatedBuilder.settings.used_section_remixes;

        updatedBuilder.uploaded_sections = updatedBuilder.uploaded_sections.map(
          (section, index) => ({
            ...section,
            selected: index < limitToUse,
          })
        );
      }

      setContextData(updatedBuilder);
    },

    updateSectionPosition: function (section, position) {
      let updatedBuilder = { ...contextData };

      updatedBuilder.uploaded_sections = updatedBuilder.uploaded_sections.map(
        (uploadedSection) => {
          if (uploadedSection.id === section.id) {
            return {
              ...uploadedSection,
              position: position,
            };
          }
          return uploadedSection;
        }
      );
      setContextData(updatedBuilder);
    },

    updateRemixSettings: function (key, value) {
      let updatedBuilder = { ...contextData };

      const newRemixSettings = {
        ...updatedBuilder.remix_settings,
        [key]: value,
      };

      setContextData({
        ...contextData,
        remix_settings: newRemixSettings,
      });
    },

    addNewMatchedSection: function (sectionTemplate = null) {
      let updatedBuilder = { ...contextData };

      let newSection = {
        id: crypto.randomUUID(),
        position: updatedBuilder.matched_sections.length + 1,
        selected: true,
        tags: {},
      };

      if (sectionTemplate !== null) {
        newSection.templates = [sectionTemplate];
        newSection.screenshot = sectionTemplate.screenshot;
      } else {
        newSection.templates = [
          {
            id: 0,
            name: "Create From Scratch",
            description: "Create a new section from scratch",
            image_bytes: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=",
          },
        ];
      }

      setContextData({...contextData, matched_sections: [...contextData.matched_sections, newSection]});
    },

    changeMatchedSectionTemplate: function (section, sectionTemplate) {
      let updatedBuilder = { ...contextData };

      updatedBuilder.matched_sections = updatedBuilder.matched_sections.map(matchedSection => {
        if (matchedSection.id === section.id) {
          return { ...matchedSection, templates: [sectionTemplate] };
        }

        return matchedSection;
      });

      setContextData(updatedBuilder);
    },

    goToPreviousStep: function () {
      let updatedBuilder = { ...contextData };
      updatedBuilder.current_step = updatedBuilder.current_step - 1;
      setContextData(updatedBuilder);
    },

    goToNextStep: function (newContextData = null) {
      let updatedBuilder = newContextData || { ...contextData };
      updatedBuilder.current_step = updatedBuilder.current_step + 1;
      setContextData(updatedBuilder);
    },

    removeSectionTag: function (section, tagId) {
      let updatedBuilder = { ...contextData };

      updatedBuilder.uploaded_sections = updatedBuilder.uploaded_sections.map(
        (uploadedSection) => {
          if (uploadedSection.id === section.id) {
            Object.keys(uploadedSection.tags).forEach(category => {
              uploadedSection.tags[category] = uploadedSection.tags[category].filter(tag => tag.id !== tagId);
            });
          }

          return uploadedSection;
        }
      );

      setContextData(updatedBuilder);
    },
  };

  useEffect(() => {
    let updatedBuilder = { ...contextData };

    if (updatedBuilder.uploaded_sections) {
      updatedBuilder.settings.used_section_remixes =
        updatedBuilder.uploaded_sections.filter((section) => section.selected).length;

      setContextData(updatedBuilder);
    }
  }, [contextData.uploaded_sections]);

  return (
    <RemixBuilderContext.Provider
      value={[contextData, builder, setContextData]}
    >
      {props.children}
    </RemixBuilderContext.Provider>
  );
};
