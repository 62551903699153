Chartkick.options = {
  colors: ["blue"],
};

const treatAsUTC = (date) => {
  let result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

const daysBetween = (startDate, endDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
};

const ReportsHelpers = {
  getGroupBy: (timeframe, range_start, range_end) => {
    const timeframeTypes = {
      "All Time": "year",
      "Today": "day",
      "Yesterday": "day",
      "This Week": "day",
      "This Month": "week",
      "This Quarter": "month",
      "This Year": "month",
      "Last 24 Hours": "day",
      "Last 7 Days": "day",
      "Last 30 Days": "week",
      "Last 90 Days": "week",
      "Last 365 Days": "month",
    };

    if (timeframeTypes[timeframe]) {
      return timeframeTypes[timeframe];
    } else {
      const difference = daysBetween(range_start, range_end);
      console.log("difference", difference);

      if (difference < 8) {
        return "day";
      } else if (difference < 32) {
        return "week";
      } else if (difference < 93) {
        return "month";
      } else if (difference > 365) {
        return "year";
      } else {
        return "month";
      }
    }
  },
};

export default ReportsHelpers;
