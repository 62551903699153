import React, { useState, useEffect, useRef } from "react";
import withLazyLoad from "../../utils/withLazyLoad";
const LazyReportCount = withLazyLoad(() => import("../reports/ReportCount"));
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";
import ProductVariantReport from "../product_variants/ProductVariantReport";

const ProductReport = (props) => {
  const [showProductVariants, setShowProductVariants] = useState(false);
  const element = props.element;
  const product = props.product;
  const product_variants = product.product_variants.sort((product_variant) => {
    product_variant.position;
  });

  const calculateSelectionRates = (element) => {
    const conversionsSpan = document.getElementById(
      "product-report-" + product.id
    );
    const conversions = parseInt($(conversionsSpan).text().replaceAll(",", ""));

    $(`.element-selection-report[data-product-id="${product.id}"]`).each(
      (i, elementSelectionReportDiv) => {
        const selectionsSpan = $(elementSelectionReportDiv).find(
          ".events-report-count"
        )[0];
        const conversionRateElement = $(elementSelectionReportDiv).find(
          ".conversion-rate"
        )[0];
        const conversionBarElement = $(elementSelectionReportDiv).find(
          ".element-selection-report-bar"
        )[0];

        const selections = parseInt(
          $(selectionsSpan).text().replaceAll(",", "")
        );
        let conversionRateValue =
          selections > 0 && conversions > 0
            ? (selections / conversions) * 100
            : 0;

        if (conversionRateValue > 100) {
          conversionRateValue = 100.0;
        }

        $(conversionRateElement).text(conversionRateValue.toFixed(2));

        if (conversionRateValue > 0) {
          $(conversionBarElement)
            .css("width", `${conversionRateValue}%`)
            .attr("aria-valuenow", conversionRateValue)
            .show();
        }
        $(elementSelectionReportDiv).css("order", selections);
      }
    );
  };

  return (
    <React.Fragment>
      <div
        className="row hard-center element-selection-report"
        data-element-id={element.id}
        style={{ width: "100%" }}
      >
        <div
          className="element-selection-report-bar"
          style={{ width: "100%" }}
        ></div>
        <div className="row hard-center">
          <div className="col-xs-4">
            <div
              className="text-xl ellipsis product-report-toggle"
              data-product-id={product.id}
              style={{ marginBottom: "5px", cursor: product_variants.length > 0 ? "pointer" : undefined }}
              onClick={() => {
                if (product_variants.length > 0) {
                  setShowProductVariants(!showProductVariants);
                }
              }}
            >
              {props.label}{" "}
              <span
                className={`glyphicon glyphicon-chevron-${
                  showProductVariants ? "down" : "right"
                } element-selection-report-toggle`}
                style={{
                  display: product_variants.length > 0 ? "inline" : "none",
                }}
              ></span>
            </div>
          </div>
          <div className="col-xs-8 right">
            <p className="light text-xl" style={{ marginBottom: "10px" }}>
              <strong>
                <LazyReportCount
                  inline={true}
                  id={`product-report-${product.id}`}
                  path={`/websites/${props.cta.website_id}/events_reports`}
                  payload={{
                    report_type: "distinct_count",
                    report: {
                      website_id: props.cta.website_id,
                      event_type: "Complete",
                      contactable_type: "Cta",
                      contactable_id: props.cta.id,
                      variant_id: props.step.variant_id,
                      step_id: props.step.id,
                      element_id: element.id,
                      product_id: product.id,
                      product_variant_id: null,
                      range_start: props.range_start,
                      range_end: props.range_end,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    props.calculationConversionRates();
                  }}
                />
              </strong>{" "}
              selected (<span className="conversion-rate">0.00</span>
              %)
              {!props.gateReports && (
                <React.Fragment>
                  {" - "}
                  <strong>
                    <LazyReportCount
                      id={`product-${product.id}-orders`}
                      inline={true}
                      path={`/websites/${props.cta.website_id}/events_reports`}
                      payload={{
                        report_type: "count",
                        report: {
                          website_id: props.cta.website_id,
                          event_type: "Order",
                          contactable_type: "Cta",
                          contactable_id: props.cta.id,
                          variant_id: props.step.variant_id,
                          step_id: props.step.id,
                          element_id: element.id,
                          range_start: props.range_start,
                          range_end: props.range_end,
                          field: null,
                          value: null,
                          product_id: product.id,
                          product_variant_id: null,
                          broadcast_id: null,
                        },
                      }}
                      callback={() => {
                        updateAOV(
                          `#product-${product.id}-orders`,
                          `#product-${product.id}-revenue`,
                          `#product-${product.id}-aov`
                        );
                        updateConversionRate(
                          `#product-report-${product.id}`,
                          `#product-${product.id}-orders`,
                          `#product-${product.id}-order-rate`
                        );
                      }}
                    />
                  </strong>{" "}
                  orders (
                  <span id={`product-${product.id}-order-rate`}>0.00</span>)
                  {" - "}
                  <strong>
                    <LazyReportCount
                      id={`product-${product.id}-revenue`}
                      inline={true}
                      path={`/websites/${props.cta.website_id}/events_reports`}
                      payload={{
                        report_type: "revenue",
                        report: {
                          website_id: props.cta.website_id,
                          range_start: props.range_start,
                          range_end: props.range_end,
                          event_type: "Order",
                          contactable_type: "Cta",
                          contactable_id: props.cta.id,
                          variant_id: props.step.variant_id,
                          step_id: props.step.id,
                          element_id: element.id,
                          field: null,
                          value: null,
                          product_id: product.id,
                          product_variant_id: null,
                          broadcast_id: null,
                        },
                      }}
                      callback={() => {
                        updateAOV(
                          `#product-${product.id}-orders`,
                          `#product-${product.id}-revenue`,
                          `#product-${product.id}-aov`
                        );
                      }}
                      currency={props.currency}
                    />
                  </strong>{" "}
                  revenue
                  {" - "}
                  <strong>
                    {props.currency}
                    <span id={`product-${product.id}-aov`}>0.00</span>
                  </strong>{" "}
                  AOV
                </React.Fragment>
              )}
            </p>
          </div>
        </div>
        {showProductVariants && (
          <div
            className="row hard-center"
            style={{
              display: "flex",
              width: "100%",
              flexBasis: "100%",
              flexWrap: "wrap",
              flexDirection: "column-reverse",
            }}
          >
            {product_variants
              .map((product_variant) => {
                return (
                  <ProductVariantReport
                    key={`product-variant-report-${product_variant.id}`}
                    id={`product-variant-report-${product_variant.id}`}
                    product={product}
                    product_variant={product_variant}
                    calculateSelectionRates={() => {
                      calculateSelectionRates();
                    }}
                    {...props}
                  />
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductReport;
