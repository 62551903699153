import React, { useState, useEffect, useRef } from "react";
import withLazyLoad from "../../utils/withLazyLoad";
const LazyReportCount = withLazyLoad(() => import("../reports/ReportCount"));
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";

const ProductVariantReport = (props) => {
  const element = props.element;
  const product = props.product;
  const product_variant = props.product_variant;

  return (
    <React.Fragment>
      <div
        className="row hard-center element-selection-report field-option-report"
        data-product-id={product.id}
      >
        <div
          className="element-selection-report-bar"
          style={{ width: undefined, display: "none" }}
        ></div>
        <div className="row hard-center">
          <div className="col-xs-4">
            <p className="ellipsis" style={{ marginBottom: "5px" }}>
              {product.title
                ? product.title
                : `Product ${product.position || product.id}`}
              {" - "}
              {product_variant.title
                ? product_variant.title
                : `Product Variant ${product_variant.position ? product_variant.position : product_variant.id}`}
            </p>
          </div>
          <div className="col-xs-8 right">
            <p className="light text-xl" style={{ marginBottom: "10px" }}>
              <strong>
                <LazyReportCount
                  inline={true}
                  id={`product-variant-report-${product_variant.id}`}
                  path={`/websites/${props.cta.website_id}/events_reports`}
                  payload={{
                    report_type: "distinct_count",
                    report: {
                      website_id: props.cta.website_id,
                      event_type: "Complete",
                      contactable_type: "Cta",
                      contactable_id: props.cta.id,
                      variant_id: props.step.variant_id,
                      step_id: props.step.id,
                      element_id: element.id,
                      product_id: product.id,
                      product_variant_id: product_variant.id,
                      range_start: props.range_start,
                      range_end: props.range_end,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    props.calculateSelectionRates();
                  }}
                />
              </strong>{" "}
              selected (<span className="conversion-rate">0.00</span>
              %){" - "}
              <strong>
                <LazyReportCount
                  id={`product-variant-${product_variant.id}-orders`}
                  inline={true}
                  path={`/websites/${props.cta.website_id}/events_reports`}
                  payload={{
                    report_type: "count",
                    report: {
                      website_id: props.cta.website_id,
                      event_type: "Order",
                      contactable_type: "Cta",
                      contactable_id: props.cta.id,
                      variant_id: props.step.variant_id,
                      step_id: props.step.id,
                      element_id: element.id,
                      range_start: props.range_start,
                      range_end: props.range_end,
                      field: null,
                      value: null,
                      product_id: product.id,
                      product_variant_id: product_variant.id,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    updateAOV(
                      `#product-variant-${product_variant.id}-orders`,
                      `#product-variant-${product_variant.id}-revenue`,
                      `#product-variant-${product_variant.id}-aov`
                    );
                    updateConversionRate(
                      `#product-variant-report-${product_variant.id}`,
                      `#product-variant-${product_variant.id}-orders`,
                      `#product-variant-${product_variant.id}-order-rate`
                    );
                  }}
                />
              </strong>{" "}
              orders (
              <span id={`product-variant-${product_variant.id}-order-rate`}>
                0.00
              </span>
              ){" - "}
              <strong>
                <LazyReportCount
                  id={`product-variant-${product_variant.id}-revenue`}
                  inline={true}
                  path={`/websites/${props.cta.website_id}/events_reports`}
                  payload={{
                    report_type: "revenue",
                    report: {
                      website_id: props.cta.website_id,
                      event_type: "Order",
                      contactable_type: "Cta",
                      contactable_id: props.cta.id,
                      variant_id: props.step.variant_id,
                      step_id: props.step.id,
                      element_id: element.id,
                      range_start: props.range_start,
                      range_end: props.range_end,
                      field: null,
                      value: null,
                      product_id: product.id,
                      product_variant_id: product_variant.id,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    updateAOV(
                      `#product-variant-${product_variant.id}-orders`,
                      `#product-variant-${product_variant.id}-revenue`,
                      `#product-variant-${product_variant.id}-aov`
                    );
                  }}
                  currency={props.currency}
                />
              </strong>{" "}
              revenue
              {" - "}
              <strong>
                {props.currency}
                <span id={`product-variant-${product_variant.id}-aov`}>
                  0.00
                </span>
              </strong>{" "}
              AOV
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductVariantReport;
