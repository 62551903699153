import React, { useState, useEffect, useRef } from "react";
import ReportsHelpers from "../../utils/ReportsHelpers";
import ReportCount from "../reports/ReportCount";
import ReportChart from "../reports/ReportChart";
import ReportList from "../reports/ReportList";

const WebsiteContactReferralSources = (props) => {
  const website = props.website;
  const [timeframeType, setTimeframeType] = useState("Last 30 Days");
  const [rangeStartValue, setRangeStartValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Last 24 Hours")[0].range_start
  );
  const [rangeEndValue, setRangeEndValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Last 24 Hours")[0].range_end
  );
  const rangeStartInput = useRef(null);
  const rangeEndInput = useRef(null);

  let group_by = ReportsHelpers.getGroupBy(timeframeType);
  let range_start = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_start;
  let range_end = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_end;

  if (timeframeType == "Date Range") {
    range_start = rangeStartValue;
    range_end = rangeEndValue;
    group_by = ReportsHelpers.getGroupBy(null, range_start, range_end);
  }

  useEffect(() => {
    $(rangeStartInput.current)
      .datetimepicker({
        defaultDate: new Date().setHours(0, 0, 0, 0),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date($(e.currentTarget).find("input").val()).setHours(0, 0, 0, 0);
        const value = new Date(timestamp).toISOString();
        setRangeStartValue(value);
      });
      
    $(rangeEndInput.current)
      .datetimepicker({
        defaultDate: new Date().setHours(23, 59, 59, 999),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date($(e.currentTarget).find("input").val()).setHours(23, 59, 59, 999);
        const value = new Date(timestamp).toISOString();
        setRangeEndValue(value);
      });
  }, [timeframeType, rangeStartValue, rangeEndValue]);

  return (
    <React.Fragment>
      <div className="row hard-center smooth">
        <div
          className="row hard-center some-vertical-space"
          style={{ marginBottom: "15px" }}
        >
          <div
            className={`col-xs-${
              props.overview == true && timeframeType == "Date Range" ? 4 : 8
            } no-padding-right`}
          >
            {props.overview == true && timeframeType == "Date Range" && (
              <h4 className="bold">
                <a
                  href={`/websites/${website.id}/insights?insight=referral_sources`}
                >
                  Top Referral Sources →
                </a>
              </h4>
            )}
            {props.overview == true && timeframeType !== "Date Range" && (
              <h4 className="bold">
                <a
                  href={`/websites/${website.id}/insights?insight=referral_sources`}
                >
                  Top Referral Sources →
                </a>
              </h4>
            )}
            {props.overview !== true && (
              <h4 className="bold">Top Referral Sources</h4>
            )}
          </div>
          <div
            className={`col-xs-${
              props.overview == true && timeframeType == "Date Range" ? 8 : 4
            } no-padding-sides`}
          >
            <div className="col-xs-3 timeframe-date-selector no-padding-right">
              <select
                className="text-field"
                style={{ float: "right" }}
                onChange={(event) => setTimeframeType(event.target.value)}
                defaultValue={timeframeType}
              >
                {props.timeframeTypes.map((option) => (
                  <option
                    value={option.value}
                    key={`timeframe-option-${option.value}`}
                  >
                    {['Today', 'Yesterday', 'This Week', 'This Month', 'This Quarter', 'This Year'].includes(option.value) ? `${option.value} (Timezone: UTC)` : option.value == 'Date Range' ? 'Date Range (UTC)' : option.value}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-end"
                ref={rangeEndInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field cursor-default"
                  placeholder="End"
                  value={new Date(rangeEndValue).toLocaleString()}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => {
                    let value = event.target.value;
                    const date = new Date(value);
                    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                    value = utcDate.toISOString();
                    setRangeEndValue(value);
                  }}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-start"
                ref={rangeStartInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field cursor-default"
                  placeholder="Start"
                  value={new Date(rangeStartValue).toLocaleString()}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => {
                    let value = event.target.value;
                    const date = new Date(value);
                    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                    value = utcDate.toISOString();
                    setRangeStartValue(value);
                  }}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row hard-center"
          style={{ borderTop: "1px solid rgb(233, 235, 241)" }}
        >
          <div className="col-xs-12" style={{ marginTop: "15px" }}>
            <p className="light" style={{ marginBottom: "6px" }}>
              The top referral sources contacts originate from
            </p>
          </div>
          <div className="col-xs-12">
            <ReportList
              path={`/websites/${props.website.id}/reports`}
              label="URL"
              payload={{
                table: "Contact",
                result_type: "group_by_value",
                range_start: range_start,
                range_end: range_end,
                value: "referral_source",
                range_type: "created_at",
                limit: props.overview == true ? 5 : 100,
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WebsiteContactReferralSources;
