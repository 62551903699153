var feature_gates = function () {
  var modal = $("#upgrade");
  var plan = $(modal).attr("data-plan");
  window.plan_type = $(modal).attr("data-plan-type");
  var plan_interval = $(modal).attr("data-plan-interval");
  var partner_type = $(modal).attr("data-partner-type");
  var website_premium = $(modal).attr("data-website-premium");
  var website_plan = $(modal).attr("data-website-plan");
  var cta_count = $(modal).attr("data-cta-count");
  var after_2024_deprecated = $(modal).attr("data-account-after-2024-deprecation") == 'true';

  // limit account features
  if (plan_type) {

    $(".funnel-vault").on("click", function (event) {
      subscriptions.upgrade_modal(
        event,
        false,
        "access the funnel vault and premium templates.",
        "2024_pro",
        "Triggered feature CTA – Vault"
      );
    });

    $(".upgrade-button").on("click", function (e) {
      if ($(e.target).is("a") == false || $(this).hasClass("upgrade-button")) {
        if (website_premium == "false" || ['free', '2024_pages', '2024_core', '2024_funnels'].includes(plan_type)) {
          subscriptions.upgrade_modal(
            event,
            false,
            "unlock pro features for the funnel builder.",
            "2024_pro",
            "Triggered feature CTA – Paid Features"
          );
        }
      }
    });

    $(".upgrade-plus-button").on("click", function (e) {
      if ($(e.target).is("a") == false || $(this).hasClass("upgrade-plus-button")) {
        if (website_premium == "false" || ['free', '2024_pages', '2024_core', '2024_funnels', '2024_pro'].includes(plan_type)) {
          subscriptions.upgrade_modal(
            event,
            false,
            "unlock Plus features.",
            "2024_plus",
            "Triggered feature CTA – Paid Features"
          );
        }
      }
    });

    $(".landing-pages-launch").on("click", function (e) {
      if (website_premium == "false" || ['free'].includes(plan_type)) {
        subscriptions.upgrade_modal(
          event,
          false,
          "launch landing pages and unlock all campaign formats.",
          "2024_pro",
          "Triggered feature CTA – Landing Pages"
        );
      }
    });

    if (plan_type == "free") {
      $(".affiliate-program").on("click", function (event) {
        subscriptions.upgrade_modal(
          event,
          false,
          "join the affiliate partner program",
          "partner",
          "Triggered feature CTA – Partner"
        );
      });
    }

    if (["agency", "affiliate"].includes(partner_type) == false) {
      $(".partner-program").on("click", function (event) {
        event.preventDefault();
        subscriptions.upgrade_modal(
          event,
          false,
          "join the affiliate partner program",
          "partner",
          "Triggered feature CTA – Partner"
        );
      });
    }

    if (["free"].includes(plan_type)) {
      $(".website-create").on("click", function () {
        subscriptions.upgrade_modal(
          event,
          false,
          "manage multiple websites",
          "2024_pro",
          "Triggered feature CTA – Website Limit"
        );
      });
    }

    if (
      [
        "free",
        "2024_pages",
        "2024_core",
        "2024_funnels",
        "2024_pro",
        "marketer",
        "2021_pro",
        "2022_pro",
        "2023_build", 
        "2023_launch",
      ].indexOf(plan_type) > -1
    ) {
      $("#accounts-logo-upload").on("click", function (event) {
        subscriptions.upgrade_modal(
          event,
          false,
          "add custom branding to your account",
          plan_type == "2022_pro" ? "team" : ['2023_build', '2023_launch'].includes(plan_type) ? '2023_pro' : "2024_plus",
          "Triggered feature CTA – Branded Account"
        );
      });
    }

    if (
      [
        "free",
        "2024_pages",
        "2024_core",
        "2024_funnels",
        "2024_pro",
        "marketer",
        "2021_pro",
        "2022_pro",
        "2023_build", 
        "2023_launch",
      ].indexOf(plan_type) > -1
    ) {
      $(".edit_account").on("submit", function (e) {
        if (
          $(this).find('select[name="account[from_email]"]').length > 0 &&
          $(this).find('select[name="account[from_email]"]').val() !==
            "app@convertflow.com"
        ) {
          e.preventDefault();
          subscriptions.upgrade_modal(
            event,
            false,
            "send custom branded lead alerts from your team",
            plan_type == "2022_pro" ? "team" : ['2023_build', '2023_launch'].includes(plan_type) ? '2023_pro' : "2024_plus",
            "Triggered feature CTA – Branded Alerts"
          );
          return false;
        } else {
          return true;
        }
      });
    }

    // limit website features

    // override 2024+ account plan with websites plan if website on lesser plan
    if (plan_type.indexOf("2024") > -1) {
      if (website_premium == "false") {
        window.plan_type = 'free';
      }
      if (website_premium == "true" && website_plan) {
        window.plan_type = website_plan;
      }
    }

    $(".upgrade-website-button").on("click", function (e) {
      if ($(e.target).is("a") == false || $(this).hasClass("upgrade-website-button")) {
        if (plan_type == "free" || website_premium == "false") {
          subscriptions.upgrade_modal(
            event,
            false,
            "go live on your own website and unlock pro features.",
            plan.indexOf('enterprise') > -1 ? "2024_plus" : "2024_pro",
            "Triggered feature CTA – Paid Features"
          );
        }
      }
    });

    if (
      (plan_type == "free" || website_premium == "false")
    ) {
      setTimeout(function () {
        $("select[name=\"cta[domain_id]\"]").on("change", function (e) {
          e.preventDefault();
          subscriptions.upgrade_modal(
            event,
            false,
            "go live on your own domain",
            plan_type == "free" ? "2024_pro" : "2024_pages",
            "Triggered feature CTA – Paid Features"
          );
        });
      }, 100);
    }

    if (
      (["free", "2024_pages"].includes(plan_type) || website_premium == "false") &&
      cta_count &&
      parseInt(cta_count) >= 5
    ) {
      setTimeout(function () {
        $(".new-cta").on("click", function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "create more than 5 pages and campaigns",
            ["free"].includes(plan_type) ? "2024_pro" : "2024_core",
            "Triggered feature CTA – CTA Limit"
          );
        });
      }, 100);
    }
    
    if (
      (["2024_core", "2024_funnels", "2023_build"].includes(plan_type) || website_premium == "false") &&
      cta_count &&
      parseInt(cta_count) >= 10
    ) {
      setTimeout(function () {
        $(".new-cta").on("click", function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "have more than 10 active landing pages and campaigns",
            "2024_pro",
            "Triggered feature CTA – CTA Limit"
          );
        });
      }, 100);
    }

    if (
      (["2024_pages", "2024_core", "2024_funnels", "2024_pro"].includes(plan_type) || website_premium == "false") &&
      cta_count &&
      parseInt(cta_count) >= 25
    ) {
      setTimeout(function () {
        $(".new-cta").on("click", function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "create more than 25 pages and campaigns",
            "2024_plus",
            "Triggered feature CTA – CTA Limit"
          );
        });
      }, 100);
    }

    if (plan_type == "free" || website_premium == "false") {
      $(".domain-create").on("click", function () {
        subscriptions.upgrade_modal(
          event,
          false,
          "add custom domains for landing pages",
          plan_type == "free" ? "2024_pro" : "2024_pages",
          "Triggered feature CTA – Custom Domain"
        );
      });
    }

    if (plan_type == "free" || website_premium == "false") {
      $("#website_powered_by")
        .unbind("click")
        .on("click", function (event) {
          subscriptions.upgrade_modal(
            event,
            false,
            'remove "powered by" links',
            plan_type == "free" ? "2024_pro" : "2024_pages",
            "Triggered feature CTA – Remove Powered By"
          );
        });
    }

    if (
      ["free", "2024_pages", "2024_core", "2024_funnels", "2022_pro", "2023_build", "2023_launch"].indexOf(plan_type) > -1 ||
      (plan_type == '2024_pro' && after_2024_deprecated) || 
      website_premium == "false"
    ) {
      $(
        "body[data-controller='broadcasts'], body[data-controller='campaign_templates']"
      ).on("click", ".broadcast-create", function (event) {
        $("#newBroadcastModal").remove();
        subscriptions.upgrade_modal(
          event,
          false,
          "create broadcast campaigns",
          plan_type == '2022_pro' ? 'team' : ['2023_build', '2023_launch'].includes(plan_type) ? '2023_pro' : "2024_plus",
          "Triggered feature CTA – Broadcasts"
        );
      });
    }

    if (
      ["free", "2024_pages", "2024_core", "2024_funnels", "2024_pro"].indexOf(
        plan_type
      ) > -1 ||
      website_premium == "false"
    ) {
      $(document).on("click", ".area-new", function (event) {
        event.preventDefault();
        $("#areaModal").modal('hide');
        $("#areaModal").remove();
        subscriptions.upgrade_modal(
          event,
          false,
          "launch targeted embeds",
          "2024_plus",
          "Triggered feature CTA – Targeted Embeds"
        );
      });
    }

    if (
      $.inArray(plan_type, [
        "free",
        "2024_pages",
        "2024_core",
        "2024_funnels",
        "2024_pro",
        "2021_pro",
        "2022_pro",
        "2023_build", 
        "2023_launch"
      ]) > -1 ||
      website_premium == "false"
    ) {
      $("input[data-name='segment-events']").on("change", function (e) {
        e.preventDefault();
        $(e.target).prop("checked", false);
        subscriptions.upgrade_modal(
          event,
          false,
          "send events stream to integrations",
          "2024_plus",
          "Triggered feature CTA – Events Streaming"
        );
      });
  
      $("input[data-name*='segment']").each(function (i, input) {
        $(input).on("focus", function (e) {
          e.preventDefault();
          $(e.target).val("");
          subscriptions.upgrade_modal(
            event,
            false,
            "send events stream to integrations",
            "2024_plus",
            "Triggered feature CTA – Events Streaming"
          );
        });
      });
    }
  
    if (
      ["free", "2024_pages", "2024_core", "2024_funnels", "2024_pro"].includes(plan_type) || website_premium == "false"
    ) {

      if (plan_type !== "2024_pro" || after_2024_deprecated) {
        $("#google-analytics-track-toggle")
          .on("click", function (event) {
            $("#google-analytics-track").prop("checked", false);
            $("#website-google-analytics-track").val("false");
            subscriptions.upgrade_modal(
              event,
              false,
              "stream events to Google Analytics 4",
              "2024_plus",
              "Triggered feature CTA – Events Streaming"
            );
          });

          $('.integration-item[data-plan="plus"]').on('click', function (event) {
            subscriptions.upgrade_modal(
              event,
              false,
              "use events streaming integrations",
              "2024_plus",
              "Triggered feature CTA – Events Streaming"
            );
          });
      }

      $("#google-analytics-data-layer-toggle")
        .on("click", function (event) {
          $("#google-analytics-data-layer").prop("checked", false);
          $("#website-google-analytics-data-layer").val("false");
          subscriptions.upgrade_modal(
            event,
            false,
            "stream events to Google Tag Manager",
            "2024_plus",
            "Triggered feature CTA – Events Streaming"
          );
        });
    }

    if (
      ["free", "2024_pages", "2024_core", "2024_funnels"].includes(plan_type) || website_premium == "false"
    ) {
      $('.integration-item[data-plan="pro"]').on('click', function (event) {
        subscriptions.upgrade_modal(
          event,
          false,
          "use Pro integrations",
          "2024_pro",
          "Triggered feature CTA – Pro Integrations"
        );
      });
    }
  
    if (["free", "2024_pages", "2024_core", "2024_funnels", "2024_pro"].includes(plan_type) || website_premium == "false") {
      $(".conditions-groups-create").on("click", function (event) {
        event.preventDefault();
        $('#newConditionsGroupModal').remove();
  
        subscriptions.upgrade_modal(
          event,
          false,
          "save dynamic visitor segments",
          "2024_plus",
          "Triggered feature CTA – Visitor Segments"
        );
      });
    }

    if ($('body').attr('data-controller') == 'campaign_templates') {
      const category = decodeURIComponent(getUrlParameter('category'));
      console.log('category', category);
      
      if (category.indexOf('Quiz') > -1 && (["free", "2024_pages", "2024_core"].includes(plan_type) || website_premium == "false")) {
        setTimeout(function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "build quiz funnels.",
            "2024_pro",
            "Triggered feature CTA – Quiz",
            true
          );
        }, 500);
      };

      if (category.indexOf('Upsell') > -1 && (["free", "2024_pages", "2024_core"].includes(plan_type) || (plan_type == '2024_pro' && after_2024_deprecated) || website_premium == "false")) {
        setTimeout(function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "launch dynamic 1-click upsell campaigns.",
            "2024_plus",
            "Triggered feature CTA – Upsells & Cross-sells",
            true
          );
        }, 500);
      };

      if (category.indexOf('Browse Abandonment') > -1 && (["free", "2024_pages", "2024_core"].includes(plan_type) || (plan_type == '2024_pro' && after_2024_deprecated) || website_premium == "false")) {
        setTimeout(function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "launch dynamic browse abandonment campaigns.",
            "2024_plus",
            "Triggered feature CTA – Browse Abandonment",
            true
          );
        }, 500);
      };

      if (category.indexOf('Cart Abandonment') > -1 && (["free", "2024_pages", "2024_core"].includes(plan_type) || (plan_type == '2024_pro' && after_2024_deprecated) || website_premium == "false")) {
        setTimeout(function () {

          subscriptions.upgrade_modal(
            event,
            false,
            "launch dynamic cart abandonment campaigns.",
            "2024_plus",
            "Triggered feature CTA – Cart Abandonment",
            true
          );
        }, 500);
      };
    }

    if (
      ["free", "2024_pages", "2024_core"].indexOf(plan_type) > -1 || website_premium == "false"
    ) {
      $("#zapier-connect").on("click", function (event) {
        event.preventDefault();
        $("#apikey").modal('hide');
        $("#apikey").remove();

        subscriptions.upgrade_modal(
          event,
          false,
          "connect to Zapier and API connectors",
          "2024_pro",
          "Triggered feature CTA – Zapier"
        );
      });

      $(".webhook-create").on("click", function (event) {
        event.preventDefault();

        $("#newWebhookModal").modal('hide');
        $("#newWebhookModal").remove();

        subscriptions.upgrade_modal(
          event,
          false,
          "create custom webhooks and Zapier triggers",
          "2024_pro",
          "Triggered feature CTA – Webhooks"
        );
      });
    }
  }
  

  $(
    '.pricing-table a[data-toggle="collapse"], .feature-gate a[data-toggle="collapse"]'
  ).each(function (i, a) {
    $(a)
      .off("click.pricing_table")
      .on("click.pricing_table", function () {
        $(a).parents(".panel").toggleClass("open");
      });
  });
};

export default feature_gates;
