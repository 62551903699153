import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsCollapseDesign = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Collapses`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][collapse-max-width-style]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["collapse-max-width-style"]
              ? element.options["collapse-max-width-style"]
              : "full"
          }
          options={[
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="desktop"
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][collapse-max-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Collapse Maximum Width"}
          value={
            element.options["collapse-max-width-value"]
              ? element.options["collapse-max-width-value"]
              : undefined
          }
          placeholder={300}
          condition={element.options["collapse-max-width-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][collapse-max-width-style-mobile]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["collapse-max-width-style-mobile"]
              ? element.options["collapse-max-width-style-mobile"]
              : undefined
          }
          options={[
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][collapse-max-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Collapse Maximum Width"}
          value={
            element.options["collapse-max-width-value-mobile"]
              ? element.options["collapse-max-width-value-mobile"]
              : undefined
          }
          placeholder={300}
          condition={
            element.options["collapse-max-width-style-mobile"] == "custom"
          }
          min={0}
          max={2560}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Items Distance"
          setting_name="[options][collapse-items-distance]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["collapse-items-distance"]
              ? element.options["collapse-items-distance"]
              : undefined
          }
          min={4}
          placeholder={
            variant.options["collapse-items-distance"]
              ? variant.options["collapse-items-distance"]
              : "4px"
          }
          max={100}
          range_step={1}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][collapse-heading-font-family]"
          label={"Title Font Family"}
          setting_type="select"
          object_type="elements"
          value={
            element.options["collapse-heading-font-family"]
              ? element.options["collapse-heading-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][collapse-heading-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          value={
            element.options["collapse-heading-font-weight"]
              ? element.options["collapse-heading-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Font Size"
          setting_name="[options][collapse-heading-font-size]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["collapse-heading-font-size"]
              ? element.options["collapse-heading-font-size"]
              : undefined
          }
          min={0}
          placeholder={variant.options["collapse-heading-font-size"] || 16}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Font Size"
          setting_name="[options][collapse-heading-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["collapse-heading-font-size-mobile"]
              ? element.options["collapse-heading-font-size-mobile"]
              : undefined
          }
          min={0}
          placeholder={
            variant.options["collapse-heading-font-size-mobile"] || 16
          }
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Font Color"
          setting_name="[options][collapse-heading-font-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["collapse-heading-font-color"]
              ? element.options["collapse-heading-font-color"]
              : undefined
          }
          placeholder={
            variant.options["collapse-heading-font-color"]
              ? variant.options["collapse-heading-font-color"]
              : "#000"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Title Background Color"
          setting_name="[options][collapse-heading-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["collapse-heading-background-color"]
              ? element.options["collapse-heading-background-color"]
              : undefined
          }
          placeholder={
            variant.options["collapse-heading-background-color"]
              ? variant.options["collapse-heading-background-color"]
              : "rgba(183, 183, 183, 0.21)"
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Border Style"
          setting_name="[options][collapse-heading-border-style]"
          setting_type="border_style"
          object_type="elements"
          value={
            element.options["collapse-heading-border-style"]
              ? element.options["collapse-heading-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Border Color"
          setting_name="[options][collapse-heading-border-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["collapse-heading-border-color"]
              ? element.options["collapse-heading-border-color"]
              : undefined
          }
          placeholder={
            variant.options["collapse-heading-border-color"]
              ? variant.options["collapse-heading-border-color"]
              : "#000"
          }
          condition={["dotted", "dashed", "solid"].includes(
            element.options["collapse-heading-border-style"]
          )}
          show_reset={true}
          gradient={true}
        />

        {element.options["collapse-heading-border-style"] &&
          element.options["collapse-heading-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <p className="field-title">Title Border Width</p>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][collapse-heading-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        object_id={element.id}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        value={
                          _.isNil(
                            element.options[
                              `collapse-heading-border-width-${side}`
                            ]
                          ) == false
                            ? element.options[
                                `collapse-heading-border-width-${side}`
                              ]
                            : element.options["collapse-heading-border-width"]
                            ? element.options[
                                "collapse-heading-border-width"
                              ].replace("px", "")
                            : undefined
                        }
                        placeholder={
                          variant.options[
                            `collapse-heading-border-width-${side}`
                          ]
                            ? variant.options[
                                `collapse-heading-border-width-${side}`
                              ]
                            : "0px"
                        }
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Box Corner Radius"
          setting_name="[options][collapse-heading-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          show_reset={true}
          object_type="elements"
          value={
            element.options["collapse-heading-radius"]
              ? element.options["collapse-heading-radius"]
              : undefined
          }
          show_reset={true}
        />

        {element.options["collapse-heading-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][collapse-heading-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={element}
                    object_id={element.id}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="elements"
                    value={
                      _.isNil(
                        element.options[`collapse-heading-radius-${side}`]
                      ) == false
                        ? element.options[`collapse-heading-radius-${side}`]
                        : undefined
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder={
                      variant.options[`collapse-heading-radius-${side}`]
                        ? variant.options[`collapse-heading-radius-${side}`]
                        : "0px"
                    }
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Content Background Color"
          setting_name="[options][collapse-content-background-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["collapse-content-background-color"]
              ? element.options["collapse-content-background-color"]
              : undefined
          }
          placeholder={
            variant.options["collapse-content-background-color"] || "#F5F5F5"
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Box Shadow"
          setting_name="[options][collapse-shadow]"
          setting_type="shadow"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["collapse-shadow"]
              ? element.options["collapse-shadow"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][collapse-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["collapse-shadow-horizontal-distance"]
              ? element.options["collapse-shadow-horizontal-distance"]
              : undefined
          }
          placeholder={variant.options["collapse-shadow-horizontal-distance"]}
          condition={element.options["collapse-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][collapse-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["collapse-shadow-vertical-distance"]
              ? element.options["collapse-shadow-vertical-distance"]
              : undefined
          }
          condition={element.options["collapse-shadow"] == "custom"}
          placeholder={variant.options["collapse-shadow-vertical-distance"]}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][collapse-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["collapse-shadow-blur-radius"]
              ? element.options["collapse-shadow-blur-radius"]
              : undefined
          }
          placeholder={variant.options["collapse-shadow-blur-radius"]}
          condition={element.options["collapse-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][collapse-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["collapse-shadow-spread-radius"]
              ? element.options["collapse-shadow-spread-radius"]
              : undefined
          }
          placeholder={variant.options["collapse-shadow-spread-radius"]}
          condition={element.options["collapse-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Shadow Color"
          setting_name="[options][collapse-shadow-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["collapse-shadow-color"]
              ? element.options["collapse-shadow-color"]
              : undefined
          }
          placeholder={variant.options["collapse-shadow-color"]}
          condition={element.options["collapse-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsCollapseDesign;
