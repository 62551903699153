import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderCanvasSelectPopover = (props) => {
  const [contextData] = useContext(VariantContextData);

  let showDuplicateButton = true;
  let showRemoveButton = true;
  let showResetButton = ["sections", "elements"].includes(props.object_type);
  let object_type = props.object_type;
  let object_id = props.object_id;
  let hidePopover = false;
  let rowObject;
  let elementObject;

  if (
    props.object_type == "sections" &&
    props.object &&
    props.object.element_id
  ) {
    rowObject = contextData.objects.variant[props.object_type][props.object_id];
    elementObject = contextData.objects.variant.elements[rowObject.element_id];

    object_type = "elements";
    object_id = elementObject.id;

    if (["collapse", "tabs", "carousel"].includes(elementObject.element_type)) {
      hidePopover = true;
    }
  }

  const waitingClick = useRef(false);

  const clickWrapper = (event, action) => {
    event.stopPropagation();

    if (waitingClick.current) return;
    waitingClick.current = true;

    action();

    setTimeout(() => {
      waitingClick.current = false;
    }, 500);
  };

  return (
    <React.Fragment>
      {!hidePopover && (
        <div
          className={`select-label-popover cursor-pointer absolute ${
            props.object_type == "elements"
              ? "-bottom-20 left-1/2 -translate-x-1/2"
              : "-top-20 right-0"
          } bg-white dark:bg-black w-175 shadow-md p-0 z-10`}
          data-object_type={props.object_type}
          data-object_id={props.object_id}
          style={{ display: "none", borderRadius: "10px" }}
        >
          <div
            className={`flex items-center ${
              props.object_type == "elements" ? "-mt-20 pt-20" : "-mb-20 pb-20"
            }`}
          >
            <button
              title="Move Up"
              className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-black p-2 pt-3 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-black dark:hover:border-black`}
              style={{
                borderRadius: "10px 0px 0px 10px",
              }}
              onClick={(e) => {
                clickWrapper(e, () => {
                  dispatchCustomEvent("moveObjectUp", {
                    object_type: object_type,
                    object_id: object_id,
                    editing: false,
                  });
                });
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 8L9 3M9 3L14.25 8M9 3V15"
                  stroke={contextData.dark_mode ? "white" : "black"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <button
              title="Move Down"
              className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-black p-2 pt-3 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-black dark:hover:border-black`}
              style={{
                borderRadius: "0px",
              }}
              onClick={(e) => {
                clickWrapper(e, () => {
                  dispatchCustomEvent("moveObjectDown", {
                    object_type: object_type,
                    object_id: object_id,
                    editing: false,
                  });
                });
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 10L9 15M9 15L14.25 10M9 15V3"
                  stroke={contextData.dark_mode ? "white" : "black"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <button
              title="Edit"
              className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-black p-2 pt-3 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-black dark:hover:border-black`}
              style={{
                borderRadius: "0px",
              }}
              onClick={(e) => {
                clickWrapper(e, () => {
                  dispatchCustomEvent("selectObject", {
                    object_type: props.object_type,
                    object_id: props.object_id,
                    tab: props.tab,
                  });
                });
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                  stroke={contextData.dark_mode ? "white" : "black"}
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            {showDuplicateButton ? (
              <button
                title="Duplicate"
                className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-black p-2 pt-3 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-black dark:hover:border-black`}
                style={{
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  clickWrapper(e, () => {
                    dispatchCustomEvent("duplicateObject", {
                      object_type: props.object_type,
                      object_id: props.object_id,
                    });
                  });
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.25 9.84375L14.25 4.49994C14.25 3.25729 13.2426 2.24992 12 2.24994L6.65625 2.25M9.75 15.75L4.6875 15.75C3.75552 15.75 3 14.9945 3 14.0625L3 6.75C3 5.81802 3.75552 5.0625 4.6875 5.0625L9.75 5.0625C10.682 5.0625 11.4375 5.81802 11.4375 6.75L11.4375 14.0625C11.4375 14.9945 10.682 15.75 9.75 15.75Z"
                    stroke={contextData.dark_mode ? "white" : "black"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            ) : (
              ""
            )}

            {showResetButton ? (
              <button
                title="Reset To Theme Styles"
                className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-black p-2 pt-3 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-black dark:hover:border-black`}
                style={{
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  clickWrapper(e, () => {
                    dispatchCustomEvent("resetStyles", {
                      object_type: props.object_type,
                      object_id: props.object_id,
                    });
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.87115 14.5C5.88914 17.6939 8.80463 20 12.2424 20C16.5268 20 20 16.4183 20 12C20 7.58172 16.5268 4 12.2424 4C9.37103 4 6.86399 5.60879 5.52267 8M7.87879 9H4V5"
                    stroke={contextData.dark_mode ? `white` : "black"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            ) : (
              ""
            )}

            {showRemoveButton ? (
              <button
                title="Remove"
                className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-black p-2 pt-3 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-black dark:hover:border-black`}
                style={{
                  borderRadius: "0px 10px 10px 0px",
                }}
                onClick={(e) => {
                  clickWrapper(e, () => {
                    dispatchCustomEvent("removeObject", {
                      object_type: props.object_type,
                      object_id: props.object_id,
                    });
                  });
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 4.63235H15M6.75 2.25H11.25M7.5 12.5735V7.80882M10.5 12.5735V7.80882M11.625 15.75H6.375C5.54657 15.75 4.875 15.0389 4.875 14.1618L4.53255 5.45953C4.5148 5.00837 4.85544 4.63235 5.2819 4.63235H12.7181C13.1446 4.63235 13.4852 5.00837 13.4674 5.45953L13.125 14.1618C13.125 15.0389 12.4534 15.75 11.625 15.75Z"
                    stroke={contextData.dark_mode ? "white" : "black"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default VariantBuilderCanvasSelectPopover;
