import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ElementTimer = (props) => {
  let element = props.element;
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );

  if (props.builder == true) {
    useEffect(() => {
      function hideTimer() {
        if (
          element.options["timer-end-action"] == "timer" ||
          element.options["timer-end-action"] == "step"
        ) {
          $(clock).css("opacity", "1");
          $(clock).find(".cf-timer-inner").show();
          $(clock).find(".cf-timer-message").show();
        } else if (element.options["timer-end-action"] == "message") {
          $(clock).css("opacity", "1");
          $(clock).find(".cf-timer-inner").hide();
          $(clock).find(".cf-timer-message").show();
        } else if (element.options["timer-end-action"] == "hide") {
          $(clock).css("opacity", "0.1");
        }
      }

      var timeinterval;
      var clock = document.querySelector(
        '#builder .cf-timer[data-id="' + element.id + '"]'
      );

      var daysSpan = clock.querySelector(
        ".cf-timer-unit[data-unit-type='days'] .cf-timer-unit-number"
      );
      var hoursSpan = clock.querySelector(
        ".cf-timer-unit[data-unit-type='hours'] .cf-timer-unit-number"
      );
      var minutesSpan = clock.querySelector(
        ".cf-timer-unit[data-unit-type='minutes'] .cf-timer-unit-number"
      );
      var secondsSpan = clock.querySelector(
        ".cf-timer-unit[data-unit-type='seconds'] .cf-timer-unit-number"
      );

      var getTimeRemaining = function (endtime) {
        var t = Date.parse(endtime) - Date.parse(new Date());
        var seconds = Math.floor((t / 1000) % 60);
        var minutes = Math.floor((t / 1000 / 60) % 60);
        var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
          total: t,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      };

      var initializeClock = function (element, clock, endtime) {
        var updateClock = function () {
          var t = getTimeRemaining(endtime);

          if (daysSpan) {
            let daysDiv = clock.querySelector(
              ".cf-timer-unit[data-unit-type='days']"
            );
            daysSpan.innerHTML = t.days > 0 ? t.days : 0;

            if (t.days == 0) {
              daysDiv.classList.add("cf-timer-hidden");
            } else {
              daysDiv.classList.remove("cf-timer-hidden");
            }
          }
          if (hoursSpan) {
            let hoursDiv = clock.querySelector(
              ".cf-timer-unit[data-unit-type='hours']"
            );
            hoursSpan.innerHTML = t.hours > 0 ? t.hours : 0;

            if (t.days == 0 && t.hours == 0) {
              hoursDiv.classList.add("cf-timer-hidden");
            } else {
              hoursDiv.classList.remove("cf-timer-hidden");
            }
          }
          if (minutesSpan) {
            let minutesDiv = clock.querySelector(
              ".cf-timer-unit[data-unit-type='minutes']"
            );
            minutesSpan.innerHTML = t.minutes > 0 ? t.minutes : 0;

            if (t.days == 0 && t.hours == 0 && t.minutes == 0) {
              minutesDiv.classList.add("cf-timer-hidden");
            } else {
              minutesDiv.classList.remove("cf-timer-hidden");
            }
          }
          if (secondsSpan) {
            let secondsDiv = clock.querySelector(
              ".cf-timer-unit[data-unit-type='seconds']"
            );
            secondsSpan.innerHTML = t.seconds > 0 ? t.seconds : 0;
          }

          if (t.total <= 0) {
            clearInterval(timeinterval);
            hideTimer();
          }
        };

        updateClock();
        timeinterval = setInterval(updateClock, 1000);
      };

      var deadline;

      if (element.options["timer-type"] == "static") {
        let endDate =
          element.options["timer-date"] ||
          moment().endOf("day").format("YYYY-MM-DD h:mm:ss a");

        deadline = new Date(Date.parse(endDate));
      } else if (element.options["timer-type"] == "daily") {
        var hours =
          element.options["timer-hours"] || element.options["timer-hours"] == 0
            ? parseInt(element.options["timer-hours"])
            : 23;
        var minutes =
          element.options["timer-minutes"] ||
          element.options["timer-minutes"] == 0
            ? parseInt(element.options["timer-minutes"])
            : 59;
        var seconds =
          element.options["timer-seconds"] ||
          element.options["timer-seconds"] == 0
            ? parseInt(element.options["timer-seconds"])
            : 59;
        deadline = new Date(new Date().setHours(hours, minutes, seconds, 999));
      } else if (element.options["timer-type"] == "relative") {
        var d = new Date();
        var days =
          d.getDate() +
          (element.options["timer-days"] || element.options["timer-days"] == 0
            ? parseInt(element.options["timer-days"])
            : 0);
        var hours =
          d.getHours() +
          (element.options["timer-hours"] || element.options["timer-hours"] == 0
            ? parseInt(element.options["timer-hours"])
            : 23);
        var minutes =
          d.getMinutes() +
          (element.options["timer-minutes"] ||
          element.options["timer-minutes"] == 0
            ? parseInt(element.options["timer-minutes"])
            : 59);
        var seconds =
          d.getSeconds() +
          (element.options["timer-seconds"] ||
          element.options["timer-seconds"] == 0
            ? parseInt(element.options["timer-seconds"])
            : 59);
        deadline = new Date(
          new Date(new Date().setDate(days)).setHours(
            hours,
            minutes,
            seconds,
            999
          )
        );
      }

      if (clock && deadline > new Date()) {
        $(clock).css("opacity", "1");
        $(clock).find(".cf-timer-inner").show();
        $(clock).find(".cf-timer-message").hide();
        initializeClock(element, clock, deadline);
      } else {
        hideTimer();
      }

      return function cleanup() {
        clearInterval(timeinterval);
        if (daysSpan) {
          daysSpan.innerHTML = 0;
        }
        if (hoursSpan) {
          hoursSpan.innerHTML = 0;
        }
        if (minutesSpan) {
          minutesSpan.innerHTML = 0;
        }
        if (secondsSpan) {
          secondsSpan.innerHTML = 0;
        }
      };
    }, [props.element]);
  }

  return (
    <React.Fragment>
      <div
        id={"element" + element.id + "-content"}
        className="element-content cf-timer"
        data-id={element.id}
        data-timer-type={element.options["timer-format"]}
      >
        {element.options["timer-format"] != "inline" && (
          <div className="cf-timer-inner cf-timer-card">
            {[undefined, "d/h/m/s", "d/h/m", "d/h", "d"].includes(
              element.options["timer-units"]
            ) && (
              <div
                className="cf-timer-unit cf-timer-unit-block"
                data-unit-type="days"
              >
                <div
                  className="cf-timer-unit-number"
                  data-font={element.options["timer-number-font"]}
                >
                  0
                </div>
                <div
                  className="cf-timer-unit-text"
                  data-font={element.options["timer-unit-font"]}
                >
                  {element.options["timer-days-translation"]
                    ? element.options["timer-days-translation"]
                    : "Days"}
                </div>
              </div>
            )}
            {[undefined, "d/h/m/s", "d/h/m", "d/h"].includes(
              element.options["timer-units"]
            ) && (
              <div
                className="cf-timer-unit cf-timer-unit-block"
                data-unit-type="hours"
              >
                <div className="cf-timer-unit-number">0</div>
                <div className="cf-timer-unit-text">
                  {element.options["timer-hours-translation"]
                    ? element.options["timer-hours-translation"]
                    : "Hours"}
                </div>
              </div>
            )}
            {[undefined, "d/h/m/s", "d/h/m"].includes(
              element.options["timer-units"]
            ) && (
              <div
                className="cf-timer-unit cf-timer-unit-block"
                data-unit-type="minutes"
              >
                <div className="cf-timer-unit-number">0</div>
                <div className="cf-timer-unit-text">
                  {element.options["timer-minutes-translation"]
                    ? element.options["timer-minutes-translation"]
                    : "Minutes"}
                </div>
              </div>
            )}
            {[undefined, "d/h/m/s"].includes(
              element.options["timer-units"]
            ) && (
              <div
                className="cf-timer-unit cf-timer-unit-block"
                data-unit-type="seconds"
              >
                <div className="cf-timer-unit-number">0</div>
                <div className="cf-timer-unit-text">
                  {element.options["timer-seconds-translation"]
                    ? element.options["timer-seconds-translation"]
                    : "Seconds"}
                </div>
              </div>
            )}
          </div>
        )}
        {element.options["timer-format"] == "inline" && (
          <div
            className="cf-timer-inner cf-timer-inline"
            data-font={element.options["timer-number-font"]}
          >
            <div className="cf-timer-inner cf-timer-inline">
              {[undefined, "d/h/m/s", "d/h/m", "d/h", "d"].includes(
                element.options["timer-units"]
              ) && (
                <div
                  className="cf-timer-unit cf-timer-unit-inline"
                  data-unit-type="days"
                >
                  <span className="cf-timer-unit-number">0</span>{" "}
                  <span className="cf-timer-unit-text">
                    {element.options["timer-days-translation"]
                      ? element.options["timer-days-translation"]
                      : "Days"}
                  </span>
                </div>
              )}
              {[undefined, "d/h/m/s", "d/h/m", "d/h"].includes(
                element.options["timer-units"]
              ) && (
                <div
                  className="cf-timer-unit cf-timer-unit-inline"
                  data-unit-type="hours"
                >
                  {" "}
                  <span className="cf-timer-unit-number">0</span>{" "}
                  <span className="cf-timer-unit-text">
                    {element.options["timer-hours-translation"]
                      ? element.options["timer-hours-translation"]
                      : "hours"}
                  </span>
                </div>
              )}
              {[undefined, "d/h/m/s", "d/h/m"].includes(
                element.options["timer-units"]
              ) && (
                <div
                  className="cf-timer-unit cf-timer-unit-inline"
                  data-unit-type="minutes"
                >
                  {" "}
                  <span className="cf-timer-unit-number">0</span>{" "}
                  <span className="cf-timer-unit-text">
                    {element.options["timer-minutes-translation"]
                      ? element.options["timer-minutes-translation"]
                      : "minutes"}
                  </span>
                </div>
              )}
              {[undefined, "d/h/m/s"].includes(
                element.options["timer-units"]
              ) && (
                <div
                  className="cf-timer-unit cf-timer-unit-inline"
                  data-unit-type="seconds"
                >
                  {" "}
                  <span className="cf-timer-unit-number">0</span>{" "}
                  <span className="cf-timer-unit-text">
                    {element.options["timer-seconds-translation"]
                      ? element.options["timer-seconds-translation"]
                      : "seconds"}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        {element.options["timer-end-action"] == "message" && (
          <div
            className="cf-timer-message cf-merge-tag-allowed"
            data-font={element.options["timer-message-font"]}
            dangerouslySetInnerHTML={{
              __html: element.html,
            }}
          ></div>
        )}
      </div>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device, cta, element) => {
    let options = { ...element.options };

    let padding;
    if (options["timer-padding"]) {
      const paddingValue = parseInt(options["timer-padding"]);
      padding = `${paddingValue / 2}px ${paddingValue}px`;
    }

    let paddingMobile;
    if (options["timer-padding-mobile"]) {
      const paddingValueMobile = parseInt(options["timer-padding-mobile"]);
      paddingMobile = `${paddingValueMobile / 2}px ${paddingValueMobile}px`;
    }

    let borderRadius = "";
    if (options["timer-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["timer-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["timer-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["timer-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["timer-radius-" + side]) == false
              ? options["timer-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let boxShadow;

    if (options["timer-shadow"] == "None") {
      boxShadow = "none";
    }

    if (options["timer-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["timer-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["timer-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["timer-shadow-horizontal-distance"]
          ? `${element.options["timer-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["timer-shadow-vertical-distance"]
          ? `${element.options["timer-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["timer-shadow-blur-radius"]
          ? `${element.options["timer-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["timer-shadow-spread-radius"]
          ? `${element.options["timer-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["timer-shadow-color"]
          ? element.options["timer-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-card .cf-timer-unit-number {
  ${padding ? 'padding: ' + padding + ' !important;' : ''}
  ${options["timer-number-size"] ? 'font-size: ' + options["timer-number-size"] + 'px !important;' : ''}
  ${options["timer-number-font"] ? 'font-family: ' + options["timer-number-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['timer-number-font-weight'] ? 'font-weight: ' + options['timer-number-font-weight'] + ' !important;' : ''}
  ${options["timer-background-color"] ? 'background: ' + options["timer-background-color"] + ' !important;' : ''}
  ${options["timer-number-color"] ? 'color: ' + options["timer-number-color"] + ' !important;' : ''}
  ${options["timer-border-style"] ? 'border-style: ' + options["timer-border-style"] + '!important;' : ''}
  ${_.isNil(options["timer-border-width"]) == false ? 'border-width: ' + options["timer-border-width"] + 'px !important;' : ''}
  ${_.isNil(options["timer-border-width-left"]) == false ? 'border-left-width: ' + options["timer-border-width-left"] + 'px !important;' : ''}
  ${_.isNil(options["timer-border-width-right"]) == false ? 'border-right-width: ' + options["timer-border-width-right"] + 'px !important;' : ''}
  ${_.isNil(options["timer-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["timer-border-width-bottom"] + 'px !important;' : ''}
  ${_.isNil(options["timer-border-width-top"]) == false ? 'border-top-width: ' + options["timer-border-width-top"] + 'px !important;' : ''}
  ${options["timer-border-color"] ? 'border-color: ' + options["timer-border-color"] + ' !important;' : ''}
  ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-card .cf-timer-unit-text {
  ${options["timer-unit-size"] ? 'font-size: ' + options["timer-unit-size"] + 'px !important;' : ''}
  ${options["timer-unit-font"] ? 'font-family: ' + options["timer-unit-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['timer-unit-font-weight'] ? 'font-weight: ' + options['timer-unit-font-weight'] + ' !important;' : ''}
  ${options["timer-unit-color"] ? 'color: ' + options["timer-unit-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-inline .cf-timer-unit-number {
  ${options["timer-number-size"] ? 'font-size: ' + options["timer-number-size"] + 'px !important;' : ''}
  ${options["timer-number-font"] ? 'font-family: ' + options["timer-number-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['timer-number-font-weight'] ? 'font-weight: ' + options['timer-number-font-weight'] + ' !important;' : ''}
  ${options["timer-number-color"] ? 'color: ' + options["timer-number-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-inline .cf-timer-unit-text {
  ${options["timer-unit-size"] ? 'font-size: ' + options["timer-unit-size"] + 'px !important;' : ''}
  ${options["timer-unit-font"] ? 'font-family: ' + options["timer-unit-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['timer-unit-font-weight'] ? 'font-weight: ' + options['timer-unit-font-weight'] + ' !important;' : ''}
  ${options["timer-unit-color"] ? 'color: ' + options["timer-unit-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message h1, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message h2, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message h3, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message h4, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message h5, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message p, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message span {
  ${options["timer-message-size"] ? 'font-size: ' + options["timer-message-size"] + 'px !important;' : ''}
  ${options["timer-message-font"] ? 'font-family: ' + options["timer-message-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['timer-message-font-weight'] ? 'font-weight: ' + options['timer-message-font-weight'] + ' !important;' : ''}
  ${options["timer-message-color"] ? 'color: ' + options["timer-message-color"] + ' !important;' : ''}
}`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-card .cf-timer-unit-number, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-inline .cf-timer-unit-number {
  ${options["timer-number-size-mobile"] ? 'font-size: ' + options["timer-number-size-mobile"] + 'px !important;' : ''}
  ${paddingMobile ? 'padding: ' + paddingMobile + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-card .cf-timer-unit-text, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-inline .cf-timer-unit-text {
  ${options["timer-unit-size-mobile"] ? 'font-size: ' + options["timer-unit-size-mobile"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-timer-message {
  ${options["timer-message-size-mobile"] ? 'font-size: ' + options["timer-message-size-mobile"] + 'px !important;' : ''}
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementTimer;
