import React from "react";
import PropTypes from "prop-types";

const FieldOptionChoice = (props) => {
  let field_option = props.field_option;
  let field = props.field;
  let important = props.builder !== true ? " !important" : "";
  let element = props.element;
  const classes = style.classes(element, props.device);

  return (
    <React.Fragment>
      <div
        data-id={field_option.id}
        data-field-option-id={field_option.id}
        data-position={field_option.position}
        data-value={
          field_option.value
            ? field_option.value
            : field_option.label
            ? field_option.label
            : "Option " + field_option.position
        }
        className={classes}
      >
        <div
          className={
            "cf-row cf-outer-center cf-survey-choice-image-container cf-survey-choice-image-size-" +
            (element.options["options-image-width"]
              ? element.options["options-image-width"]
              : "200")
          }
          style={{
            margin: "10px",
            display:
              field_option.image && field_option.image !== "none"
                ? "block" + important
                : undefined,
          }}
        >
          {field_option.image && field_option.image !== "none" && (
            <img src={field_option.image}></img>
          )}
        </div>
        <div
          className="cf-col-xs-12 cf-survey-choice-label"
          style={{
            textAlign: "inherit" + important,
          }}
        >
          <p
            style={{
              fontFamily: element.options["field-font"]
                ? element.options["field-font"] + important
                : undefined,
            }}
            dangerouslySetInnerHTML={{
              __html: field_option.label
                ? field_option.label
                : field_option.value &&
                  (!field_option.image || field_option.image == "none")
                ? field_option.value
                : !field_option.image || field_option.image == "none"
                ? "Option " + field_option.position
                : "",
            }}
          ></p>
        </div>
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, device) => {
    let options = element.options;
    let classes = "cf-survey-choice";

    let optionsWidthClass = "";
    if (options["options-width"] && options["options-width"] !== "custom") {
      optionsWidthClass = options["options-width"];
    }

    if (device == "mobile") {
      if (options["options-width-mobile"] && options["options-width-mobile"] !== "custom") {
        optionsWidthClass = options["options-width-mobile"];
      }
    }
    classes += " " + optionsWidthClass;

    classes += " " + (options["button-radius"] ? options["button-radius"] : "");

    classes += " " + (options["button-size"] ? options["button-size"] : "");

    return classes;
  },
};

export default FieldOptionChoice;
