import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsTabsDesign = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Tabs`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][tabs-max-width-style]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["tabs-max-width-style"]
              ? element.options["tabs-max-width-style"]
              : "none"
          }
          options={[
            { value: "none", text: "Theme default" },
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="desktop"
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][tabs-max-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Container Maximum Width"}
          value={
            element.options["tabs-max-width-value"]
              ? element.options["tabs-max-width-value"]
              : undefined
          }
          placeholder={300}
          condition={element.options["tabs-max-width-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][tabs-max-width-style-mobile]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["tabs-max-width-style-mobile"]
              ? element.options["tabs-max-width-style-mobile"]
              : "none"
          }
          options={[
            { value: "none", text: "Theme default" },
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="mobile"
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][tabs-max-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Container Maximum Width"}
          value={
            element.options["tabs-max-width-value-mobile"]
              ? element.options["tabs-max-width-value-mobile"]
              : undefined
          }
          placeholder={300}
          condition={element.options["tabs-max-width-style-mobile"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Layout"
          setting_name="[options][tabs-layout]"
          setting_type="layout"
          object_type="elements"
          value={
            element.options["tabs-layout"]
              ? element.options["tabs-layout"]
              : variant.options["tabs-layout"]
              ? variant.options["tabs-layout"]
              : undefined
          }
          placeholder={variant.options["tabs-layout"]
          ? variant.options["tabs-layout"]
          : undefined}
          valueMappings={{
            responsive: "horizontal",
            stacked: "vertical",
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Alignment"
          setting_name="[options][tabs-alignment]"
          setting_type="dropdown"
          object_type="elements"
          value={
            element.options["tabs-alignment"]
              ? element.options["tabs-alignment"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "start", text: "Together (left)" },
            { value: "center", text: "Together (center)" },
            { value: "end", text: "Together (right)" },
            { value: "space-between", text: "Spaced (fill)" },
            { value: "space-evenly", text: "Spaced (even)" },
          ]}
          condition={element.options["tabs-layout"] == "horizontal"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Width"
          setting_name="[options][tabs-width]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-width"]
              ? element.options["tabs-width"]
              : undefined
          }
          placeholder="0px"
          min={0}
          max={700}
          range_step={1}
          allow_empty={true}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Width"
          setting_name="[options][tabs-width-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-width-mobile"]
              ? element.options["tabs-width-mobile"]
              : undefined
          }
          placeholder={element.options["tabs-width"] || "0px"}
          min={0}
          max={700}
          range_step={1}
          allow_empty={true}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Border Style"
          setting_name="[options][tabs-border-style]"
          setting_type="border_style"
          object_type="elements"
          value={
            element.options["tabs-border-style"]
              ? element.options["tabs-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Border Color"
          setting_name="[options][tabs-border-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["tabs-border-color"]
              ? element.options["tabs-border-color"]
              : undefined
          }
          placeholder={
            variant.options["tabs-border-color"]
              ? variant.options["tabs-border-color"]
              : "#000"
          }
          condition={["dotted", "dashed", "solid"].includes(
            element.options["tabs-border-style"]
          )}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Border Hover"
            setting_name="[options][tabs-border-color-hover]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["tabs-border-color-hover"]
                ? element.options["tabs-border-color-hover"]
                : undefined
            }
            placeholder={
              variant.options["tabs-border-color-hover"]
                ? variant.options["tabs-border-color-hover"]
                : "#000"
            }
            col="6"
            classNames="some-padding-right"
            condition={["dotted", "dashed", "solid"].includes(
              element.options["tabs-border-style"]
            )}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Border Active"
            setting_name="[options][tabs-border-color-active]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["tabs-border-color-active"]
                ? element.options["tabs-border-color-active"]
                : undefined
            }
            placeholder={
              variant.options["tabs-border-color-active"]
                ? variant.options["tabs-border-color-active"]
                : "#000"
            }
            col="6"
            classNames="some-padding-left"
            condition={["dotted", "dashed", "solid"].includes(
              element.options["tabs-border-style"]
            )}
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        {element.options["tabs-border-style"] &&
          element.options["tabs-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Tabs Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][tabs-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        object_id={element.id}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        value={
                          _.isNil(
                            element.options[`tabs-border-width-${side}`]
                          ) == false
                            ? element.options[`tabs-border-width-${side}`]
                            : element.options["tabs-border-width"]
                            ? element.options["tabs-border-width"].replace(
                                "px",
                                ""
                              )
                            : undefined
                        }
                        placeholder={
                          variant.options[`tabs-border-width-${side}`]
                            ? variant.options[`tabs-border-width-${side}`]
                            : "0px"
                        }
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Corner Radius"
          setting_name="[options][tabs-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="elements"
          value={
            element.options["tabs-radius"]
              ? element.options["tabs-radius"]
              : undefined
          }
          show_reset={true}
        />

        {element.options["tabs-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][tabs-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={element}
                    object_id={element.id}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="elements"
                    value={
                      _.isNil(element.options[`tabs-radius-${side}`]) == false
                        ? element.options[`tabs-radius-${side}`]
                        : undefined
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder={
                      variant.options[`tabs-radius-${side}`]
                        ? variant.options[`tabs-radius-${side}`]
                        : "0px"
                    }
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Padding Style"
          setting_name="[options][tabs-padding-style]"
          setting_type="dropdown"
          object_type="elements"
          value={
            element.options["tabs-padding-style"]
              ? element.options["tabs-padding-style"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "custom", text: "Custom" },
          ]}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Padding Value"
          setting_name="[options][tabs-padding-value]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-padding-value"]
              ? element.options["tabs-padding-value"]
              : undefined
          }
          placeholder={
            variant.options["tabs-padding-value"]
              ? variant.options["tabs-padding-value"]
              : 0
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          condition={element.options["tabs-padding-style"] == "custom"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Padding Style"
          setting_name="[options][tabs-padding-style-mobile]"
          setting_type="dropdown"
          object_type="elements"
          value={
            element.options["tabs-padding-style-mobile"]
              ? element.options["tabs-padding-style-mobile"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "custom", text: "Custom" },
          ]}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Padding Value"
          setting_name="[options][tabs-padding-value-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-padding-value-mobile"]
              ? element.options["tabs-padding-value-mobile"]
              : undefined
          }
          placeholder={
            variant.options["tabs-padding-value-mobile"]
              ? variant.options["tabs-padding-value-mobile"]
              : 0
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          condition={element.options["tabs-padding-style-mobile"] == "custom"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Tabs Background Color"
          setting_name="[options][tabs-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["tabs-background-color"]
              ? element.options["tabs-background-color"]
              : undefined
          }
          placeholder={
            variant.options["tabs-background-color"]
              ? variant.options["tabs-background-color"]
              : "#F5F5F5"
          }
          show_reset={true}
          gradient={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Backgr. Hover"
            setting_name="[options][tabs-background-color-hover]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["tabs-background-color-hover"]
                ? element.options["tabs-background-color-hover"]
                : undefined
            }
            placeholder={
              variant.options["tabs-background-color-hover"]
                ? variant.options["tabs-background-color-hover"]
                : ""
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Backgr. Active"
            setting_name="[options][tabs-background-color-active]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["tabs-background-color-active"]
                ? element.options["tabs-background-color-active"]
                : undefined
            }
            placeholder={
              variant.options["tabs-background-color-active"]
                ? variant.options["tabs-background-color-active"]
                : ""
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][tabs-font-family]"
          label={"Title Font Family"}
          setting_type="select"
          object_type="elements"
          value={
            element.options["tabs-font-family"]
              ? element.options["tabs-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][tabs-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          value={
            element.options["tabs-font-weight"]
              ? element.options["tabs-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Font Size"
          setting_name="[options][tabs-font-size]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-font-size"]
              ? element.options["tabs-font-size"]
              : undefined
          }
          placeholder={
            variant.options["tabs-font-size"]
              ? variant.options["tabs-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Font Size"
          setting_name="[options][tabs-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-font-size-mobile"]
              ? element.options["tabs-font-size-mobile"]
              : undefined
          }
          placeholder={
            element.options["tabs-font-size"]
              ? element.options["tabs-font-size"]
              : variant.options["tabs-font-size-mobile"]
              ? variant.options["tabs-font-size-mobile"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Title Color"
          setting_name="[options][tabs-font-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["tabs-font-color"]
              ? element.options["tabs-font-color"]
              : undefined
          }
          placeholder={
            variant.options["tabs-font-color"]
              ? variant.options["tabs-font-color"]
              : "#000"
          }
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Title Hover"
            setting_name="[options][tabs-font-color-hover]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["tabs-font-color-hover"]
                ? element.options["tabs-font-color-hover"]
                : undefined
            }
            placeholder={
              variant.options["tabs-font-color-hover"]
                ? variant.options["tabs-font-color-hover"]
                : "#1e90ff"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Title Active"
            setting_name="[options][tabs-font-color-active]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["tabs-font-color-active"]
                ? element.options["tabs-font-color-active"]
                : undefined
            }
            placeholder={
              variant.options["tabs-font-color-active"]
                ? variant.options["tabs-font-color-active"]
                : "#1e90ff"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Content Spacing"
          setting_name="[options][tabs-content-spacing-horizontal]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-content-spacing-horizontal"]
              ? element.options["tabs-content-spacing-horizontal"]
              : undefined
          }
          placeholder="0px"
          min={0}
          max={100}
          range_step={1}
          allow_empty={true}
          condition={element.options["tabs-layout"] == "horizontal"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Content Spacing"
          setting_name="[options][tabs-content-spacing-vertical]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-content-spacing-vertical"]
              ? element.options["tabs-content-spacing-vertical"]
              : undefined
          }
          placeholder="0px"
          min={0}
          max={100}
          range_step={1}
          allow_empty={true}
          condition={element.options["tabs-layout"] == "vertical"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Content Spacing"
          setting_name="[options][tabs-content-spacing-horizontal-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-content-spacing-horizontal-mobile"]
              ? element.options["tabs-content-spacing-horizontal-mobile"]
              : undefined
          }
          placeholder="0px"
          min={0}
          max={100}
          range_step={1}
          allow_empty={true}
          condition={element.options["tabs-layout"] == "horizontal"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Tabs Content Spacing"
          setting_name="[options][tabs-content-spacing-vertical-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["tabs-content-spacing-vertical-mobile"]
              ? element.options["tabs-content-spacing-vertical-mobile"]
              : undefined
          }
          placeholder="0px"
          min={0}
          max={100}
          range_step={1}
          allow_empty={true}
          condition={element.options["tabs-layout"] == "vertical"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Content Background Color"
          setting_name="[options][tabs-content-background-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["tabs-content-background-color"]
              ? element.options["tabs-content-background-color"]
              : undefined
          }
          placeholder={
            variant.options["tabs-content-background-color"]
              ? variant.options["tabs-content-background-color"]
              : ""
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Content Border Style"
          setting_name="[options][tabs-content-border-style]"
          setting_type="border_style"
          object_type="elements"
          value={
            element.options["tabs-content-border-style"]
              ? element.options["tabs-content-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Content Border Color"
          setting_name="[options][tabs-content-border-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["tabs-content-border-color"]
              ? element.options["tabs-content-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={["dotted", "dashed", "solid"].includes(
            element.options["tabs-content-border-style"]
          )}
          show_reset={true}
        />

        {element.options["tabs-content-border-style"] &&
          element.options["tabs-content-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Content Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][tabs-content-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        object_id={element.id}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        value={
                          _.isNil(
                            element.options[`tabs-content-border-width-${side}`]
                          ) == false
                            ? element.options[
                                `tabs-content-border-width-${side}`
                              ]
                            : element.options["tabs-content-border-width"]
                            ? element.options[
                                "tabs-content-border-width"
                              ].replace("px", "")
                            : variant.options[
                                `tabs-content-border-width-${side}`
                              ]
                        }
                        placeholder={
                          variant.options[
                            `tabs-content-border-width-${side}`
                          ] || "0px"
                        }
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Content Corner Radius"
          setting_name="[options][tabs-content-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="elements"
          value={
            element.options["tabs-content-radius"]
              ? element.options["tabs-content-radius"]
              : undefined
          }
          show_reset={true}
        />

        {element.options["tabs-content-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][tabs-content-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={element}
                    object_id={element.id}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="elements"
                    value={
                      _.isNil(element.options[`tabs-content-radius-${side}`]) ==
                      false
                        ? element.options[`tabs-content-radius-${side}`]
                        : undefined
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder={
                      variant.options[`tabs-content-radius-${side}`]
                        ? variant.options[`tabs-content-radius-${side}`]
                        : "0px"
                    }
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsTabsDesign;
