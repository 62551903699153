import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import ElementSettingsButtonDesign from "./ElementSettingsButtonDesign";

const ElementSettingsFormDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Fields`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Field Layout"
          setting_name="[options][form-type]"
          setting_type="layout"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["form-type"]
              ? element.options["form-type"]
              : "vertical"
          }
          valueMappings={{
            responsive: 'horizontal',
            stacked: 'vertical',
          }}
          condition={element.element_type == "form"}
          device="desktop"
        />
        
        <VariantBuilderSetting
          object={element}
          label="Field Layout"
          setting_name="[options][form-type-mobile]"
          setting_type="layout"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["form-type-mobile"]
              ? element.options["form-type-mobile"]
              : element.options["form-type"]
              ? element.options["form-type"]
              : "vertical"
          }
          valueMappings={{
            responsive: 'horizontal',
            stacked: 'vertical',
          }}
          condition={element.element_type == "form"}
          device="mobile"
        />

        <VariantBuilderSetting
          object={element}
          label="Field Width"
          setting_name="[options][form-width]"
          setting_type="box_width"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["form-width"]
              ? element.options["form-width"]
              : "cf-form-mid"
          }
          valueMappings={{
            auto: 'cf-form-small',
            regular: 'cf-form-mid',
            full: 'cf-form-large',
            custom: 'custom',
          }}
          condition={element.element_type == "form"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={element}
          label="Field Width"
          setting_name="[options][form-width-mobile]"
          setting_type="box_width"
          valueMappings={{
            auto: 'cf-form-small',
            regular: 'cf-form-mid',
            full: 'cf-form-large',
            custom: 'custom',
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["form-width-mobile"]
              ? element.options["form-width-mobile"]
              : element.options["form-width"]
                ? element.options["form-width"]
                : undefined
          }
          condition={element.element_type == "form"}
          device="mobile"
          show_reset={element.options["form-width-mobile"] ? true : false}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][form-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Form Width"}
          value={
            element.options["form-width-value"]
              ? element.options["form-width-value"]
              : "0"
          }
          condition={element.options["form-width"] == "custom"}
          min={0}
          max={2000}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][form-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Form Width"}
          value={
            element.options["form-width-value-mobile"]
              ? element.options["form-width-value-mobile"]
              : undefined
          }
          placeholder={
            element.options["form-width-value"] || 0
          }
          condition={element.options["form-width-mobile"] == "custom"}
          min={0}
          max={2000}
          range_step={"1"}
          show_reset={true}
          device="mobile"
        />

        <VariantBuilderSetting
          object={element}
          label="Field Size"
          setting_name="[options][field-size]"
          setting_type="box_height"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-size"]
              ? element.options["field-size"]
              : "cf-field-regular"
          }
          valueMappings={{
            regular: 'cf-field-regular',
            large: 'cf-field-large',
            custom: 'custom',
          }}
          condition={element.element_type == "form"}
        />
        <VariantBuilderSetting
          object={section}
          setting_name="[options][field-size-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Field Padding value"}
          value={
            element.options["field-size-value"]
              ? element.options["field-size-value"]
              : undefined
          }
          condition={element.options["field-size"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={element}
          label={element.element_type == "form" ? "Field Font" : "Option Font"}
          setting_name="[options][field-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font"] ? element.options["field-font"] : undefined
          }
          placeholder={
            variant.options["field-font"]
              ? variant.options["field-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={["form"].includes(element.element_type)}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][field-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font-weight"]
              ? element.options["field-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          condition={["form"].includes(element.element_type)}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Field Font size"}
          setting_name="[options][field-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font-size"]
              ? element.options["field-font-size"]
              : undefined
          }
          placeholder={
            variant.options["field-font-size"]
              ? variant.options["field-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Field Font size"}
          setting_name="[options][field-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font-size-mobile"]
              ? element.options["field-font-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["field-font-size-mobile"]
              ? variant.options["field-font-size-mobile"]
              : variant.options["field-font-size"]) || 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Field Text Color"}
          setting_name="[options][field-text-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-text-color"]
              ? element.options["field-text-color"]
              : undefined
          }
          placeholder={
            variant.options["field-text-color"]
              ? variant.options["field-text-color"]
              : "#000"
          }
          condition={element.element_type == "form"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Label Font Family"
          setting_name="[options][field-label-font-family]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-label-font-family"]
              ? element.options["field-label-font-family"]
              : undefined
          }
          placeholder={
            variant.options["field-label-font-family"] == "font-default"
              ? undefined
              : variant.options["field-label-font-family"]
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          condition={element.element_type == "form"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][field-label-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-label-font-weight"]
              ? element.options["field-label-font-weight"]
              : undefined
          }
          col="4"
          condition={element.element_type == "form"}
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Label Font Size"
          setting_name="[options][field-label-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-label-font-size"]
              ? element.options["field-label-font-size"]
              : undefined
          }
          placeholder={
            (element.options["field-label-font-size"]
              ? element.options["field-label-font-size"]
              : variant.options["field-label-font-size"]) || 15
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          condition={element.element_type == "form"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Label Font Size"
          setting_name="[options][field-label-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-label-font-size-mobile"]
              ? element.options["field-label-font-size-mobile"]
              : undefined
          }
          placeholder={
            (element.options["field-label-font-size-mobile"]
              ? element.options["field-label-font-size-mobile"]
              : variant.options["field-label-font-size"]) || 15
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          condition={element.element_type == "form"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Label Color"
          setting_name="[options][label-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["label-color"]
              ? element.options["label-color"]
              : undefined
          }
          placeholder={
            variant.options["label-color"]
              ? variant.options["label-color"]
              : "#232323"
          }
          condition={element.element_type == "form"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Field Background Color"}
          setting_name="[options][field-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-background-color"]
              ? element.options["field-background-color"]
              : undefined
          }
          placeholder={
            variant.options["field-background-color"]
              ? variant.options["field-background-color"]
              : "#fff"
          }
          condition={element.element_type == "form"}
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Rounding"
          setting_name="[options][field-rounded]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-field-not-rounded',
            some: 'cf-field-slightly-rounded',
            full: 'cf-field-fully-rounded',
            custom: 'custom',
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-rounded"]
              ? element.options["field-rounded"]
              : undefined
          }
          condition={element.element_type == "form"}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][field-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`field-radius-${side}`]) == false
                      ? element.options[`field-radius-${side}`]
                      : "3"
                  }
                  condition={
                    ["form"].includes(element.element_type) &&
                    element.options["field-rounded"] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={element}
          label="Field Border Style"
          setting_name="[options][field-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-border-style"]
              ? element.options["field-border-style"]
              : undefined
          }
          condition={["form"].includes(element.element_type)}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field border color"
          setting_name="[options][field-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-border-color"]
              ? element.options["field-border-color"]
              : undefined
          }
          placeholder={
            variant.options["field-border-color"]
              ? variant.options["field-border-color"]
              : "#DBDBDB"
          }
          condition={
            ["form"].includes(element.element_type) &&
            element.options["field-border-style"] !== "none"
          }
          show_reset={true}
        />

        {["form"].includes(element.element_type) &&
          element.options["field-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Field Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][field-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[`field-border-width-${side}`]
                          ) == false
                            ? element.options[`field-border-width-${side}`]
                            : "1"
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Range field handle"
          setting_name="[options][field-handle-shape]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-handle-shape"]
              ? element.options["field-handle-shape"]
              : undefined
          }
          condition={element.element_type == "form"}
          options={[
            { value: "", text: "Default" },
            { value: "rectangle", text: "Rectangle" },
            { value: "square", text: "Square" },
            { value: "circle", text: "Circle" },
          ]}
        />

        <VariantBuilderSetting
          object={element}
          label="Range Handle Color"
          setting_name="[options][field-handle-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-handle-color"]
              ? element.options["field-handle-color"]
              : undefined
          }
          placeholder={
            variant.options["field-handle-color"]
              ? variant.options["field-handle-color"]
              : "#fff"
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Range Handle Border"
          setting_name="[options][field-handle-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-handle-border-color"]
              ? element.options["field-handle-border-color"]
              : undefined
          }
          placeholder={
            variant.options["field-handle-border-color"]
              ? variant.options["field-handle-border-color"]
              : "#D9D9D9"
          }
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>

      <ElementSettingsButtonDesign {...props} />
    </React.Fragment>
  );
};

export default ElementSettingsFormDesign;
