import React from "react";
import PropTypes from "prop-types";
import FieldOption from "../field_options/FieldOption";
import FieldSurveyNext from "../fields/FieldSurveyNext";

const FieldSurvey = (props) => {
  let element = props.element;
  let field = props.field;
  let important = props.builder !== true ? " !important" : "";
  const classes = style.classes(element);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    field,
    props.variant
  );

  let field_options = Object.values(field.field_options).filter(
    (field_option) =>
      field_option.field_id == field.id && field_option.toBeDeleted !== true
  );

  let sorted_field_options = Object.values(field_options).sort(function (a, b) {
    return a.position - b.position;
  });

  // prettier-ignore
  const isVisualChoiceComponent = () => {
    // if a legacy survey uses the new range slider to update image widht it would lead to the image not showing bc the
    // old classes only supported those fixed values. So if the image is different automatically renders the news component.
    let old_component_supported_widths = ["120", "200", "300", "400", "500", "600", "700",];
    if (element.options["options-image-width"] && old_component_supported_widths.includes(element.options["options-image-width"]) == false) {
      return true;
    }

    let hasImageVisualType = field_options.filter((f) => f.visual_type === "image" && f.image !== "none").length > 0
    let hasLetterVisualType = field_options.filter((f) => f.visual_type === "letter" && f.letter).length > 0
    let hasIconVisualType = field_options.filter((f) => f.visual_type === "icon" && f.icon).length > 0
    let hasEmojiVisualType = field_options.filter((f) => f.visual_type === "emoji" && f.emoji).length > 0
    let hasColorVisualType = field_options.filter((f) => f.visual_type === "color").length > 0
    let hasVisualTypeDefined = element.options["visual-type-placement"] != undefined || props.variant.options["visual-type-placement"] != undefined;

    return (
      hasVisualTypeDefined ||
      hasEmojiVisualType ||
      hasIconVisualType ||
      hasLetterVisualType ||
      hasColorVisualType ||
      hasImageVisualType
    );
  };

  return (
    <React.Fragment>
      <div
        className={classes}
        id={"field_" + field.id}
        data-id={field.id}
        data-field-id={field.id}
        data-field={field.slug}
        data-multi-select={field.multi_select}
        data-confirmation-button={field.options["confirmation_button"]}
        data-pos={field.position ? field.position : ""}
        data-visible={field.field_type !== "hidden" ? "true" : ""}
      >
        {sorted_field_options.map((field_option) => {
          return (
            <FieldOption
              key={"field-option-" + field_option.id}
              field_option={field_option}
              renderVisualComponent={isVisualChoiceComponent()}
              {...props}
            />
          );
        })}
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
      <FieldSurveyNext {...props} />
    </React.Fragment>
  );
};

const style = {
  classes: (element) => {
    let options = element.options;
    let classes = "cf-survey-field ";

    let alignmentClass = "cf-outer-center";
    if (
      ["custom", "cf-survey-responsive"].includes(options["options-width"]) &&
      options["options-display"] == "table" &&
      options["cf-alignment"]
    ) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentClass = "cf-outer-left";
      } else if (options["cf-alignment"].indexOf("center") > -1) {
        alignmentClass = "cf-outer-center";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentClass = "cf-outer-right";
      }
    }
    classes += alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element, field, variant) => {
    let options = style.backport(element);

    let borderRadius;
    if (options["button-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["button-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["button-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["button-radius"] == "custom") {
      borderRadius = "";
      borderRadius += (options["button-radius-top-left"] || 0) + `px `;
      borderRadius += (options["button-radius-top-right"] || 0) + `px `;
      borderRadius += (options["button-radius-bottom-right"] || 0) + `px `;
      borderRadius += (options["button-radius-bottom-left"] || 0) + `px`;
    }

    let fontFamily = options["field-font"] || undefined;

    let fontWeight;
    if (
      options["field-font-weight"] &&
      options["field-font-weight"] !== "theme"
    ) {
      fontWeight = options["field-font-weight"];
    } else if (
      options["field-font-weight"] == "theme" &&
      variant.options["field-font-weight"]
    ) {
      fontWeight = variant.options["field-font-weight"];
    }

    let fontSize;
    if (options["field-font-size"]) {
      fontSize = options["field-font-size"];
    }

    let boxShadow;
    let boxShadowHover;

    if (options["button-shadow"] == "None") {
      boxShadow = "none";
    }

    if (options["button-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["button-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["button-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["button-shadow-horizontal-distance"]
          ? `${element.options["button-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["button-shadow-vertical-distance"]
          ? `${element.options["button-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["button-shadow-blur-radius"]
          ? `${element.options["button-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["button-shadow-spread-radius"]
          ? `${element.options["button-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadowHover =
        boxShadow +
        (element.options["button-hover-shadow-color"]
          ? element.options["button-hover-shadow-color"]
          : element.options["button-shadow-color"]
          ? element.options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      boxShadow +=
        (element.options["button-shadow-color"]
          ? element.options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      boxShadow +=
        element.options["button-shadow-inset"] == "true" ? "inset" : "";
    }

    let optionsDisplay;
    if (
      ["custom", "cf-survey-responsive"].includes(options["options-width"]) &&
      options["options-display"] == "table"
    ) {
      optionsDisplay =
        "display: table !important; margin-left: inherit !important; margin-right: inherit !important;";
    }

    let optionsMarginVertical;
    if (_.isNil(options["options-margin-bottom"]) == false) {
      optionsMarginVertical = options["options-margin-bottom"];
    } else if (
      ["cf-survey-regular", "cf-survey-full"].includes(
        options["options-width"]
      ) ||
      (options["options-width"] == "custom" &&
        options["options-display"] == "table")
    ) {
      optionsMarginVertical = 10;
    }

    let optionsMarginHorizontal;
    if (
      _.isNil(options["options-margin-left"]) == false &&
      options["options-width"] !== "cf-survey-full" &&
      options["options-display"] !== "table"
    ) {
      optionsMarginHorizontal = options["options-margin-left"];
    }

    let nextButtonBorderRadius = "";
    if (options["next-button-radius"] == "cf-not-rounded") {
      nextButtonBorderRadius = "0px";
    }
    if (options["next-button-radius"] == "cf-slightly-rounded") {
      nextButtonBorderRadius = "4px";
    }
    if (options["next-button-radius"] == "cf-fully-rounded") {
      nextButtonBorderRadius = "100px";
    }
    if (options["next-button-radius"] == "custom") {
      nextButtonBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          nextButtonBorderRadius += `${
            _.isNil(options["next-button-radius-" + side]) == false
              ? options["next-button-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let nextButtonBoxShadow;
    let nextButtonBoxShadowHover;

    if (options["next-button-shadow"] == "None") {
      nextButtonBoxShadow = "none";
    }

    if (options["next-button-shadow"] == "soft") {
      nextButtonBoxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["next-button-shadow"] == "hard") {
      nextButtonBoxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["next-button-shadow"] == "custom") {
      nextButtonBoxShadow = "";
      nextButtonBoxShadow +=
        (variant.options["next-button-shadow-horizontal-distance"]
          ? `${variant.options["next-button-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      nextButtonBoxShadow +=
        (variant.options["next-button-shadow-vertical-distance"]
          ? `${variant.options["next-button-shadow-vertical-distance"]}px`
          : "0px") + " ";

      nextButtonBoxShadow +=
        (variant.options["next-button-shadow-blur-radius"]
          ? `${variant.options["next-button-shadow-blur-radius"]}px`
          : "0px") + " ";

      nextButtonBoxShadow +=
        (variant.options["next-button-shadow-spread-radius"]
          ? `${variant.options["next-button-shadow-spread-radius"]}px`
          : "0px") + " ";

      nextButtonBoxShadow +=
        (variant.options["next-button-shadow-color"]
          ? variant.options["next-button-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      nextButtonBoxShadowHover =
        nextButtonBoxShadow +
        (variant.options["next-button-hover-shadow-color"]
          ? variant.options["next-button-hover-shadow-color"]
          : variant.options["next-button-shadow-color"]
          ? variant.options["next-button-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      nextButtonBoxShadow +=
        variant.options["next-button-shadow-inset"] == "true" ? "inset" : "";
      nextButtonBoxShadowHover +=
        variant.options["next-button-shadow-inset"] == "true" ? "inset" : "";
    }

    let nextButtonPadding = options["next-button-padding"];
    let nextButtonPaddingValue = "";
    if (nextButtonPadding == "cf-button-small") {
      nextButtonPaddingValue = "padding: 8px 15px !important;";
    } else if (nextButtonPadding == "cf-button-regular") {
      nextButtonPaddingValue = "padding: 10px 20px !important;";
    } else if (nextButtonPadding == "cf-button-large") {
      nextButtonPaddingValue = "padding: 13px 20px !important;";
    }

    let nextButtonFontWeight;
    if (
      options["next-button-font-weight"] &&
      options["next-button-font-weight"] !== "theme"
    ) {
      nextButtonFontWeight = options["next-button-font-weight"];
    }

    let nextButtonWidth = options["next-button-width"];
    let nextButtonWidthValue = "";

    if (nextButtonWidth == "custom" && options["next-button-width-value"]) {
      nextButtonWidthValue = options["next-button-width-value"] + "px";
    } else if (nextButtonWidth == "cf-full-width") {
      nextButtonWidthValue = "100%";
    } else if (nextButtonWidth == "cf-button-mid") {
      nextButtonWidthValue = "200px";
    } else if (nextButtonWidth == "cf-button-slim") {
      nextButtonWidthValue = "100px";
    }

    // prettier-ignore
    const desktopStyles =
`#cta_${cta.id} #field_${field.id}.cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice {
  text-align: ${element.options["options-alignment"] ? element.options["options-alignment"] : 'center'} !important;
}

#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-small,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-regular,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-large,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next {
  ${options["next-button-color"] ? 'background: ' + options["next-button-color"] + '!important;' : ''}
  ${nextButtonWidthValue ? `min-width: ${nextButtonWidthValue} !important;` : ''}
  ${nextButtonPadding == 'custom' && options["next-button-padding-value"] ? `padding: ${options["next-button-padding-value"]}px 20px !important;` : nextButtonPaddingValue}
  ${options["next-button-font-size"] ? `font-size: ${options['next-button-font-size']}px !important;` : ''}
  ${nextButtonFontWeight ? `font-weight: ${nextButtonFontWeight} !important;` : ''}
  font-family: ${options["next-button-font-family"] ? options["next-button-font-family"] : ''} !important;
  ${options["next-button-text-color"] ? 'color: ' + options["next-button-text-color"] + ' !important;' : ''}
  ${options["next-button-border-style"] ? 'border-style: ' + options["next-button-border-style"] + ' !important;' : ''}
  ${options["next-button-border-style"] !== "" && _.isNil(options["next-button-border-width"]) == false ? 'border-width: ' + options["next-button-border-width"] + 'px !important;' : 'border-width: 1px !important;'}
  ${options["next-button-border-style"] !== "" && _.isNil(options["next-button-border-width-left"]) == false ? 'border-left-width: ' + options["next-button-border-width-left"] + 'px !important;' : _.isNil(options["next-button-border-width"]) == false ? 'border-left-width: ' + options["next-button-border-width"] + 'px !important;' : ''}
  ${options["next-button-border-style"] !== "" && _.isNil(options["next-button-border-width-right"]) == false ? 'border-right-width: ' + options["next-button-border-width-right"] + 'px !important;' : _.isNil(options["next-button-border-width"]) == false ? 'border-right-width: ' + options["next-button-border-width"] + 'px !important;' : ''}
  ${options["next-button-border-style"] !== "" && _.isNil(options["next-button-border-width-top"]) == false ? 'border-top-width: ' + options["next-button-border-width-top"] + 'px !important;' : _.isNil(options["next-button-border-width"]) == false ? 'border-top-width: ' + options["next-button-border-width"] + 'px !important;' : ''}
  ${options["next-button-border-style"] !== "" && _.isNil(options["next-button-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["next-button-border-width-bottom"] + 'px !important;' : _.isNil(options["next-button-border-width"]) == false ? 'border-bottom-width: ' + options["next-button-border-width"] + 'px !important;' : ''}
  ${options["next-button-border-style"] !== "" && options["next-button-border-color"] ? 'border-color: ' + options["next-button-border-color"] + ' !important;' : options["next-button-border-color"] ? 'border-color: ' + options["next-button-border-color"] + ' !important;' : ''}
  ${nextButtonBorderRadius ? 'border-radius: ' + nextButtonBorderRadius + ' !important;' : ''}
  ${nextButtonBoxShadow ? 'box-shadow: ' + nextButtonBoxShadow + ' !important;' : ''}
}

#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-small:hover,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-regular:hover,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-large:hover,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next:hover {
  ${options["next-button–hover-color"] ? 'background: ' + options["next-button–hover-color"] + ' !important;' : options["next-button-color"] ? 'background: ' + options["next-button-color"] + ' !important;' : ''}
  ${options["next-button–hover-text-color"] ? 'color: ' + options["next-button–hover-text-color"] + ' !important;' : options["next-button-text-color"] ? 'color: ' + options["next-button-text-color"] + ' !important;' : ''}
  ${options["next-button-hover-border-color"] ? 'border-color: ' + options["next-button-hover-border-color"] + ' !important;' : options["next-button-border-color"] ? 'border-color: ' +  options["next-button-border-color"] + ' !important;' : ''}
  ${nextButtonBoxShadowHover ? 'box-shadow: ' + nextButtonBoxShadowHover + ' !important;' : ''}
}

#cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice {
  ${options["button-color"] ? 'background: ' + options["button-color"] + ' !important;' : ''}
  ${options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
  ${options["options-width"] == 'custom' && options["options-width-value"] ? `width: ${options["options-width-value"]}px !important;` : ''}
  ${options["button-size"] == 'custom' && options["button-size-value"] ? 'padding: ' + options["button-size-value"] + 'px 0px !important;' : ''}
  ${optionsDisplay ? optionsDisplay : ''}
  ${options["button-border-style"] ? 'border-style: ' + options["button-border-style"] + ' !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width"]) == false ? 'border-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-left"]) == false ? 'border-left-width: ' + options["button-border-width-left"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-left-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-right"]) == false ? 'border-right-width: ' + options["button-border-width-right"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-right-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-top"]) == false ? 'border-top-width: ' + options["button-border-width-top"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-top-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["button-border-width-bottom"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-bottom-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && options["button-border-color"] ? 'border-color: ' + options["button-border-color"] + ' !important;' : options["button-color"] ? 'border-color: ' + options["button-color"] + ' !important;' : ''}
  ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
  ${_.isNil(optionsMarginVertical) == false ? 'margin-bottom: ' + optionsMarginVertical + 'px !important;' : ''}
  ${_.isNil(optionsMarginHorizontal) == false ? 'margin-left: ' + optionsMarginHorizontal + 'px !important;' : ''}
  ${_.isNil(optionsMarginHorizontal) == false ? 'margin-right: ' + optionsMarginHorizontal + 'px !important;' : ''}
}

#cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice:hover, #cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice:active {
  ${options["button–hover-color"] ? 'background: ' + options["button–hover-color"] + ' !important;' : options["button-color"] ? 'background: ' + options["button-color"] + ' !important;' : ''}
  ${options["button–hover-text-color"] ? 'color: ' + options["button–hover-text-color"] + ' !important;' : options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
  ${options["button-border-style"] !== "" && options["button-hover-border-color"] ? 'border-color: ' + options["button-hover-border-color"] + ' !important;' : options["button-color"] ? 'border-color: ' + options["button-border-color"] + ' !important;' : ''}
  ${boxShadowHover ? 'box-shadow: ' + boxShadowHover + ' !important;' : ''}
}

#cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice p,
#cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice .cf-survey-label {
  ${fontFamily ? `font-family: ${fontFamily} !important;` : ''}
  ${fontWeight ? `font-weight: ${fontWeight} !important;` : ''}
  ${fontSize ? `font-size: ${fontSize}px !important;` : ''}
}

#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next {
  ${options["options-width"] == 'custom' && options['options-display'] == 'table' && options["options-width-value"] ? 'min-width: ' + options["options-width-value"] + 'px !important;' : ''}
}
`;

    let fontSizeMobile;
    if (options["field-font-size-mobile"]) {
      fontSizeMobile = options["field-font-size-mobile"];
    }

    let nextButtonWidthMobile = options["next-button-width-mobile"];
    let nextButtonWidthValueMobile = "";

    if (
      nextButtonWidthMobile == "custom" &&
      options["next-button-width-value-mobile"]
    ) {
      nextButtonWidthValueMobile =
        options["next-button-width-value-mobile"] + "px";
    } else if (nextButtonWidthMobile == "cf-full-width") {
      nextButtonWidthValueMobile = "100%";
    } else if (nextButtonWidthMobile == "cf-button-mid") {
      nextButtonWidthValueMobile = "200px";
    } else if (nextButtonWidthMobile == "cf-button-slim") {
      nextButtonWidthValueMobile = "100px";
    }

    let optionsCustomWidthMobile = "";
    if (options["options-width-mobile"] && options["options-width-mobile"] !== "custom") {
      optionsCustomWidthMobile = "";
    } else if (options["options-width-mobile"] == "custom" && options["options-width-value-mobile"]) {
      optionsCustomWidthMobile = `${options["options-width-value-mobile"]}px`;
    }

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice p,
#cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice .cf-survey-label {
  ${fontSizeMobile ? `font-size: ${fontSizeMobile}px !important;` : ''}
}

#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-small,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-regular,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next.cf-button-large,
#cta_${cta.id} .cf-survey-next-outer[data-field-id="${field.id}"] .cf-survey-next {
  ${options["next-button-font-size-mobile"] ? `font-size: ${options['next-button-font-size-mobile']}px !important;` : ''}
  ${nextButtonWidthValueMobile ? `min-width: ${nextButtonWidthValueMobile} !important;` : ''}
}

#cta_${cta.id} .cf-survey-field[data-field-id="${field.id}"] .cf-survey-choice {
  ${optionsCustomWidthMobile ? `width: ${optionsCustomWidthMobile} !important;` : ''}
}
`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },

  // converts old database values to current values
  backport: (element) => {
    let options = { ...element.options };

    let buttonBorderWidth = options["button-border-width"];
    if (buttonBorderWidth && buttonBorderWidth.indexOf("px") > -1) {
      options["button-border-width"] = buttonBorderWidth.replace("px", "");
    }
    return options;
  },
};

export default FieldSurvey;
