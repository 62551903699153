import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsProgressDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Progress Bar`} open={false}>
        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-outer-width]"
          setting_type="range"
          value={
            element.options["progress-outer-width"]
              ? element.options["progress-outer-width"]
              : undefined
          }
          placeholder={400}
          label="Bar container max width"
          min={100}
          max={2560}
          step={1}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-height]"
          setting_type="range"
          value={
            element.options["progress-height"]
              ? element.options["progress-height"]
              : undefined
          }
          placeholder={
            element.options["progress-format"] == "box" ? "40" : "10"
          }
          label="Bar container height"
          condition={element.options["progress-format"] !== "box"}
          min={5}
          max={200}
          step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-height-mobile]"
          setting_type="range"
          value={
            element.options["progress-height-mobile"]
              ? element.options["progress-height-mobile"]
              : undefined
          }
          placeholder={
            element.options["progress-height"]
              ? element.options["progress-height"]
              : element.options["progress-format"] == "box"
              ? "40"
              : "10"
          }
          label="Bar container height"
          condition={element.options["progress-format"] !== "box"}
          min={5}
          max={200}
          step={1}
          device={"mobile"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-inner-padding]"
          setting_type="range"
          value={
            element.options["progress-inner-padding"]
              ? element.options["progress-inner-padding"]
              : undefined
          }
          placeholder={"15"}
          label="Bar message padding"
          condition={element.options["progress-format"] == "box"}
          min={5}
          max={100}
          step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-inner-padding-mobile]"
          setting_type="range"
          value={
            element.options["progress-inner-padding-mobile"]
              ? element.options["progress-inner-padding-mobile"]
              : undefined
          }
          placeholder={
            element.options["progress-inner-padding"]
              ? element.options["progress-inner-padding"]
              : "15"
          }
          label="Bar message padding"
          condition={element.options["progress-format"] == "box"}
          min={5}
          max={100}
          step={1}
          device={"mobile"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-inner-width]"
          setting_type="range"
          value={
            element.options["progress-inner-width"]
              ? element.options["progress-inner-width"]
              : undefined
          }
          placeholder="50"
          label="Bar progress percentage"
          condition={element.options["progress-type"] == "static"}
          min={0}
          max={100}
          step={1}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Message font"
          setting_name="[options][progress-text-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["progress-text-font"]
              ? element.options["progress-text-font"]
              : undefined
          }
          placeholder={
            variant.options["progress-text-font"]
              ? variant.options["progress-text-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={element.options["progress-format"] !== "none"}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][progress-text-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["progress-text-font-weight"]
              ? element.options["progress-text-font-weight"]
              : undefined
          }
          placeholder={
            variant.options["progress-text-font-weight"]
              ? variant.options["progress-text-font-weight"]
              : "400"
          }
          condition={element.options["progress-format"] !== "none"}
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Message font size"
          setting_name="[options][progress-text-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["progress-text-size"]
              ? element.options["progress-text-size"]
              : undefined
          }
          placeholder={
            variant.options["progress-text-size"]
              ? variant.options["progress-text-size"]
              : 16
          }
          condition={element.options["progress-format"] !== "none"}
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Message font size"
          setting_name="[options][progress-text-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["progress-text-size-mobile"]
              ? element.options["progress-text-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["progress-text-size-mobile"]
              ? variant.options["progress-text-size-mobile"]
              : variant.options["progress-text-size"]) || 16
          }
          condition={element.options["progress-format"] !== "none"}
          min={0}
          max={100}
          range_step={1}
          device={"mobile"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-text-color]"
          setting_type="color_v2"
          value={
            element.options["progress-text-color"]
              ? element.options["progress-text-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-text-color"]
              ? variant.options["progress-text-color"]
              : "#fff"
          }
          label="Message text color"
          condition={element.options["progress-format"] !== "none"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-outer-background-color]"
          setting_type="color_v2"
          value={
            element.options["progress-outer-background-color"]
              ? element.options["progress-outer-background-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-outer-background-color"]
              ? variant.options["progress-outer-background-color"]
              : "#ecf0f6"
          }
          label="Bar background color"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][progress-inner-background-color]"
          setting_type="color_v2"
          value={
            element.options["progress-inner-background-color"]
              ? element.options["progress-inner-background-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-inner-background-color"]
              ? variant.options["progress-inner-background-color"]
              : "#0D42E4"
          }
          label="Bar meter color"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Border Style"
          setting_name="[options][progress-border-style]"
          setting_type="border_style"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["progress-border-style"]
              ? element.options["progress-border-style"]
              : undefined
          }
          placeholder={
            variant.options["progress-border-style"]
              ? variant.options["progress-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Border color"
          setting_name="[options][progress-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["progress-border-color"]
              ? element.options["progress-border-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-border-color"]
              ? variant.options["progress-border-color"]
              : "#000"
          }
          condition={element.options["progress-border-style"] !== "none"}
          show_reset={true}
        />

        {element.options["progress-border-style"] !== "none" && (
          <div
            className="col-xs-12 some-vertical-space setting"
            style={{ marginBottom: "5px" }}
          >
            <div className="row">
              <div className="col-xs-12">
                <div className="mb-[5px] text-xl text-black dark:text-white">
                  Border Width
                </div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][progress-border-width-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(
                          element.options[`progress-border-width-${side}`]
                        ) == false
                          ? element.options[`progress-border-width-${side}`]
                          : element.options["progress-border-width"]
                          ? element.options["progress-border-width"].replace(
                              "px",
                              ""
                            )
                          : "2"
                      }
                      placeholder="0px"
                      col="3"
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <VariantBuilderSetting
          object={element}
          label="Corner Radius"
          setting_name="[options][progress-outer-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["progress-outer-radius"]
              ? element.options["progress-outer-radius"]
              : undefined
          }
          placeholder={
            variant.options["progress-outer-radius"]
              ? variant.options["progress-outer-radius"]
              : undefined
          }
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][progress-outer-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`progress-outer-radius-${side}`]) ==
                    false
                      ? element.options[`progress-outer-radius-${side}`]
                      : "3"
                  }
                  condition={
                    element.options["progress-outer-radius"] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsProgressDesign;
