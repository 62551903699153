import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useFieldOptions from "../field_options/useFieldOptions";
import useActionGroups from "../action_groups/useActionGroups";

const useFields = (prepend_to_container = true) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { FieldOptions } = useFieldOptions();
  const { ActionGroups } = useActionGroups();
  let variant = contextData.objects.variant;

  function generateID() {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  }

  const Fields = {
    create: async function (element, payload, template_id = null, callback) {
      let position =
        Object.values(variant.fields).filter(
          (field) =>
            field.element_id == element.id && element.toBeDeleted !== true
        ).length + 13;

      let label;
      let slug;
      let options = {};
      let required;
      let field_type;
      let placeholder;

      if (template_id == null) {
        // Creating from scratch

        if (element.element_type == "quiz") {
          let question_position = (payload && payload["position"]) || position;

          options["description"] = "<p>Choose your answer below</p>";
          label = "<h1>Write question here</h1>";
          position = question_position;
          placeholder = "Write placeholder";
          required = true;
          field_type = "survey";
          slug = payload && payload["slug"] ? payload["slug"] : "New Field";
        } else {
          label = "Empty Field";
          slug = payload && payload["slug"];
          options = (payload && payload["options"]) || {};
          required = payload && payload["required"];
          field_type = "text";
          placeholder = payload && payload["placeholder"];
        }
      }

      builder.create(
        "fields",
        {
          position: position,
          element_id: element.id,
          label: label,
          placeholder: placeholder,
          slug: slug,
          options: options,
          required: required,
          template_id: template_id,
          service: "ConvertFlow",
          field_type: field_type,
          ...payload,
        },
        {
          callback: (field) => {
            if (element.element_type == "quiz") {
              if (template_id == null) {
                [0,1,2].forEach((index) => {
                  let position_letter = String.fromCharCode(65 + index);
                  const default_value = `Option ${position_letter}`;

                  builder.create("field_options", {
                    position: index,
                    field_id: field.id,
                    option_type: field.field_type == "survey" ? "choice" : undefined,
                    label: default_value,
                    value: default_value,
                    emoji: "😎",
                    letter: position_letter,
                    icon: 'fas fa-archive'
                  },
                  {
                    callback: () => {},
                    skip_history: true,
                    forceRender: true
                  }
                )})
              }

              Fields.addChildrenToBuilder(field, true);

              if (field.action_groups) {
                Object.values(field.action_groups).forEach((action_group) => {
                  builder.addObjectToBuilder(
                    "action_groups",
                    action_group,
                    true
                  );
                });
              }

              if (field.fields) {
                Object.values(field.fields).forEach((nested_field) => {
                  if (nested_field.action_groups) {
                    Object.values(nested_field.action_groups).forEach((action_group) => {
                      builder.addObjectToBuilder(
                        "action_groups",
                        action_group,
                        true
                      );
                    });
                  }

                  builder.addObjectToBuilder(
                    "fields",
                    nested_field,
                    true
                  );
                  Fields.addChildrenToBuilder(nested_field, true);
                });
              }
            }

            if (callback) {
              callback(field);
            }
          },
          forceRender: true,
          skip_select: element.element_type !== 'quiz'
        }
      );
    },

    duplicate: async function (og_field, payload, skip_history) {
      let attributes = {
        position: og_field.position,
        label: og_field.label,
        service: og_field.service,
        slug: og_field.slug,
        placeholder: og_field.placeholder,
        field_type: og_field.field_type,
        field_id: og_field.field_id,
        rows: og_field.rows,
        value: og_field.value,
        url_params: og_field.url_params,
        required: og_field.required,
        multi_select: og_field.multi_select,
        options: og_field.options,
        element_id: og_field.element_id,
        duplicated: true,
      };

      if (
        og_field.field_type == "survey" &&
        (!og_field.slug || og_field.slug.indexOf("survey_") > -1)
      ) {
        attributes.slug = "survey_" + generateID();
      }

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      return new Promise((resolve, reject) => {
        builder.create("fields", attributes, {
          callback: async function (field) {
            try {
              // Duplicate conditional jumps for the parent field
              let action_groups = Object.values(variant.action_groups).filter(
                (action_group) =>
                  action_group.field_id == og_field.id &&
                  action_group.element_id == og_field.element_id &&
                  action_group.toBeDeleted !== true
              );

              let field_options = Object.values(variant.field_options).filter(
                (field_option) =>
                  field_option.field_id == og_field.id &&
                  field_option.toBeDeleted !== true
              );

              let nested_fields = Object.values(variant.fields).filter(
                (field) =>
                  field.field_id == og_field.id && field.toBeDeleted !== true
              );

              await Promise.all([
                ...action_groups.map((action_group) =>
                  ActionGroups.duplicate(
                    action_group,
                    {
                      element_id: field.element_id,
                      field_id: field.id,
                    },
                    true
                  )
                ),
                ...field_options.map((field_option) =>
                  FieldOptions.duplicate(
                    field_option,
                    {
                      field_id: field.id,
                    },
                    true
                  )
                ),
                ...nested_fields.map(async (nested_field) => {
                  await Fields.duplicate(
                    nested_field,
                    { element_id: field.element_id, field_id: field.id },
                    true
                  );

                  // Duplicate conditional jumps for the nested fields
                  let nested_action_groups = Object.values(
                    variant.action_groups
                  ).filter(
                    (action_group) =>
                      action_group.field_id == nested_field.id &&
                      action_group.element_id == nested_field.element_id &&
                      action_group.toBeDeleted !== true
                  );

                  await Promise.all(
                    nested_action_groups.map((action_group) =>
                      ActionGroups.duplicate(
                        action_group,
                        {
                          element_id: field.element_id,
                          field_id: nested_field.id,
                        },
                        true
                      )
                    )
                  );
                }),
              ]);

              resolve(field);
            } catch (error) {
              reject(error);
            }
          },
          skip_history: skip_history,
          skip_select: true,
        });
      });
    },

    remove: (field) => {
      let message =
        "Are you sure you want to remove this field? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove("fields", field.id, message);
    },

    addChildrenToBuilder: (field, skip_history) => {
      if (Object.keys(field).includes("field_options")) {
        Object.values(field.field_options).forEach((field_option) => {
          builder.addObjectToBuilder(
            "field_options",
            field_option,
            skip_history
          );
        });
      }
    },

    sortableStart: (element, given_container = undefined) => {
      let fields_container = document.querySelector(
        '.form-fields-container'
      );
      let quiz_container = document.querySelector(
        '.quiz-fields-container'
      );
      let container = given_container || fields_container || quiz_container;

      let item_selector =
        ".setting[data-setting_type='preset_field'], " +
        ".setting[data-setting_type='item'][data-object_type='fields'], " +
        ".setting[data-setting_type='item_v2'][data-object_type='fields']";

      if (prepend_to_container) {
        $(container)
          .find(item_selector)
          .sort(function (a, b) {
            return $(a).attr("data-position") - $(b).attr("data-position");
          })
          .prependTo(container);
      }

      let sortable_options = {
        sort: true,
        group: {
          name: given_container ? container.className : "field-position",
        },
        animation: 0,
        draggable: item_selector,
        ghostClass: "drop-zone",
        handle:
          ".field-trigger, .item-trigger[data-object_type='fields'], .cf-item-v2",
        forceFallback: true,
        fallbackOnBody: true,
        fallbackClass: "builder-sortable-fallback",
        fallbackTolerance: 5,
        delay: 10,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        swapThreshold: 0.8,
        onStart: (event) => {
          if (element.element_type == "quiz") {
            document
              .querySelector("#options-panel")
              .querySelectorAll(".cf-quiz-sortable-subfield-indicator")
              .forEach((indicator) => {
                if (
                  event.item.dataset.object_id !==
                  indicator.parentElement.getAttribute("data-field-item-id")
                ) {
                  indicator.parentElement.style.cssText = `
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    height: 40px;
                    margin: 0px;
                    width: 180px;
                    margin-left: 45px;
                    border: 1px dashed lightgray;
                  `;
                  indicator.style.display = "block";
                }
              });
          }
        },
        onEnd: (event) => {
          if (element.element_type == "quiz") {
            document
              .querySelector("#options-panel")
              .querySelectorAll(".cf-quiz-sortable-subfield-indicator")
              .forEach((indicator) => {
                indicator.parentElement.style.cssText = `
                  display: inherit;
                  height: initial;
                  width: initial;
                  margin-left: 18px;
                  border: none;
                `;
                indicator.style.display = "none";
              });
          }
        },
        onMove: function (event) {
          if (element.element_type == "quiz") {
            let item_v2 =
              event.dragged.querySelector(".cf-item-v2") ||
              event.related.querySelector(".cf-item-v2");
            let dragged_object_id = event.dragged.getAttribute(
              "data-object_id"
            );
            let drop_field_id = event.to.getAttribute("data-field-item-id");
            let draggingPosition = parseInt(event.dragged.dataset["position"]);
            let relatedPosition = parseInt(event.related.dataset["position"]);

            // If trying to move into the same position avoid children not moving properly
            if (
              relatedPosition == draggingPosition + 1 &&
              !event.willInsertAfter
            )
              return false;

            // If we are transforming into a subfield
            if (drop_field_id) {
              if (dragged_object_id == drop_field_id) return false;

              let has_children =
                parseInt(item_v2.getAttribute("data-nested-items-length")) > 0;

              return !has_children;
            }
          }
        },
        onUpdate: (event) => {
          Fields.sortableUpdate(event, element);
        },
        onAdd: (event) => {
          var itemEl = event.item;
          let origParent = event.from;
          origParent.appendChild(itemEl);

          Fields.sortableAdd(event);
        },
      };
      new Sortable(container, sortable_options);
    },

    sortableAdd: (event) => {
      let updates = [];
      let field_item_id = parseInt(event.to.getAttribute("data-field-item-id"));
      let dragged_index = event.newIndex + 1;
      let new_position_index_start;

      updates.push({
        object_type: "fields",
        object_id: parseInt(event.item.getAttribute("data-object_id")),
        setting_name: "[field_id]",
        value: field_item_id,
        old_value: parseInt(event.from.getAttribute("data-field-item-id")),
      });

      updates.push({
        object_type: "fields",
        object_id: parseInt(event.item.getAttribute("data-object_id")),
        setting_name: "[position]",
        value: dragged_index,
        old_value: parseInt(event.item.getAttribute("data-position")),
      });

      Array.from(event.to.children).forEach((field_div, i) => {
        let new_position = i + 1;
        let old_position = parseInt(field_div.getAttribute("data-position"));

        if (new_position < dragged_index) return;
        if (new_position == dragged_index) {
          new_position_index_start = new_position + 1;
          new_position = new_position_index_start;
        } else if (new_position > dragged_index) {
          new_position = new_position_index_start + 1;
          new_position_index_start += 1;
        }

        if (
          ["item", "item_v2"].includes(
            field_div.getAttribute("data-setting_type")
          )
        ) {
          updates.push({
            object_type: "fields",
            object_id: parseInt(field_div.getAttribute("data-object_id")),
            setting_name: "[position]",
            value: new_position,
            old_value: old_position,
          });
        }
      });

      builder.update(updates);
    },

    sortableUpdate: (event, element) => {
      let updates = [];

      let item_children = event.to.querySelectorAll(
        ':scope > .setting[data-setting_type="item"], :scope > .setting[data-setting_type="preset_field"], :scope >  .setting[data-setting_type="item_v2"]'
      );

      Array.from(event.to.children).forEach((field_div, i) => {
        let new_position = i + 1;
        let setting_name =
          "[options][" +
          field_div.getAttribute("data-field-name") +
          "-position]";

        if (field_div.getAttribute("data-setting_type") == "preset_field") {
          updates.push({
            object_type: "elements",
            object_id: element.id,
            setting_name: setting_name,
            value: new_position,
          });
        }

        if (
          ["item", "item_v2"].includes(
            field_div.getAttribute("data-setting_type")
          )
        ) {
          // Old behavior prepend items to container making them always at the top. With item_v2 this might not be true
          let position_within_container =
            Array.from(item_children).indexOf(field_div) + 1;

          updates.push({
            object_type: "fields",
            object_id: parseInt(field_div.getAttribute("data-object_id")),
            setting_name: "[position]",
            value: position_within_container,
          });
        }
      });

      builder.update(updates);
    },

    sortableReorderUpdates: (items) => {
      let updates = [];

      Array.from(items).forEach((item, i) => {
        let object_id = parseInt(item.getAttribute("data-object_id"));
        let old_position = parseInt(item.getAttribute("data-position"));
        let new_position = i + 1;

        if (new_position !== old_position) {
          updates.push({
            object_type: "fields",
            object_id: object_id,
            setting_name: "[position]",
            value: new_position,
            skip_history: true,
            old_value: old_position,
          });
        }
      });

      builder.update(updates);
    },

    layersSortableStart: (element_id) => {
      let container = document.querySelector(`#layers .layers-container[data-object-type='elements'][data-object-id="${element_id}"]`);

      let sortable_options = {
        sort: true,
        group: { name: `element-${element_id}-field-layer-items` },
        animation: 150,
        draggable: `.layers-item[data-object-type='fields'][data-field-is-child='false']`,
        ghostClass: "drop-zone",
        handle: `.layers-item-icon[data-object-type='fields'][data-field-is-child='false'], .layers-item-label[data-object-type='fields'][data-field-is-child='false']`,
        scroll: true,
        scrollSensitivity: 100,
        forceFallback: true,
        fallbackOnBody: true,
        fallbackTolerance: 5,
        fallbackClass: 'hide-during-drag',
        scrollSpeed: 10,
        onUpdate: function (event) {
          Fields.layersSortableUpdate(event);
        },
      };

      if (container) {
        new Sortable(container, sortable_options);
      }
    },

    layersSortableUpdate: (event) => {
      let updates = [];

      Array.from(event.to.children).forEach((list_item_div, i) => {
        let new_position = i + 1;
        let object_id = list_item_div.getAttribute("data-object-id");
        if (object_id) {
          let field_id = parseInt(object_id);

          updates.push({
            object_type: "fields",
            object_id: field_id,
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },

    sublayersSortableStart: (field_id) => {
      let container = document.querySelector(`#layers .layers-container[data-object-type='fields'][data-object-id="${field_id}"]`);

      let sortable_options = {
        sort: true,
        group: { name: `field-${field_id}-sub-layer-items` },
        animation: 150,
        draggable: `.layers-item[data-object-type='fields'][data-field-is-child='true']`,
        ghostClass: "drop-zone",
        handle: `.layers-item-icon[data-object-type='fields'][data-field-is-child='true'], .layers-item-label[data-object-type='fields'][data-field-is-child='true']`,
        scroll: true,
        scrollSensitivity: 100,
        forceFallback: true,
        fallbackOnBody: true,
        fallbackTolerance: 5,
        fallbackClass: 'hide-during-drag',
        scrollSpeed: 10,
        onUpdate: function (event) {
          Fields.layersSortableUpdate(event);
        },
      };

      if (container) {
        new Sortable(container, sortable_options);
      }
    },

    sublayersSortableUpdate: (event) => {
      let updates = [];

      Array.from(event.to.children).forEach((list_item_div, i) => {
        let new_position = i + 1;
        let object_id = list_item_div.getAttribute("data-object-id");
        if (object_id) {
          let field_id = parseInt(object_id);

          updates.push({
            object_type: "fields",
            object_id: field_id,
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },
    
    reset: (object, updates) => {
      let mergingChanges = false;
      let resettableSettings = {
        'quiz-options-layout': null,
        'quiz-options-layout-mobile': null,
        'quiz-options-vertical-spacing': null,
        'quiz-options-horizontal-spacing': null,
        'quiz-options-horizontal-spacing-mobile': null,
        'quiz-visual-placement': null,
        'quiz-visual-placement-mobile': null,
        'quiz-option-font-family': null,
        'quiz-option-font-weight': null,
        'quiz-option-font-size': null,
        'quiz-option-font-size-mobile': null,
        'quiz-option-font-line-height': null,
        'quiz-option-font-line-height-mobile': null,
        'quiz-option-font-color': null,
        'quiz-hover-option-font-color': null,
        'quiz-option-visual-font-size': null,
        'quiz-option-visual-font-size-mobile': null,
        'quiz-option-visual-spacing': null,
        'quiz-option-visual-spacing-mobile': null,
        'quiz-option-color-size': null,
        'quiz-option-color-size-mobile': null,
        'quiz-option-background-color': null,
        'quiz-hover-option-background-color': null,
        'quiz-option-border-placement': null,
        'quiz-option-border-style': null,
        'quiz-option-border-color': null,
        'quiz-hover-option-border-color': null,
        'quiz-option-border-width-left': null,
        'quiz-option-border-width-top': null,
        'quiz-option-border-width-bottom': null,
        'quiz-option-border-width-right': null,
        'quiz-option-border-radius': null,
        'quiz-option-border-radius-top-left': null,
        'quiz-option-border-radius-top-right': null,
        'quiz-option-border-radius-bottom-right': null,
        'quiz-option-border-radius-bottom-left': null,
        'quiz-selected-option-style': null,
        'quiz-selected-option-border-style': null,
        'quiz-selected-option-border-color': null,
        'quiz-selected-option-border-width-left': null,
        'quiz-selected-option-border-width-top': null,
        'quiz-selected-option-border-width-bottom': null,
        'quiz-selected-option-border-width-right': null,
        'quiz-selected-option-font-color': null,
        'quiz-selected-option-background-color': null,
        'quiz-option-box-shadow': null,
        'quiz-option-box-shadow-horizontal-distance': null,
        'quiz-option-box-shadow-vertical-distance': null,
        'quiz-option-box-shadow-blur-radius': null,
        'quiz-option-box-shadow-color': null,
        'quiz-option-width-style': null,
        'quiz-option-width-value': null,
        'quiz-option-width-style-mobile': null,
        'quiz-option-width-value-mobile': null,
        'quiz-option-image-width': null,
        'quiz-option-image-width-mobile': null,
        'quiz-option-image-height': null,
        'quiz-option-image-height-mobile': null,
        'quiz-option-text-alignment': null,
        'quiz-option-text-alignment-mobile': null,
        'quiz-option-color': null,
        'quiz-hover-option-color': null,
        'quiz-answer-width': null,
        'quiz-answer-width-value': null,
        'quiz-answer-field-size': null,
        'quiz-answer-field-size-value': null,
        'quiz-answer-field-font': null,
        'quiz-answer-field-font-weight': null,
        'quiz-answer-field-font-size': null,
        'quiz-answer-field-font-size-mobile': null,
        'quiz-answer-field-text-color': null,
        'quiz-answer-field-rounded': null,
        'quiz-answer-field-background-color': null,
        'quiz-answer-field-border-style': null,
        'quiz-answer-field-border-color': null,
        'quiz-answer-field-box-shadow': null,
        'quiz-answer-field-box-shadow-horizontal-distance': null,
        'quiz-answer-field-box-shadow-vertical-distance': null,
        'quiz-answer-field-box-shadow-blur-radius': null,
        'quiz-answer-field-box-shadow-spread-radius': null,
        'quiz-answer-field-box-shadow-color': null
      }

      if (!updates) {
        mergingChanges = true;
        updates = [];
      }

      // Reset the element's settings
      Object.keys(resettableSettings).forEach((setting_name) => {
        let setting_value = resettableSettings[setting_name];

        if (object.options[setting_name]) {
          updates.push({
            object_type: "fields",
            object_id: object.id,
            setting_name: `[options][${setting_name}]`,
            value: setting_value
          });
        }
      })

      if (mergingChanges) {
        builder.update(updates);
      } else {
        return updates;
      }
    }
  };

  let currentHook = Fields;
  return { Fields, currentHook };
};

export default useFields;
