import React, { useEffect, useRef, memo, useMemo, useState } from "react";

const TemplatePreview = memo((props) => {
  const template = props.template;
  const contentRef = useRef(null);
  const timeoutRef = useRef(null);
  const lastRequestTimeRef = useRef(0);
  const [isLoading, setIsLoading] = useState(false);

  const buildPreviewUrl = useMemo(() => {
    if (!template) return null;

    const root_url = window.location.origin;
    const targetController = {
      Cta: "cta",
      Section: "sections",
      Step: "steps",
      Element: "elements",
      Field: "fields",
    }[template.object_type];

    let src = `${root_url}/websites/0/${targetController}/${template.object_id}/preview`;

    let parsed_options = {
      "h1-font-family": props.options["heading-font-family"],
      "h2-font-family": props.options["heading-font-family"],
      "h3-font-family": props.options["heading-font-family"],
      "h4-font-family": props.options["heading-font-family"],
      "h1-font-color": props.options["heading-font-color"],
      "h2-font-color": props.options["heading-font-color"],
      "h3-font-color": props.options["heading-font-color"],
      "h4-font-color": props.options["heading-font-color"],
      "p-font-family": props.options["paragraph-font-family"],
      "p-font-color": props.options["paragraph-font-color"],
      "button-color": props.options["button-color"],
    };

    if (props.options) {
      src += `?${new URLSearchParams({
        variant: JSON.stringify({ options: parsed_options }),
      }).toString()}`;
    }

    return src;
  }, [template?.id, props.options]);

  const fetchTemplatePreview = (previewUrl) => {
    if (!contentRef.current || !template || !template.id) return;

    const now = Date.now();
    const timeSinceLastRequest = now - lastRequestTimeRef.current;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (timeSinceLastRequest < 1500) {
      timeoutRef.current = setTimeout(() => {
        fetchTemplatePreview(previewUrl);
      }, 1500 - timeSinceLastRequest);
      return;
    }

    lastRequestTimeRef.current = now;

    $.ajax({
      url: `${window.location.origin}/templates/${template.id}`,
      dataType: "JSON",
      type: "GET",
      complete: function (data) {
        const responseData = data.responseJSON;
        if (!responseData || !contentRef.current) return;

        if (previewUrl) {
          $.ajax({
            url: previewUrl,
            type: "GET",
            success: function (html) {
              if (contentRef.current) {
                contentRef.current.innerHTML = html;
                setIsLoading(false);
              }
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchTemplatePreview(buildPreviewUrl);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [template?.id, buildPreviewUrl]);

  return (
    <React.Fragment>
      <div className="relative bg-white max-h-[200px] overflow-hidden">
        <div className="relative flex h-[1080px] origin-top scale-[0.5]">
          <div
            data-src={buildPreviewUrl}
            ref={contentRef}
            className="w-full h-full template-preview-container"
          />
        </div>

        {isLoading ? (
          <div className="z-[1] absolute flex items-center justify-center inset-0 bg-black opacity-[0.7]">
            <span className="cf-loading-spinner" />
          </div>
        ) : (
          <div className="absolute inset-0 bg-transparent hover:bg-white dark:hover:bg-slate-800 hover:bg-opacity-50 dark:hover:bg-opacity-50 cursor-pointer">
            {props.children}
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default TemplatePreview;
