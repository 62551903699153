import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsTimerDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Timer`} open={false}>
        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-format]"
          setting_type="dropdown"
          label="Timer format"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-format"]
              ? element.options["timer-format"]
              : "card"
          }
          options={[
            { value: "card", text: "Cards" },
            { value: "inline", text: "Inline Text" },
          ]}
          condition={["timer"].includes(element.element_type)}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-units]"
          setting_type="dropdown"
          label="Timer units"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-units"]
              ? element.options["timer-units"]
              : "d/h/m/s"
          }
          options={[
            { value: "d/h/m/s", text: "Days/Hours/Minutes/Seconds" },
            { value: "d/h/m", text: "Days/Hours/Minutes" },
            { value: "d/h", text: "Days/Hours" },
            { value: "d", text: "Days" },
          ]}
          condition={["timer"].includes(element.element_type)}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer number font"
          setting_name="[options][timer-number-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-number-font"]
              ? element.options["timer-number-font"]
              : undefined
          }
          placeholder={
            variant.options["timer-number-font"]
              ? variant.options["timer-number-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={["timer"].includes(element.element_type)}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-number-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-number-font-weight"]
              ? element.options["timer-number-font-weight"]
              : undefined
          }
          placeholder={variant.options["timer-number-font-weight"]
          ? variant.options["timer-number-font-weight"]
          : undefined}
          condition={["timer"].includes(element.element_type)}
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer number size"
          setting_name="[options][timer-number-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-number-size"]
              ? element.options["timer-number-size"]
              : undefined
          }
          placeholder={
            variant.options["timer-number-size"]
              ? variant.options["timer-number-size"]
              : 35
          }
          condition={["timer"].includes(element.element_type)}
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          allow_empty={true}
          show_reset={true}

        />

        <VariantBuilderSetting
          object={element}
          label="Timer number size"
          setting_name="[options][timer-number-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-number-size-mobile"]
              ? element.options["timer-number-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["timer-number-size-mobile"]
              ? variant.options["timer-number-size-mobile"]
              : variant.options["timer-number-size"]
          }
          condition={["timer"].includes(element.element_type)}
          min={0}
          max={100}
          range_step={1}
          device={"mobile"}
          allow_empty={true}
          show_reset={true}

        />

        <VariantBuilderSetting
          object={element}
          label="Timer number text color"
          setting_name="[options][timer-number-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-number-color"]
              ? element.options["timer-number-color"]
              : undefined
          }
          placeholder={
            variant.options["timer-number-color"]
              ? variant.options["timer-number-color"]
              : "#fff"
          }
          condition={["timer"].includes(element.element_type)}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-padding]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Timer number background padding"}
          value={
            element.options["timer-padding"]
              ? element.options["timer-padding"]
              : undefined
          }
          placeholder={variant.options["timer-padding"]
          ? variant.options["timer-padding"]
          : 20}
          condition={
            element.element_type == "timer" &&
            element.options["timer-format"] == "card"
          }
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-padding-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Timer number background padding"}
          value={
            element.options["timer-padding-mobile"]
              ? element.options["timer-padding-mobile"]
              : undefined
          }
          placeholder={variant.options["timer-padding-mobile"]
          ? variant.options["timer-padding-mobile"]
          : 20}
          condition={
            element.element_type == "timer" &&
            element.options["timer-format"] == "card"
          }
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer number background color"
          setting_name="[options][timer-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-background-color"]
              ? element.options["timer-background-color"]
              : undefined
          }
          placeholder={
            variant.options["timer-background-color"]
              ? variant.options["timer-background-color"]
              : "#000"
          }
          condition={
            element.element_type == "timer" &&
            element.options["timer-format"] == "card"
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer number border style"
          setting_name="[options][timer-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-border-style"]
              ? element.options["timer-border-style"]
              : undefined
          }
          condition={
            element.element_type == "timer" &&
            element.options["timer-format"] == "card"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer number border color"
          setting_name="[options][timer-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-border-color"]
              ? element.options["timer-border-color"]
              : undefined
          }
          placeholder={
            variant.options["timer-border-color"]
              ? variant.options["timer-border-color"]
              : "#000"
          }
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-format"] == "card" &&
            ["dotted", "dashed", "solid"].includes(
              element.options["timer-border-style"]
            )
          }
          show_reset={true}
        />

        {["timer"].includes(element.element_type) &&
          element.options["timer-format"] == "card" &&
          element.options["timer-border-style"] &&
          element.options["timer-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Timer Number Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][timer-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[`timer-border-width-${side}`]
                          ) == false
                            ? element.options[`timer-border-width-${side}`]
                            : element.options["timer-border-width"]
                            ? element.options["timer-border-width"].replace(
                                "px",
                                ""
                              )
                            : "1"
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Timer Number Corner Radius"
          setting_name="[options][timer-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-radius"]
              ? element.options["timer-radius"]
              : undefined
          }
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-format"] == "card"
          }
          show_reset={true}
        />

        {["timer"].includes(element.element_type) &&
          element.options["timer-format"] == "card" &&
          element.options["timer-radius"] == "custom" && (
            <div className="col-xs-12">
              {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                (side) => {
                  const settingName = `[options][timer-radius-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(element.options[`timer-radius-${side}`]) ==
                        false
                          ? element.options[`timer-radius-${side}`]
                          : "3"
                      }
                      min={0}
                      note={capitalizeFirstLetter(side).replace("-", " ")}
                      col="3"
                      placeholder="0px"
                      classNames="spacing-inputs"
                    />
                  );
                }
              )}
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Timer Number Shadow"
          setting_name="[options][timer-shadow]"
          setting_type="shadow"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-shadow"]
              ? element.options["timer-shadow"]
              : undefined
          }
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-format"] == "card"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["timer-shadow-horizontal-distance"]
              ? element.options["timer-shadow-horizontal-distance"]
              : undefined
          }
          placeholder={variant.options["timer-shadow-horizontal-distance"]
          ? variant.options["timer-shadow-horizontal-distance"]
          : undefined}
          condition={element.options["timer-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["timer-shadow-vertical-distance"]
              ? element.options["timer-shadow-vertical-distance"]
              : undefined
          }
          placeholder={variant.options["timer-shadow-vertical-distance"]
          ? variant.options["timer-shadow-vertical-distance"]
          : undefined}
          condition={element.options["timer-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["timer-shadow-blur-radius"]
              ? element.options["timer-shadow-blur-radius"]
              : undefined
          }
          placeholder={variant.options["timer-shadow-blur-radius"]
          ? variant.options["timer-shadow-blur-radius"]
          : "0"}
          condition={element.options["timer-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["timer-shadow-spread-radius"]
              ? element.options["timer-shadow-spread-radius"]
              : undefined
          }
          placeholder={variant.options["timer-shadow-spread-radius"]
          ? variant.options["timer-shadow-spread-radius"]
          : undefined}
          condition={element.options["timer-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Shadow Color"
          setting_name="[options][timer-shadow-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-shadow-color"]
              ? element.options["timer-shadow-color"]
              : undefined
          }
          placeholder={variant.options["timer-shadow-color"]
          ? variant.options["timer-shadow-color"]
          : "rgba(0,0,0, 0.35)"}
          condition={element.options["timer-shadow"] == "custom"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Inset shadow position"
          setting_name="[options][timer-shadow-inset]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-shadow-inset"]
              ? element.options["timer-shadow-inset"]
              : "false"
          }
          condition={element.options["timer-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer unit text font"
          setting_name="[options][timer-unit-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-unit-font"]
              ? element.options["timer-unit-font"]
              : undefined
          }
          placeholder={
            variant.options["timer-unit-font"]
              ? variant.options["timer-unit-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={["timer"].includes(element.element_type)}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-unit-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-unit-font-weight"]
              ? element.options["timer-unit-font-weight"]
              : undefined
          }
          condition={["timer"].includes(element.element_type)}
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer unit text color"
          setting_name="[options][timer-unit-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-unit-color"]
              ? element.options["timer-unit-color"]
              : undefined
          }
          placeholder={
            variant.options["timer-unit-color"]
              ? variant.options["timer-unit-color"]
              : "#000"
          }
          condition={["timer"].includes(element.element_type)}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer unit text size"
          setting_name="[options][timer-unit-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-unit-size"]
              ? element.options["timer-unit-size"]
              : undefined
          }
          placeholder={
            variant.options["timer-unit-size"]
              ? variant.options["timer-unit-size"]
              : "15"
          }
          condition={["timer"].includes(element.element_type)}
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Timer unit text size"
          setting_name="[options][timer-unit-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-unit-size-mobile"]
              ? element.options["timer-unit-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["timer-unit-size-mobile"]
              ? variant.options["timer-unit-size-mobile"]
              : "13"
          }
          condition={["timer"].includes(element.element_type)}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name={`Expired Message`} open={false} condition={element.options["timer-end-action"] == "message"}>
        <VariantBuilderSetting
          object={element}
          label="Expired message text color"
          setting_name="[options][timer-message-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-message-color"]
              ? element.options["timer-message-color"]
              : undefined
          }
          placeholder={
            variant.options["timer-message-color"]
              ? variant.options["timer-message-color"]
              : "#000"
          }
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-end-action"] == "message"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Expired text font"
          setting_name="[options][timer-message-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-message-font"]
              ? element.options["timer-message-font"]
              : undefined
          }
          placeholder={
            variant.options["timer-message-font"]
              ? variant.options["timer-message-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-end-action"] == "message"
          }
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][timer-message-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-message-font-weight"]
              ? element.options["timer-message-font-weight"]
              : undefined
          }
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-end-action"] == "message"
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Expired message text size"
          setting_name="[options][timer-message-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-message-size"]
              ? element.options["timer-message-size"]
              : undefined
          }
          placeholder={
            variant.options["timer-message-size"]
              ? variant.options["timer-message-size"]
              : "15"
          }
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-end-action"] == "message"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Expired message text size"
          setting_name="[options][timer-message-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["timer-message-size-mobile"]
              ? element.options["timer-message-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["timer-message-size-mobile"]
              ? variant.options["timer-message-size-mobile"]
              : "20"
          }
          condition={
            ["timer"].includes(element.element_type) &&
            element.options["timer-end-action"] == "message"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsTimerDesign;
