async function apiRequest(method, api_endpoint, payload, callback, extra_headers = {}) {
  let status;
  let message;
  let csrf_token;
  let metaTag = document.querySelector('meta[name="csrf-token"]');

  if (metaTag) {
    csrf_token = metaTag.getAttribute("content");
  }

  let headers = {
    "Content-Type": "application/json; charset=utf-8",
    "X-CSRF-Token": csrf_token,
    ...extra_headers,
  };

  let options = {
    headers: headers,
    method: method == "PUT_WITH_RESPONSE" ? "PUT" : method,
  };

  if (!["GET"].includes(method) && payload) {
    options.body = JSON.stringify({ ...(payload || {}) });
  }

  const result = await fetch(api_endpoint, options)
    .then((response) => {
      status = response.status;
      message = response.statusText;
      return ["POST", "PUT_WITH_RESPONSE", "GET"].includes(method) ? response.json() : {};
    })
    .then((data) => {
      if (data && callback) {
        callback(data);
      }
      return {
        code: status,
        message: message,
        json: data,
      };
    })
    .catch(function (error) {
      return {
        code: 408,
        message: "Could not connect to server",
        json: {},
      };
    });
  return result;
}

export default apiRequest;
