import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsProgress = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        object_type="elements"
        object_id={element.id}
        setting_name="[options][progress-format]"
        setting_type="dropdown"
        value={
          element.options["progress-format"]
            ? element.options["progress-format"]
            : "box"
        }
        label="Message placement"
        options={[
          { value: "none", text: "None" },
          { value: "box", text: "Inside" },
          { value: "line", text: "Outside" },
        ]}
      />

      <VariantBuilderSetting
        object={element}
        object_type="elements"
        object_id={element.id}
        setting_name="[options][progress-type]"
        setting_type="dropdown"
        value={
          element.options["progress-type"]
            ? element.options["progress-type"]
            : "dynamic"
        }
        label="Text to show"
        condition={element.options["progress-format"] !== "none"}
        options={[
          { value: "dynamic", text: "Steps remaining" },
          { value: "static", text: "Custom message" },
          { value: "cart", text: "Cart offer progress" },
        ]}
      />

      <VariantBuilderSetting
        object={element}
        object_type="elements"
        object_id={element.id}
        setting_name="[html]"
        setting_type="editor"
        value={element.html}
        label="Custom message"
        condition={
          element.options["progress-type"] == "static" &&
          element.options["progress-format"] !== "none"
        }
        editor_toolbar_exclusions={["align", "customHTML"]}
      />

      <VariantBuilderSetting
        object={element}
        object_type="elements"
        object_id={element.id}
        setting_name="[options][progress-step-translation]"
        setting_type="text"
        value={
          _.isNil(element.options["progress-step-translation"]) == false
            ? element.options["progress-step-translation"]
            : "Step"
        }
        label="Custom message"
        condition={
          element.options["progress-type"] == "dynamic" &&
          element.options["progress-format"] !== "none"
        }
      />

      <VariantBuilderSetting
        object={element}
        object_type="elements"
        object_id={element.id}
        setting_name="[options][progress-message-cart]"
        setting_type="editor"
        value={element.options["progress-message-cart"]}
        label="Cart calculation message"
        condition={
          element.options["progress-type"] == "cart" &&
          element.options["progress-format"] !== "none"
        }
        editor_toolbar_exclusions={["align", "customHTML"]}
      />

      <VariantBuilderSetting
        object={step}
        setting_name="[html]"
        setting_type="code"
        label="HTML Editor"
        object_type="elements"
        object_id={element.id}
        value={element.html ? element.html : ""}
        condition={["progress"].includes(element.element_type) && element.options["progress-type"] == "static"}
        feature_gate_type="Custom HTML"
      />

      <VariantBuilderSetting
        object={step}
        setting_name="[progress-message-cart]"
        setting_type="code"
        label="HTML Editor"
        object_type="elements"
        object_id={element.id}
        value={element.options["progress-message-cart"] ? element.options["progress-message-cart"] : ""}
        condition={["progress"].includes(element.element_type) && element.options["progress-type"] == "cart"}
        feature_gate_type="Custom HTML"
      />
    
    </React.Fragment>
  );
};

export default ElementSettingsProgress;
