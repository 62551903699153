import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

const FieldOptionVisualChoice = (props) => {
  let field_option = props.field_option;
  let field = props.field;
  let important = props.builder !== true ? " !important" : "";
  let element = props.element;
  let variant = props.variant;
  const classes = style.classes(element, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    field_option,
    variant
  );

  const isImageVisualType = () => {
    return (
      (field_option.visual_type === null &&
        field_option.image &&
        field_option.image !== "none") ||
      (field_option.visual_type === "image" && field_option.image !== "none")
    );
  };

  const isLetterVisualType = () => {
    return field_option.visual_type === "letter" && field_option.letter;
  };

  const isIconVisualType = () => {
    return field_option.visual_type === "icon" && field_option.icon;
  };

  const isEmojiVisualType = () => {
    return field_option.visual_type === "emoji" && field_option.emoji;
  };

  const isColorVisualType = () => {
    return field_option.visual_type === "color";
  };

  const visualTypePlacement = () => {
    if (props.device == "mobile") {
      return (
        element.options["visual-type-placement-mobile"] ||
        variant.options["visual-type-placement-mobile"]
      );
    } else {
      return (
        element.options["visual-type-placement"] ||
        variant.options["visual-type-placement"]
      );
    }
  };

  return (
    <React.Fragment>
      <div
        data-id={field_option.id}
        data-field-option-id={field_option.id}
        data-position={field_option.position}
        data-value={
          field_option.value
            ? field_option.value
            : field_option.label
            ? field_option.label
            : "Option " + field_option.position
        }
        className={classes}
      >
        <div
          className="cf-col-xs-12 cf-survey-choice-label"
          style={{
            textAlign: "inherit" + important,
          }}
        >
          <div
            className="cf-visual-type-outer-box"
            style={{
              fontFamily: element.options["field-font"]
                ? element.options["field-font"] + important
                : undefined,
            }}
          >
            {isImageVisualType() ||
            isLetterVisualType() ||
            isIconVisualType() ||
            isColorVisualType() ||
            isEmojiVisualType() ? (
              <div className="cf-visual-type-box">
                {/* BEGIN IMAGE VISUAL TYPE */}
                {isImageVisualType() ? (
                  <span
                    className={
                      "cf-visual-type cf-row cf-outer-center cf-survey-choice-image-container"
                    }
                    style={{
                      display:
                        visualTypePlacement() == "centered"
                          ? "block"
                          : "inline-block",
                    }}
                  >
                    <img src={field_option.image}></img>
                  </span>
                ) : (
                  ""
                )}
                {/* END IMAGE VISUAL TYPE */}

                {/* BEGIN LETTER VISUAL TYPE */}
                {isLetterVisualType() ? (
                  <span className="cf-visual-type-letter">
                    {field_option.letter}
                  </span>
                ) : (
                  ""
                )}
                {/* END LETTER VISUAL TYPE */}

                {/* BEGIN ICON VISUAL TYPE */}
                {isIconVisualType() ? (
                  <span className="cf-visual-type">
                    <FontAwesomeIcon icon={field_option.icon} />
                  </span>
                ) : (
                  ""
                )}
                {/* END ICON VISUAL TYPE */}

                {/* BEGIN EMOJI VISUAL TYPE */}
                {isEmojiVisualType() ? (
                  <span className="cf-visual-type">{field_option.emoji}</span>
                ) : (
                  ""
                )}
                {/* END EMOJI VISUAL TYPE */}

                {/* BEGIN COLOR VISUAL TYPE */}
                {isColorVisualType() ? (
                  <span className="cf-visual-type-color" />
                ) : (
                  ""
                )}
                {/* END COLOR VISUAL TYPE */}
              </div>
            ) : (
              ""
            )}

            <div
              className="cf-survey-label"
              dangerouslySetInnerHTML={{
                __html: field_option.label
                  ? field_option.label
                  : field_option.value &&
                    !field_option.visual_type &&
                    (!field_option.image || field_option.image == "none")
                  ? field_option.value
                  : !field_option.visual_type &&
                    (!field_option.image || field_option.image == "none")
                  ? "Option " + field_option.position
                  : "",
              }}
            ></div>
          </div>
        </div>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, device) => {
    let options = element.options;
    let classes = "cf-survey-choice";

    let optionsWidthClass = "";
    if (options["options-width"] && options["options-width"] !== "custom") {
      optionsWidthClass = options["options-width"];
    }

    if (device == "mobile") {
      if (options["options-width-mobile"] && options["options-width-mobile"] !== "custom") {
        optionsWidthClass = options["options-width-mobile"];
      }
    }
    classes += " " + optionsWidthClass;

    classes += " " + (options["button-radius"] ? options["button-radius"] : "");

    classes += " " + (options["button-size"] ? options["button-size"] : "");

    return classes;
  },
  stylesheet: (builder, device, cta, element, field_option, variant) => {
    let visualTypeFontSize;
    if (element.options["visual-type-font-size"]) {
      visualTypeFontSize = element.options["visual-type-font-size"];
    } else if (variant.options["visual-type-font-size"]) {
      visualTypeFontSize = variant.options["visual-type-font-size"];
    } else {
      visualTypeFontSize = "16";
    }

    let fontColor;
    if (element.options["visual-type-font-color"]) {
      fontColor = element.options["visual-type-font-color"];
    }

    let optionsAlignment;
    if (element.options["options-alignment"]) {
      optionsAlignment = element.options["options-alignment"];
    }

    let optionsImageWidth;
    if (element.options["options-image-width"]) {
      optionsImageWidth = element.options["options-image-width"];
    } else if (variant.options["options-image-width"]) {
      optionsImageWidth = variant.options["options-image-width"];
    } else {
      optionsImageWidth = "134";
    }

    let optionsImageHeight;
    if (element.options["options-image-height"]) {
      optionsImageHeight = element.options["options-image-height"];
    } else if (variant.options["options-image-height"]) {
      optionsImageHeight = variant.options["options-image-height"];
    }

    let surveyLabelLeftAligned = "";
    let surveyLabelCenterAligned = `margin: auto;`;
    let surveyLabelRightAligned = `margin-left: auto; padding-right: 5px;`;

    let visualTypePlacement =
      element.options["visual-type-placement"] ||
      variant.options["visual-type-placement"];

    let visualTypePlacementWidth;
    if (visualTypePlacement == "centered") {
      visualTypePlacementWidth = "100%";
    } else if (visualTypePlacement == "left") {
      visualTypePlacementWidth = `${parseInt(visualTypeFontSize) * 0.6}%`;
    }

    let imageContainerHeight;
    if (visualTypePlacement == "left") {
      imageContainerHeight = visualTypeFontSize * 1.3;
    } else if (optionsImageWidth) {
      imageContainerHeight = optionsImageWidth * 0.67;
    }

    // prettier-ignore
    const desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-outer-box {
        align-items: center;
        justify-content: flex-start;
        display: flex;
        ${visualTypePlacement == "left" ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;'}
        ${visualTypePlacement == "left" ? 'gap: 15px;' : 'gap: 5px;'}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] {
        ${element.options["options-width"] == "cf-survey-regular" && element.options['options-display'] == 'inline-block' ? `display: inline-block !important; ${element.options["options-margin-left"] ? 'margin-bottom: 0px;' : 'margin-left: 17px !important; margin-right: 17px !important;' }` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] div.cf-survey-choice-label {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-box {
        ${visualTypePlacementWidth ? `width: ${visualTypePlacementWidth};` : ""}
        text-align: center !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-color {
        width: ${visualTypeFontSize * 1.5}px !important;
        height: ${visualTypeFontSize * 1.5}px !important;
        ${field_option.color ? `background-color: ${field_option.color};` : 'background-color: #000;' }
        line-height: 182% !important;
        border-radius: 50% !important;
        display: block !important;
        text-align: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-letter {
        ${visualTypeFontSize ? `font-size: ${visualTypeFontSize*0.77}px !important;` : ""}
        line-height: 182% !important;
        width: ${visualTypeFontSize * 1.5}px !important;
        height: ${visualTypeFontSize * 1.5}px !important;
        ${fontColor ? `border: 2px solid ${fontColor} !important;` : ''}
        ${fontColor ? `background-color: ${fontColor}14 !important;` : ''}
        ${fontColor ? `color: ${fontColor} !important;` : ""}
        border-radius: 50% !important;
        display: block !important;
        text-align: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type {
        ${visualTypeFontSize ? `font-size: ${visualTypeFontSize}px;` : ""}
        ${visualTypeFontSize ? `line-height: ${visualTypeFontSize * 1.3}px;` : ""}
        ${fontColor ? `color: ${fontColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-survey-choice-image-container {
        ${optionsImageWidth ? `width: ${optionsImageWidth}px !important;` : ""}
        ${optionsImageHeight ? `height: ${optionsImageHeight}px;` : imageContainerHeight ? `height: ${imageContainerHeight}px;` : ''}
        ${visualTypePlacement != "left" ? 'margin: 10px;' : ''}
        max-width: 100%;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-label {
        ${optionsAlignment === "left" ? surveyLabelLeftAligned : optionsAlignment === "right" ? surveyLabelRightAligned : surveyLabelCenterAligned}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .svg-inline--fa {
        display: var(--fa-display, inline-block);
        height: 1em;
        overflow: visible;
      }`;

    let optionsImageWidthMobile;
    if (element.options["options-image-width-mobile"]) {
      optionsImageWidthMobile = element.options["options-image-width-mobile"];
    } else if (variant.options["options-image-width-mobile"]) {
      optionsImageWidthMobile = variant.options["options-image-width-mobile"];
    } else {
      optionsImageWidthMobile = "134";
    }

    let optionsImageHeightMobile;
    if (element.options["options-image-height-mobile"]) {
      optionsImageHeightMobile = element.options["options-image-height-mobile"];
    } else if (variant.options["options-image-height-mobile"]) {
      optionsImageHeightMobile = variant.options["options-image-height-mobile"];
    }

    let visualTypeFontSizeMobile;
    if (element.options["visual-type-font-size-mobile"]) {
      visualTypeFontSizeMobile =
        element.options["visual-type-font-size-mobile"];
    } else if (variant.options["visual-type-font-size-mobile"]) {
      visualTypeFontSizeMobile =
        variant.options["visual-type-font-size-mobile"];
    } else if (element.options["visual-type-font-size"]) {
      visualTypeFontSizeMobile = element.options["visual-type-font-size"];
    } else if (variant.options["visual-type-font-size"]) {
      visualTypeFontSizeMobile = variant.options["visual-type-font-size"];
    } else {
      visualTypeFontSizeMobile = "16";
    }

    let fontColorMobile;
    if (element.options["visual-type-font-color-mobile"]) {
      fontColorMobile = element.options["visual-type-font-color-mobile"];
    }

    let visualTypePlacementMobile =
      element.options["visual-type-placement-mobile"] ||
      variant.options["visual-type-placement-mobile"] ||
      element.options["visual-type-placement"] ||
      variant.options["visual-type-placement"];

    let visualTypePlacementWidthMobile;
    if (visualTypePlacementMobile == "centered") {
      visualTypePlacementWidthMobile = "100%";
    } else if (visualTypePlacementMobile == "left") {
      visualTypePlacementWidthMobile = `${
        parseInt(visualTypeFontSizeMobile || visualTypeFontSize) * 0.6
      }%`;
    }

    let imageContainerHeightMobile;
    if (visualTypePlacementMobile == "left") {
      imageContainerHeightMobile =
        (visualTypeFontSizeMobile || visualTypeFontSize) * 1.3;
    } else if (optionsImageWidthMobile) {
      imageContainerHeightMobile = optionsImageWidthMobile * 0.67;
    }

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-outer-box {
        ${visualTypePlacementMobile == "left" ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;'}
        ${visualTypePlacementMobile == "left" ? 'gap: 15px;' : 'gap: 5px;'}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-box {
        ${visualTypePlacementWidthMobile ? `width: ${visualTypePlacementWidthMobile} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-color {
        ${visualTypeFontSizeMobile ? `font-size: ${visualTypeFontSizeMobile*0.77}px !important;` : ""}
        width: ${visualTypeFontSizeMobile * 1.5}px !important;
        height: ${visualTypeFontSizeMobile * 1.5}px !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type-letter {
        ${visualTypeFontSizeMobile ? `font-size: ${visualTypeFontSizeMobile*0.77}px !important;` : ""}
        ${fontColorMobile ? `border: 2px solid ${fontColorMobile} !important;` : ''}
        ${fontColorMobile ? `background-color: ${fontColorMobile}14 !important;` : ''}
        ${fontColorMobile ? `color: ${fontColorMobile} !important;` : ""}
        width: ${visualTypeFontSizeMobile * 1.5}px !important;
        height: ${visualTypeFontSizeMobile * 1.5}px !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-visual-type {
        ${visualTypeFontSizeMobile ? `font-size: ${visualTypeFontSizeMobile}px;` : ""}
        ${visualTypeFontSizeMobile ? `line-height: ${visualTypeFontSizeMobile * 1.3}px;` : ""}
        ${fontColorMobile ? `color: ${fontColorMobile} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-survey .cf-survey-choice[data-id="${field_option.id}"] .cf-survey-choice-image-container {
        ${optionsImageWidthMobile ? `width: ${optionsImageWidthMobile}px !important;` : ""}
        ${optionsImageHeightMobile ? `height: ${optionsImageHeightMobile}px;` : imageContainerHeightMobile ? `height: ${imageContainerHeightMobile}px;` : ''}

        ${visualTypePlacementMobile != "left" ? 'margin: 10px;' : ''}
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default FieldOptionVisualChoice;
