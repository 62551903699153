import { useContext, useRef, useEffect } from "react";
import { RemixBuilderContext } from "./RemixBuilderContext";
import apiRequest from "../../utils/apiRequest";

const useRemix = () => {
  const [contextData, builder, setContextData] = useContext(RemixBuilderContext);
  const contextDataRef = useRef(contextData);

  useEffect(() => {
    contextDataRef.current = contextData;
  }, [contextData]);

  const Remix = {
    getSectionTemplates: async function (tagIds = null) {
      let endpoint = `/api/v1/section_templates`;
      if (tagIds) {
        endpoint += `?template_tag_ids=${tagIds}`;
      }

      const response = await apiRequest("GET", endpoint);

      if (response && response.json) {
        const parsedTemplates = response.json.map((template) => ({
          ...template,
          embedding: template.embedding ? JSON.parse(template.embedding) : null,
        }));

        return parsedTemplates;
      } else {
        return [];
      }
    },

    calculateEmbedding: async function (section, retries = 0) {
      const response = await apiRequest(
        "POST",
        `https://${contextData.python_service_url}/api/calculate_embedding`,
        {
          image: section.image_bytes,
          type: "bytes",
        },
        null,
        {
          Authorization: `Bearer ${contextData.python_service_auth_token}`,
        }
      );

      if (response.code !== 200 && retries < 3) {
        return this.calculateEmbedding(section, retries + 1);
      } else if (response.code == 200) {
        return response.json;
      }
    },

    calculateMatchingTemplates: async function (embedding, templates) {
      const response = await apiRequest(
        "POST",
        `https://${contextData.python_service_url}/api/calculate_matching_templates`,
        {
          embedding,
          templates,
        },
        null,
        {
          Authorization: `Bearer ${contextData.python_service_auth_token}`,
        }
      );

      if (response.code !== 500 && response.json) {
        const enrichedResponse = response.json.map((res) => {
          const template = templates.find((template) => template.id === res.id);

          return {
              ...res,
              ...template
          }
        })

        return enrichedResponse;
      } else {
        return [];
      }
    },

    detectLayoutSections: async function (imageURL) {
      const response = await apiRequest(
        "POST",
        `https://${contextData.python_service_url}/api/detect_layout_sections`,
        {
          image: imageURL,
          type: "url",
        },
        null,
        {
          Authorization: `Bearer ${contextData.python_service_auth_token}`,
        }
      );

      if (response && response.json) {
        return response.json;
      }
    },

    detectSectionTags: async function (section) {
      const response = await apiRequest(
        "POST",
        `/websites/${contextData.website.id}/sections/identify_tags`,
        {
          image: section.image_bytes,
        },
        null,
        {
          Accept: "text/javascript",
        }
      );

      if (response && response.json) {
        return { ...section, tags: response.json };
      }
    },

    sortableStart: (containerId, contextDataKey) => {
      const container = document.querySelector(`#${containerId}`);

      if (container) {
        const sortableOptions = {
          sort: false,
          group: { name: 'layout-sections' },
          animation: 150,
          draggable: '.section-selector-box',
          ghostClass: 'drop-zone',
          forceFallback: true,
          fallbackOnBody: true,
          fallbackTolerance: 5,
          fallbackClass: 'builder-sortable-fallback',
          scroll: true,
          scrollSensitivity: 100,
          scrollSpeed: 10,
          onUpdate: (event) => {
            Remix.sortableUpdate(event, contextDataKey);
          }
        };

        new Sortable(container, sortableOptions);
      }
    },

    sortableUpdate: (event, contextDataKey) => {
      const sections = Array.from(event.to.children);

      const newPositionsMap = sections.reduce((acc, section, index) => {
        acc[section.getAttribute('data-section-id')] = index + 1;
        return acc;
      }, {});

      const currentSections = contextDataRef.current[contextDataKey];

      const updatedSections = currentSections.map(section => ({
        ...section,
        position: newPositionsMap[section.id] || section.position
      }));

      setContextData({...contextData, matched_sections: updatedSections});
    },

    createCTA: async function () {
      let payload = {
        settings: {
          ...contextData.remix_settings,
        }
      }

      payload['section_templates'] = contextData.matched_sections.map((section) => {
        return {
          position: section.position,
          template_id: section.templates[0].id,
        }
      });

      const response = await apiRequest("POST", `/websites/${contextData.website.id}/remix/cta`, payload);

      if (response && response.json) {
        return response.json;
      } else {
        return null;
      }
    },
  };

  return { Remix };
};

export default useRemix;
