import safelyRequestIdleCallback from "../../utils/safelyRequestIdleCallback";

const sortElements = {
  start: (section, column) => {
    let sortable_options;

    let container = document.querySelector(
      '#builder .cf-section[section-id="' +
        section.id +
        '"] .cf-cta-item-container[data-section-id="' +
        section.id +
        '"][data-section-column="' +
        column.position +
        '"]'
    );

    if (window.selected_object_editing !== true) {
      let forceFallback = environment == "test" ? false : true;

      sortable_options = {
        sort: true,
        group: { name: "elements" },
        draggable: ".cf-cta-item",
        ghostClass: "drop-zone",
        chosenClass: "editor-select",
        filter: ".editing, .element-spacing-wrapper, .section-spacing-wrapper, .section-column-spacing-handle, .section-column-spacing-wrapper, .section-column-spacing-handle",
        forceFallback: forceFallback,
        fallbackOnBody: true,
        fallbackClass: "builder-sortable-fallback",
        fallbackTolerance: 5,
        animation: 0,
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        preventOnFilter: false,
        onStart: function (event) {
          event.item.dragging = true;
          $('#builder').addClass('dragging');
        },
        onEnd: function (event) {
          $('#builder').removeClass('dragging');
        },
        onUpdate: function (event) {
          // #new_element is the nav element creation. It's from there when creating an element using the drag-n-drop
          if (
            event.from.id == "new-element" ||
            event.from.id == "new-element-templates" ||
            event.from.id == "create-from-scratch"
          )
            return;

          safelyRequestIdleCallback(function () {
            sortElements.update(event.to, true, section);
          });
        },
        onAdd: function (event) {
          // #new_element is the nav element creation. It's from there when creating an element using the drag-n-drop
          if (
            event.from.id == "new-element" ||
            event.from.id == "new-element-templates" ||
            event.from.id == "create-from-scratch"
          )
            return;

          safelyRequestIdleCallback(function () {
            let dragged_element_id = event.item.getAttribute("data-element-id");
            let dragged_element_type = event.item.getAttribute("data-type");

            if (dragged_element_type !== "row" && dragged_element_id) {
              dispatchCustomEvent("selectObject", {
                object_type: "elements",
                object_id: dragged_element_id,
              });
            }

            sortElements.add(event, section);
          });
        },
      };
      let sortable = new Sortable(container, sortable_options);
      sortable.section_id = section.id;
      sortable.column = column.position;
      window.sortables = [sortable];
    }
  },

  update: (container, setContext, section) => {
    let updates = [];

    Array.from(container.children)
      .filter((div) => div.className.indexOf("disabled-item") == -1)
      .forEach((element_div, i) => {
        let element_id = element_div.getAttribute("data-element-id");

        if (element_id) {
          let new_position = i + 1;
          let new_column = parseInt(element_div.getAttribute("sortable-id"));

          updates.push({
            object_type: "elements",
            object_id: element_id,
            setting_name: "[position]",
            value: new_position,
          });
        }

      });

    if (setContext == true) {
      dispatchCustomEvent("updateBuilder", { updates: updates });
    } else {
      return updates;
    }
  },

  add: (event, section) => {
    if (event.item.getAttribute("data-element-id")) {
      let updates = sortElements.update(event.from, false, section);

      // update positions of elements in sortable that item is being dragged to
      Array.from(event.to.children)
        .filter((div) => div.className.indexOf("disabled-item") == -1)
        .forEach((element_div, i) => {
          let element_id = parseInt(
            element_div.getAttribute("data-element-id")
          );
          let new_position = i + 1;

          if (element_id) {
            updates.push({
              object_type: "elements",
              object_id: element_id,
              setting_name: "[position]",
              value: new_position,
            });
          }
        });

      let nested_section_ids = [];
      function getNestedSectionIds(element) {
        let element_section_id = element.getAttribute("section-id");

        if (
          element_section_id !== null &&
          element_section_id.length > 0 &&
          !nested_section_ids.includes(element_section_id)
        ) {
          nested_section_ids.push(element_section_id);
        }

        Array.from(element.children).forEach(function (e) {
          getNestedSectionIds(e);
        });
      }

      function updateItemColumn(element_div) {
        const element_id = parseInt(
          element_div.getAttribute("data-element-id")
        );
        const element_type = element_div.getAttribute("data-type");
        const old_section_id = parseInt(
          element_div.getAttribute("data-section-id")
        );
        const new_section_id = parseInt(
          event.to.getAttribute("data-section-id")
        );
        const column = parseInt(event.to.getAttribute("data-sortable-id"));

        updates.push({
          object_type: "elements",
          object_id: element_id,
          setting_name: "[column]",
          value: column,
        });

        updates.push({
          object_type: "elements",
          object_id: element_id,
          setting_name: "[section_id]",
          value: new_section_id,
        });

        updates.push({
          object_type: "sections",
          object_id: new_section_id,
          setting_name: "[lastUpdated]",
          value: Date.now(),
          old_value: Date.now()
        });

        const content_toggle_elements = ["carousel", "tabs", "collapse", "conditional_row"];
        if (
          element_type == "row" ||
          content_toggle_elements.includes(element_type)
        ) {
          const row_section_divs = element_div.querySelectorAll(
            `.cf-section[data-element-id="${element_id}"]`
          );
          if (row_section_divs) {
            row_section_divs.forEach((row_section_div) => {
              const row_section_id =
                row_section_div.getAttribute("data-section-id");
              const old_step_id = row_section_div.getAttribute("data-step-id");
              const section_step_id = section.step_id;

              if (old_section_id !== section_step_id) {
                let element = $("#section" + row_section_id)[0];
                getNestedSectionIds(element);
                let filtered_nested_section_ids = nested_section_ids.filter(
                  (id) => id !== row_section_id.toString()
                );

                filtered_nested_section_ids.forEach(function (section_id) {
                  updates.push({
                    object_type: "sections",
                    object_id: section_id,
                    setting_name: "[step_id]",
                    value: section_step_id,
                    old_value: old_step_id,
                  });
                });

                updates.push({
                  object_type: "sections",
                  object_id: row_section_id,
                  setting_name: "[step_id]",
                  value: section_step_id,
                  old_value: old_step_id,
                });
              }
            });
          }
        }

        event.from.appendChild(element_div);
      }
      if (event.to !== event.from) {
        updateItemColumn(event.item);
      }

      dispatchCustomEvent("updateBuilder", { updates: updates });
    }
  },

  sortableLayersStart: (section, column) => {
    let sortable_options;

    const container = document.querySelector(
      `#layers .layers-container[data-object-type='sections'][data-object-id="${section.id}"][data-column="${column.position}"]`
    );

    sortable_options = {
      sort: true,
      group: { name: "layers-elements" },
      draggable: `.layers-item[data-object-type='elements'], .layers-item[data-object-type='sections'][data-element-type='row']`,
      ghostClass: "drop-zone",
      handle: `.layers-item-icon[data-object-type='elements'], .layers-item-label[data-object-type='elements'], .layers-item-icon[data-object-type='sections'], .layers-item-label[data-object-type='sections']`,
      forceFallback: true,
      fallbackOnBody: true,
      fallbackTolerance: 5,
      fallbackClass: "hide-during-drag",
      animation: 0,
      scroll: true,
      scrollSensitivity: 100,
      scrollSpeed: 10,
      preventOnFilter: false,
      onUpdate: function (event) {
        sortElements.sortableLayersUpdate(event.to, true, section);
      },
      onAdd: function (event) {
        sortElements.sortableLayersAdd(event, section);
      },
    };
    let sortable = new Sortable(container, sortable_options);
  },

  sortableLayersUpdate: (container, setContext, section) => {
    let updates = [];

    Array.from(container.children).forEach((element_div, i) => {
      let element_id = element_div.getAttribute("data-wrapper-object-id") || element_div.getAttribute("data-object-id");
      let new_position = i + 1;

      updates.push({
        object_type: "elements",
        object_id: element_id,
        setting_name: "[position]",
        value: new_position,
      });
    });

    if (setContext == true) {
      dispatchCustomEvent("updateBuilder", { updates: updates });
    } else {
      return updates;
    }
  },

  sortableLayersAdd: (event, section) => {
    let updates = sortElements.update(event.from, false, section);

    // update positions of elements in sortable that item is being dragged to
    Array.from(event.to.children).forEach((element_div, i) => {
      let element_id = parseInt(element_div.getAttribute("data-wrapper-object-id") || element_div.getAttribute("data-object-id"));
      let new_position = i + 1;

      updates.push({
        object_type: "elements",
        object_id: element_id,
        setting_name: "[position]",
        value: new_position,
      });
    });

    let nested_section_ids = [];
    function getNestedSectionIds(element) {
      let element_section_id = element.getAttribute("section-id");

      if (
        element_section_id !== null &&
        element_section_id.length > 0 &&
        !nested_section_ids.includes(element_section_id)
      ) {
        nested_section_ids.push(element_section_id);
      }

      Array.from(element.children).forEach(function (e) {
        getNestedSectionIds(e);
      });
    }

    function updateItemColumn(element_div) {
      const element_id = parseInt(element_div.getAttribute("data-wrapper-object-id") || element_div.getAttribute("data-object-id"));
      const element_type = element_div.getAttribute("data-element-type");
      const old_section_id = parseInt(
        event.from.getAttribute("data-object-id")
      );
      const new_section_id = parseInt(event.to.getAttribute("data-object-id"));
      const column = parseInt(event.to.getAttribute("data-column"));

      updates.push({
        object_type: "elements",
        object_id: element_id,
        setting_name: "[column]",
        value: column,
      });

      updates.push({
        object_type: "elements",
        object_id: element_id,
        setting_name: "[section_id]",
        value: new_section_id,
      });

      updates.push({
        object_type: "sections",
        object_id: new_section_id,
        setting_name: "[lastUpdated]",
        value: Date.now(),
      });

      const content_toggle_elements = ["carousel", "tabs", "collapse"];
      if (
        element_type == "row" ||
        content_toggle_elements.includes(element_type)
      ) {
        const row_section_divs = document.querySelectorAll(
          `#builder .cf-section[data-element-id="${element_id}"]`
        );
        if (row_section_divs) {
          row_section_divs.forEach((row_section_div) => {
            const row_section_id =
              row_section_div.getAttribute("data-section-id");
            const old_step_id = row_section_div.getAttribute("data-step-id");
            const section_step_id = section.step_id;

            if (old_section_id !== section_step_id) {
              let element = $("#section" + row_section_id)[0];
              getNestedSectionIds(element);
              let filtered_nested_section_ids = nested_section_ids.filter(
                (id) => id !== row_section_id.toString()
              );

              filtered_nested_section_ids.forEach(function (section_id) {
                updates.push({
                  object_type: "sections",
                  object_id: section_id,
                  setting_name: "[step_id]",
                  value: section_step_id,
                  old_value: old_step_id,
                });
              });

              updates.push({
                object_type: "sections",
                object_id: row_section_id,
                setting_name: "[step_id]",
                value: section_step_id,
                old_value: old_step_id,
              });
            }
          });
        }
      }

      event.from.appendChild(element_div);
    }
    if (event.to !== event.from) {
      updateItemColumn(event.item);
    }

    dispatchCustomEvent("updateBuilder", { updates: updates });
  },
};

export default sortElements;
