import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsButtonDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  function previewButtonHover(updated_element, new_value, styleToPreview) {
    let current_button_color = updated_element.options["button-color"];
    let current_border_color = updated_element.options["button-border-color"]
      ? updated_element.options["button-border-color"]
      : updated_element.options["button-color"];
    let current_text_color = updated_element.options["button-text-color"]
      ? updated_element.options["button-text-color"]
      : "#fff";

    let new_button_color =
      styleToPreview == "button–hover-color"
        ? new_value
        : updated_element.options["button–hover-color"]
        ? updated_element.options["button–hover-color"]
        : current_button_color;

    let new_border_color =
      styleToPreview == "button-hover-border-color"
        ? new_value
        : updated_element.options["button-hover-border-color"]
        ? updated_element.options["button-hover-border-color"]
        : current_border_color;

    let new_text_color =
      styleToPreview == "button–hover-text-color"
        ? new_value
        : updated_element.options["button–hover-text-color"]
        ? updated_element.options["button–hover-text-color"]
        : current_text_color;

    let button_div = document.querySelector(
      '#builder .cf-element[data-element-id="' +
        updated_element.id +
        '"] .cf-button, #builder .cf-element[data-element-id="' +
        updated_element.id +
        '"] .cf-survey-choice'
    );

    button_div.style.setProperty("background", new_button_color, "important");
    button_div.style.setProperty("border-color", new_border_color, "important");
    button_div.style.setProperty("color", new_text_color, "important");

    setTimeout(function () {
      button_div.style.removeProperty("background");
      button_div.style.removeProperty("border-color");
      button_div.style.removeProperty("color");
    }, 1500);
  }

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Button`} open={false}>
        <VariantBuilderSetting
          object={element}
          label={
            element.element_type == "survey"
              ? "Option Height"
              : "Button Height"
          }
          setting_name="[options][button-size]"
          setting_type="box_height"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-size"]
              ? element.options["button-size"]
              : "cf-button-regular"
          }
          valueMappings={{
            small: "cf-button-small",
            regular: "cf-button-regular",
            large: "cf-button-large",
            custom: "custom",
          }}
          condition={["form", "button", "survey", "products"].includes(
            element.element_type
          )}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-size-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Inner Vertical Padding"}
          value={
            element.options["button-size-value"]
              ? element.options["button-size-value"]
              : "0"
          }
          condition={
            element.options["button-size"] == "custom" &&
            ["form", "button", "survey", "products"].includes(
              element.element_type
            )
          }
          min={0}
          max={200}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Width"
          setting_name="[options][button-width]"
          setting_type="box_width"
          valueMappings={{
            auto: "cf-button-slim",
            regular: "cf-button-mid",
            full: "cf-full-width",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-width"]
              ? element.options["button-width"]
              : "cf-full-width"
          }
          condition={
            ["form", "button", "products"].includes(element.element_type) &&
            element.options["form-type"] !== "horizontal" &&
            (element.element_type !== "products" ||
              element.options["products-layout"] == "list")
          }
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Width"
          setting_name="[options][button-width-mobile]"
          setting_type="box_width"
          valueMappings={{
            auto: "cf-button-slim",
            regular: "cf-button-mid",
            full: "cf-full-width",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-width-mobile"]
              ? element.options["button-width-mobile"]
              : element.options["button-width"]
                ? element.options["button-width"]
                : undefined
          }
          condition={
            ["form", "button", "products"].includes(element.element_type) &&
            element.options["form-type"] !== "horizontal" &&
            (element.element_type !== "products" ||
              element.options["products-layout"] == "list")
          }
          device="mobile"
          show_reset={element.options["button-width-mobile"] ? true : false}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Custom Button Width"}
          value={
            element.options["button-width-value"]
              ? element.options["button-width-value"]
              : "0"
          }
          condition={
            element.options["button-width"] == "custom" &&
            ["form", "button", "products"].includes(element.element_type) &&
            element.options["form-type"] !== "horizontal"
          }
          min={0}
          max={2000}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
          device="desktop"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Custom Button Width"}
          value={
            element.options["button-width-value-mobile"]
              ? element.options["button-width-value-mobile"]
              : undefined
          }
          placeholder={
            element.options["button-width-value"]
          }
          condition={
            element.options["button-width-mobile"] == "custom" &&
            ["form", "button", "products"].includes(element.element_type) &&
            element.options["form-type"] !== "horizontal"
          }
          min={0}
          max={2000}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
          device="mobile"
        />

        <VariantBuilderSetting
          object={element}
          label="Button Alignment Within Form"
          setting_name="[options][button-alignment]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-alignment"]
              ? element.options["button-alignment"]
              : "cf-outer-center"
          }
          valueMappings={{
            left: "cf-outer-left",
            center: "cf-outer-center",
            right: "cf-outer-right",
          }}
          condition={
            element.element_type == "form" &&
            element.options["form-type"] !== "horizontal"
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Button Font"
          setting_name="[options][button-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font"]
              ? element.options["button-font"]
              : undefined
          }
          placeholder={
            variant.options["button-font"] ? variant.options["button-font"] : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={["button", "form", "products"].includes(
            element.element_type
          )}
          skip_history={true}
          callback={function (new_value, old_value) {
            var div = document.createElement("div");
            div.innerHTML = element.html;
            
            const p = div.querySelector("p");
            if (p) {
              p.style.removeProperty("font-family");
            }

            builder.update([
              {
                object_type: "elements",
                object_id: element.id,
                setting_name: "[options][button-font]",
                value: new_value,
                old_value: old_value,
              },
              {
                object_type: "elements",
                object_id: element.id,
                setting_name: "[html]",
                value: div.outerHTML,
              },
            ]);
            ctas.builder.fonts.load_font_family(element.options["button-font"]);
          }}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font-weight"]
              ? element.options["button-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          condition={["button", "form", "products"].includes(
            element.element_type
          )}
        />

        <VariantBuilderSetting
          object={element}
          label="Button font size"
          setting_name="[options][button-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font-size"]
              ? element.options["button-font-size"]
              : undefined
          }
          placeholder={
            variant.options["button-font-size"]
              ? variant.options["button-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          condition={["button", "form", "products"].includes(
            element.element_type
          )}
          allow_empty={true}
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="font-size"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("font-size");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Font size"
          setting_name="[options][button-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font-size-mobile"]
              ? element.options["button-font-size-mobile"]
              : element.options["button-font-size"]
              ? element.options["button-font-size"]
              : undefined
          }
          placeholder={
            (variant.options["button-font-size-mobile"]
              ? variant.options["button-font-size-mobile"]
              : variant.options["button-font-size"]) || 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          condition={["button", "form", "products"].includes(
            element.element_type
          )}
          allow_empty={true}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label={
              element.element_type == "survey"
                ? "Opt. text color"
                : "But. text color"
            }
            setting_name="[options][button-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-text-color"]
                ? element.options["button-text-color"]
                : undefined
            }
            placeholder={
              variant.options["button-text-color"]
                ? variant.options["button-text-color"]
                : "#fff"
            }
            condition={["form", "button", "survey", "products"].includes(
              element.element_type
            )}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button–hover-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button–hover-text-color"]
                ? element.options["button–hover-text-color"]
                : element.options["button-text-color"]
                ? element.options["button-text-color"]
                : undefined
            }
            placeholder={
              variant.options["button–hover-text-color"]
                ? variant.options["button–hover-text-color"]
                : variant.options["button-text-color"]
            }
            condition={["form", "button", "survey", "products"].includes(
              element.element_type
            )}
            callback={(new_value) => {
              previewButtonHover(
                builder.selected().object,
                new_value,
                "button–hover-text-color"
              );
            }}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label={
              element.element_type == "survey" ? "Option color" : "Button color"
            }
            setting_name="[options][button-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-color"]
                ? element.options["button-color"]
                : undefined
            }
            placeholder={
              variant.options["button-color"]
                ? variant.options["button-color"]
                : "#0072f7"
            }
            condition={["form", "button", "survey", "products"].includes(
              element.element_type
            )}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button–hover-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button–hover-color"]
                ? element.options["button–hover-color"]
                : undefined
            }
            placeholder={
              element.options["button-color"]
                ? element.options["button-color"] 
                : variant.options["button–hover-color"]
                ? variant.options["button–hover-color"]
                : variant.options["button-color"]
            }
            condition={["form", "button", "survey", "products"].includes(
              element.element_type
            )}
            callback={(new_value) => {
              previewButtonHover(
                builder.selected().object,
                new_value,
                "button–hover-color"
              );
            }}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label={
            element.element_type == "survey"
              ? "Option Border Style"
              : "Button Border Style"
          }
          setting_name="[options][button-border-style]"
          setting_type="border_style"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-border-style"]
              ? element.options["button-border-style"]
              : undefined
          }
          condition={["form", "button", "survey", "products"].includes(
            element.element_type
          )}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Border Color"
            setting_name="[options][button-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-border-color"]
                ? element.options["button-border-color"]
                : element.options["button-color"]
                ? element.options["button-color"]
                : undefined
            }
            placeholder={variant.options["button-border-color"]}
            condition={
              ["form", "button", "survey", "products"].includes(
                element.element_type
              ) &&
              element.options["button-border-style"] &&
              element.options["button-border-style"] !== "" &&
              element.options["button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button-hover-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-hover-border-color"]
                ? element.options["button-hover-border-color"]
                : undefined
            }
            placeholder={variant.options["button-hover-border-color"] ? variant.options["button-hover-border-color"] : element.options["button-border-color"]
            ? element.options["button-border-color"]
            : undefined}
            condition={
              ["form", "button", "survey", "products"].includes(
                element.element_type
              ) &&
              element.options["button-border-style"] &&
              element.options["button-border-style"] !== "" &&
              element.options["button-border-style"] !== "none"
            }
            callback={(new_value) => {
              previewButtonHover(
                builder.selected().object,
                new_value,
                "button-hover-border-color"
              );
            }}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        {["form", "button", "survey", "products"].includes(
          element.element_type
        ) &&
          element.options["button-border-style"] &&
          element.options["button-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Button Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][button-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[`button-border-width-${side}`]
                          ) == false
                            ? element.options[`button-border-width-${side}`]
                            : element.options["button-border-width"]
                            ? element.options["button-border-width"].replace(
                                "px",
                                ""
                              )
                            : undefined
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label={
            element.element_type == "survey"
              ? "Option Corner Radius"
              : "Button Corner Radius"
          }
          setting_name="[options][button-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-radius"]
              ? element.options["button-radius"]
              : undefined
          }
          condition={["form", "button", "survey", "products"].includes(
            element.element_type
          )}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`button-radius-${side}`]) == false
                      ? element.options[`button-radius-${side}`]
                      : undefined
                  }
                  condition={
                    ["form", "button", "survey", "products"].includes(
                      element.element_type
                    ) && element.options["button-radius"] == "custom"
                  }
                  min={0}
                  col="6"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={element}
          label={
            element.element_type == "survey"
              ? "Option Button Shadow"
              : "Button Shadow"
          }
          setting_name="[options][button-shadow]"
          setting_type="shadow"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-shadow"]
              ? element.options["button-shadow"]
              : undefined
          }
          condition={["form", "button", "survey", "products"].includes(
            element.element_type
          )}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["button-shadow-horizontal-distance"]
              ? element.options["button-shadow-horizontal-distance"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["button-shadow-vertical-distance"]
              ? element.options["button-shadow-vertical-distance"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["button-shadow-blur-radius"]
              ? element.options["button-shadow-blur-radius"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["button-shadow-spread-radius"]
              ? element.options["button-shadow-spread-radius"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Shadow Color"
            setting_name="[options][button-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-shadow-color"]
                ? element.options["button-shadow-color"]
                : undefined
            }
            condition={element.options["button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button-hover-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-hover-shadow-color"]
                ? element.options["button-hover-shadow-color"]
                : undefined
            }
            placeholder={variant.options["button-shadow-color"] ? variant.options["button-shadow-color"] : element.options["button-shadow-color"]
            ? element.options["button-shadow-color"]
            : undefined }
            condition={element.options["button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label="Inset shadow position"
          setting_name="[options][button-shadow-inset]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-shadow-inset"]
              ? element.options["button-shadow-inset"]
              : "false"
          }
          condition={element.options["button-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsButtonDesign;
