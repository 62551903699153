import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const useFieldOptions = () => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;

  const FieldOptions = {
    create: async function (field, payload, options) {
      let position =
        Object.values(variant.field_options).filter(
          (option) => option.field_id == field.id && option.toBeDeleted !== true
        ).length + 1;

      builder.create(
        "field_options",
        {
          position: position,
          field_id: field.id,
          option_type: field.field_type == "survey" ? "choice" : undefined,
          ...payload,
        },
        options
      );
    },

    duplicate: async function (og_field_option, payload, skip_history) {
      let attributes = {
        label: og_field_option.label,
        value: og_field_option.value,
        image: og_field_option.image,
        position: og_field_option.position,
        option_type: og_field_option.option_type,
        score: og_field_option.score,
        visual_type: og_field_option.visual_type,
        icon: og_field_option.icon,
        emoji: og_field_option.emoji,
        letter: og_field_option.letter,
        color: og_field_option.color,
        step_id: og_field_option.step_id,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      return new Promise((resolve, reject) => {
        builder.create("field_options", attributes, {
          callback: async function (field_option) {
            resolve(field_option);
          },
          skip_history: skip_history,
          skip_select: true,
        });
      });
    },

    remove: (field_option) => {
      let message =
        "Are you sure you want to remove this field option? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove("field_options", field_option.id, message);
    },

    sortableStart: (field, selector = undefined) => {
      let containerSelector = `.sortable-container[data-sortable-name="field-options"][data-object_type="fields"][data-object_id="${field.id}"]`;
      if (selector) {
        containerSelector += selector;
      }

      let container = document.querySelector(containerSelector);

      let item_selector =
        containerSelector +
        " .setting[data-setting_type='item_v2'][data-object_type='field_options']";

      let sortable_options = {
        sort: true,
        group: { name: "field-option-position" },
        animation: 0,
        draggable: item_selector,
        ghostClass: "drop-zone",
        handle: ".cf-item-v2[data-object_type='field_options']",
        scroll: true,
        scrollSensitivity: 100,
        forceFallback: true,
        fallbackTolerance: 5,
        scrollSpeed: 10,
        onUpdate: function (event) {
          FieldOptions.sortableUpdate(event, field);
        },
      };

      if (container) {
        new Sortable(container, sortable_options);
      }
    },

    sortableUpdate: (event, field) => {
      let updates = [];

      Array.from(event.to.children).forEach((field_option_div, i) => {
        let new_position = i + 1;
        let object_id = field_option_div.getAttribute("data-object_id");
        if (object_id) {
          let field_option_id = parseInt(object_id);

          updates.push({
            object_type: "field_options",
            object_id: field_option_id,
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },
  };

  let currentHook = FieldOptions;
  return { FieldOptions, currentHook };
};

export default useFieldOptions;
