import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsVideoDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Video`} open={false}>
        <VariantBuilderSetting
          object={section}
          setting_name="[options][video-embed-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Video Max Width"}
          value={
            element.options["video-embed-width-value"]
              ? element.options["video-embed-width-value"]
              : "300"
          }
          condition={
            ["video"].includes(element.element_type) &&
            element.options["video-embed-width"] == "custom"
          }
          min={0}
          max={2560}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][video-embed-height-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Video Height"}
          value={element.options["video-embed-height-value"]}
          placeholder={"Auto"}
          min={0}
          max={2560}
          allow_empty={true}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][video-embed-height-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Video Height"}
          value={element.options["video-embed-height-value-mobile"]}
          placeholder={element.options["video-embed-height-value"] || "Auto"}
          min={0}
          max={2560}
          allow_empty={true}
          range_step={"1"}
          device="mobile"
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsVideoDesign;
