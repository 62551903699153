import React, { useContext, useState, useRef } from "react";

import { RemixBuilderContext } from "./RemixBuilderContext";
import useRemix from "./useRemix";

const RemixBuilderSettingsSections = () => {
  const { Remix } = useRemix();

  const [contextData, builder, setContextData] = useContext(RemixBuilderContext);

  const [loadingTemplateMatches, setLoadingTemplateMatches] = useState(false);

  const sectionsSettingsDiv = useRef(null);

  const SectionTagButton = (props) => {
    const section = props.section;
    const tag = props.tag;

    return (
      <span
        className="bg-white py-[1px] px-[8px] border-solid border-1 border-slate-300 rounded-xl flex items-center cursor-pointer"
      >
        {tag.name}
        <svg
          onClick={() => builder.removeSectionTag(section, tag.id)}
          width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6654 5.33325L5.33203 10.6666M10.6654 10.6666L5.33203 5.33325" stroke="black" strokeWidth="2" strokeLinecap="round"></path>
        </svg>
      </span>
    )
  };

  const getTemplatesWithTagPriority = async (section) => {
    try {
      const goalTags = section.tags?.goal?.map((tag) => tag.id) || [];
      const industryTags = section.tags?.industry?.map((tag) => tag.id) || [];
      const otherTags = section.tags?.other?.map((tag) => tag.id) || [];

      const tagCombinations = [
        [...goalTags, ...industryTags, ...otherTags],
        [...goalTags, ...otherTags],
        [...otherTags],
        null,
      ];

      for (const tags of tagCombinations) {
        const templates = await Remix.getSectionTemplates(tags);

        if (templates?.length > 0) {
          const filteredTemplates = templates.filter(
            (template) => template.embedding !== null
          );

          if (filteredTemplates.length > 0) {
            return filteredTemplates;
          }
        }
      }

      return [];
    } catch (error) {
      return [];
    }
  };

  const handleNextStep = async () => {
    if (loadingTemplateMatches === true) return;

    let updatedBuilder = { ...contextData };
    setLoadingTemplateMatches(true);

    const sortedSelectedSections = contextData.uploaded_sections
      ?.filter((section) => section.selected)
      ?.sort((a, b) => a.position - b.position)

    for (const section of sortedSelectedSections) {
      if (section.templates?.length > 0) {
        console.log("already calculated for section", section);
        continue;
      }

      const loadingBox = sectionsSettingsDiv.current.querySelector(
        `.section-selector-box[data-section-id="${section.id}"] .loading-box`
      );
      if (loadingBox) loadingBox.style.visibility = "visible";

      const filteredTemplates = await getTemplatesWithTagPriority(section);
      console.log('filteredTemplates', filteredTemplates);
      const sectionEmbedding = await Remix.calculateEmbedding(section);

      if (!sectionEmbedding) {
        builder.toggleSelectSection(section);
      } else {
        const matchingTemplates = await Remix.calculateMatchingTemplates(sectionEmbedding, filteredTemplates);

        updatedBuilder.uploaded_sections = updatedBuilder.uploaded_sections.map(
          (uploadedSection) => {
            if (uploadedSection.id === section.id) {
              return {
                ...uploadedSection,
                templates: matchingTemplates,
              };
            }

            return uploadedSection;
          }
        );

        updatedBuilder.matched_sections =
          updatedBuilder.uploaded_sections.filter((section) => section.selected && section.templates?.length > 0);
      }

      if (loadingBox) loadingBox.style.visibility = "hidden";
    }

    setLoadingTemplateMatches(false);
    builder.goToNextStep(updatedBuilder);
  };

  return (
    <React.Fragment>
      {contextData.uploaded_sections && (
        <>
          <div
            ref={sectionsSettingsDiv}
            className="remix-editor-settings-sections flex flex-column overflow-scroll grow-1"
          >
            <h2 className="text-[18px] font-medium">
              Choose sections to remix
            </h2>
            <p className="text-[12px] text-gray-400">
              Select sections for your generated template
            </p>

            <div className="border-solid border-1 border-slate-300 rounded-xl overflow-hidden flex flex-column">
              <div className="bg-slate-100 p-5 flex items-end gap-2">
                <label className="flex items-center gap-2 justify-center cursor-pointer relative">
                  <input
                    onChange={builder.toggleAllUploadedSections}
                    checked={contextData.uploaded_sections.every((section) => section.selected)}
                    type="checkbox"
                    className="w-[24px] h-[24px] border-2 border-solid border-slate-300 rounded-[50%]"
                  />
                  <span>Select all sections</span>
                </label>
              </div>

              <div className="flex flex-wrap gap-3 p-3 overflow-scroll">
                {contextData.uploaded_sections.map((section, index) => (
                  <div
                    className="flex gap-2 p-3 section-selector-box"
                    key={`section-selector-${index}`}
                    data-section-id={section.id}
                  >
                    <input
                      onChange={() => builder.toggleSelectSection(section)}
                      checked={section.selected}
                      type="checkbox"
                      className="w-[24px] h-[24px] border-2 border-solid border-slate-300 rounded-[50%]"
                    />
                    <div
                      className="relative border-solid border-1 border-slate-300 rounded-xl overflow-hidden flex justify-center items-center min-h-[40px]"
                      key={`section-${index}`}
                    >
                      <div className="z-[1] invisible loading-box absolute flex w-full h-full items-center justify-center bg-black opacity-[0.7]">
                        <span className="cf-loading-spinner" />
                      </div>

                      {section.tags && (
                        <div className="absolute m-[5px] top-1 right-2 flex flex-wrap gap-2 text-lg items-center justify-end">
                          {section.tags?.goal?.slice(0, 5)?.map((tag, index) => (
                            <SectionTagButton key={`section-goal-${index}-${tag?.id}`} section={section} tag={tag} />
                          ))}
                          {section.tags?.industry?.slice(0, 5)?.map((tag, index) => (
                            <SectionTagButton key={`section-industry-${index}-${tag?.id}`} section={section} tag={tag} />
                          ))}
                          {section.tags?.other?.slice(0, 5)?.map((tag, index) => (
                            <SectionTagButton key={`section-other-${index}-${tag?.id}`} section={section} tag={tag} />
                          ))}
                        </div>
                      )}

                      <img
                        className="w-full h-full object-cover"
                        src={`data:image/png;base64,${section.image_bytes}`}
                        alt={`Section ${index}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="pt-[30px] gap-4 flex justify-between">
            <div
              onClick={handleNextStep}
              className={`cursor-pointer rounded-xl w-full p-[10px] bg-slate-800 flex justify-center items-center text-white gap-2 text-lg ${
                loadingTemplateMatches ? "disabled" : ""
              }`}
            >
              Next
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default RemixBuilderSettingsSections;
