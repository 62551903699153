import React, { useContext, useEffect, useRef, useState } from "react";

import RemixUploader from "./RemixUploader";
import RemixBuilderSettingsSections from "./RemixBuilderSettingsSections";
import RemixBuilderSettingsLayouts from "./RemixBuilderSettingsLayouts";
import RemixBuilderSettingsTheme from "./RemixBuilderSettingsTheme";
import RemixBuilderSettingsBreadcrumb from "./RemixBuilderSettingsBreadcrumb";
import RemixBuilderPreview from "./RemixBuilderPreview";

import { RemixBuilderContext } from "./RemixBuilderContext";
import useRemix from "./useRemix";

const CtaRemixBuilder = () => {
  const { Remix } = useRemix();

  const [contextData, builder, setContextData] = useContext(RemixBuilderContext);

  const [modalVisibility, setModalVisibility] = useState(false);
  const remixModal = useRef(null);

  useEffect(() => {
    const fetchSectionTemplates = async () => {
      const templates = await Remix.getSectionTemplates();
      console.log('templates', templates);

      setContextData({
        ...contextData,
        section_templates: templates,
      });
    };

    fetchSectionTemplates();
  }, []);

  useEffect(() => {
    if (remixModal.current && modalVisibility == true) {
      if (!window.builder_fonts_dropdown) {
        ctas?.builder?.fonts?.initialize();
      }

      $(remixModal.current).modal("show");
      $(remixModal.current).addClass("in").css("display", "block");
      $("body").addClass("modal-open");
      $(remixModal.current).parents(".modal").scrollTop(0);
    } else if (remixModal.current) {
      $(remixModal.current).removeClass("in").css("display", "none");
    }
  });

  return (
    <div className="remix-editor">
      <RemixUploader
        remixModal={remixModal}
        onSubmit={(sections, uploadUrl) => {
          let updatedBuilder = { ...contextData };

          if (!sections && updatedBuilder.uploaded_sections && updatedBuilder.uploaded_sections?.length > 0) {
            sections = updatedBuilder.uploaded_sections;
          }
          updatedBuilder.uploaded_sections = sections;

          const limitToUse = updatedBuilder.settings.max_section_remixes - updatedBuilder.settings.used_section_remixes;
          const maxSelectedSections = sections.slice(0, limitToUse);
          updatedBuilder.uploaded_sections = updatedBuilder.uploaded_sections.map((section) => {
            return {
              ...section,
              selected: maxSelectedSections.some((selectedSection) => selectedSection.id === section.id),
            };
          });
          updatedBuilder.current_step = updatedBuilder.current_step || 1;
          updatedBuilder.uploaded_image_url = uploadUrl;

          setContextData(updatedBuilder);
          setModalVisibility(true);
        }}
      />

      {contextData.uploaded_sections && (
        <div
          ref={remixModal}
          className="modal"
          id="remix-uploader"
          role="dialog"
          aria-labelledby="myModalLabel"
          data-backdrop="false"
          style={{
            background: "rgb(0, 0, 0, 0.5)",
            overflowY: "auto",
          }}
        >
          <div
            className="modal-dialog !w-[100%] !h-[100%] ml-auto mr-auto"
            role="document"
          >
            <div
              className="modal-content hard-center bg-white dark:bg-slate-950 !shadow-2xl max-h-[95vh] flex flex-column"
            >
              <div className="modal-header !border-slate-200 dark:!border-slate-800">
                <button
                  type="button"
                  className="close dark:text-white"
                  onClick={() => {
                    setModalVisibility(false);
                  }}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M12.2996 4.1001L4.09961 12.3001M12.2996 12.3001L4.09961 4.1001"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>

                <h4
                  className="text-black dark:text-white whitespace-nowrap text-ellipsis overflow-hidden font-medium m-0 text-4xl"
                >
                  Generate a template
                </h4>
              </div>

              <div className="modal-body flex h-[85vh]">
                <div className="flex gap-6 mt-5 px-7 flex-1">
                  <div className="basis-3-4 flex flex-column justify-between">
                    <RemixBuilderPreview />
                  </div>

                  <div className="basis-1-4 flex flex-column justify-between">
                    <RemixBuilderSettingsBreadcrumb />

                    {contextData.current_step === 1 && <RemixBuilderSettingsSections />}
                    {contextData.current_step === 2 && <RemixBuilderSettingsLayouts />}
                    {contextData.current_step === 3 && <RemixBuilderSettingsTheme />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CtaRemixBuilder;
