import React, { Suspense, lazy } from "react";

const withLazyLoad = (component, fallback = null) => {
  const LazyComponent = lazy(component);

  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default withLazyLoad;
