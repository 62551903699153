import React, { useContext, useEffect  } from "react";

import { RemixBuilderContext } from "./RemixBuilderContext";
import RemixTemplatesModal from "./RemixTemplatesModal";
import useRemix from "./useRemix";

const RemixBuilderSettingsLayouts = () => {
  const [contextData, builder] = useContext(RemixBuilderContext);
  const { Remix } = useRemix();

  useEffect(() => {
    Remix.sortableStart("remix-matched-sections-templates", "matched_sections");
  });

  return (
    <React.Fragment>
      {contextData.matched_sections && (
        <>
          <div className="remix-editor-settings-layouts flex flex-column overflow-scroll grow-1">
            <h2 className="text-[18px] font-medium">
              Approve section templates
            </h2>
            <p className="text-[12px] text-gray-400">
              We recommend these section templates
            </p>

            <div id="remix-matched-sections-templates" className="flex flex-wrap gap-3 overflow-scroll mt-5">
              {contextData.matched_sections.map((section, index) => (
                <div
                  className="matched-section-template flex gap-2 section-selector-box text-2xl items-center justify-around w-full pb-5 cursor-move"
                  key={`section-selector-${index}`}
                  data-section-id={section.id}
                  data-position={section.position}
                >
                  <input
                    onChange={() => builder.toggleSelectSection(section, "matched_sections")}
                    checked={contextData.matched_sections.find((matchedSection) => matchedSection.id === section.id)?.selected}
                    type="checkbox"
                    className="w-[20px] h-[20px] border-2 border-solid border-slate-300 rounded-[50%] cursor-pointer"
                  />

                  <div className="whitespace-nowrap text-ellipsis overflow-hidden text-[13px] flex-1" key={`section-${index}`}>
                    {`${section?.templates?.[0]?.name}`}
                  </div>

                  <RemixTemplatesModal
                    title="Change template"
                    templates={contextData.section_templates}
                    template={section?.templates?.[0]}
                    onSelectTemplate={(sectionTemplate) => builder.changeMatchedSectionTemplate(section, sectionTemplate)}
                  >
                    <span
                      className="bg-white py-[2px] px-[8px] border-solid border-1 border-slate-300 rounded-xl flex gap-1 items-center cursor-pointer text-xl"
                    >
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.59844 1.8999C6.59844 1.54092 6.30742 1.2499 5.94844 1.2499C5.58945 1.2499 5.29844 1.54092 5.29844 1.8999H6.59844ZM5.29844 13.0999C5.29844 13.4589 5.58945 13.7499 5.94844 13.7499C6.30742 13.7499 6.59844 13.4589 6.59844 13.0999H5.29844ZM3.49844 2.5499H10.4984V1.2499H3.49844V2.5499ZM11.9484 3.9999V10.9999H13.2484V3.9999H11.9484ZM10.4984 12.4499H3.49844V13.7499H10.4984V12.4499ZM2.04844 10.9999V3.9999H0.748438V10.9999H2.04844ZM3.49844 12.4499C2.69762 12.4499 2.04844 11.8007 2.04844 10.9999H0.748438C0.748438 12.5187 1.97965 13.7499 3.49844 13.7499V12.4499ZM11.9484 10.9999C11.9484 11.8007 11.2992 12.4499 10.4984 12.4499V13.7499C12.0172 13.7499 13.2484 12.5187 13.2484 10.9999H11.9484ZM10.4984 2.5499C11.2992 2.5499 11.9484 3.19909 11.9484 3.9999H13.2484C13.2484 2.48112 12.0172 1.2499 10.4984 1.2499V2.5499ZM3.49844 1.2499C1.97965 1.2499 0.748438 2.48112 0.748438 3.9999H2.04844C2.04844 3.19909 2.69762 2.5499 3.49844 2.5499V1.2499ZM5.29844 1.8999V13.0999H6.59844V1.8999H5.29844ZM5.94844 8.1499H12.2484V6.8499H5.94844V8.1499Z" fill="black"/>
                      </svg>

                      Change
                    </span>
                  </RemixTemplatesModal>

                  <div className="handler cursor-pointer">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.91797 8C7.22761 8 6.66797 7.44036 6.66797 6.75C6.66797 6.05964 7.22761 5.5 7.91797 5.5C8.60832 5.5 9.16797 6.05964 9.16797 6.75C9.16797 7.44036 8.60832 8 7.91797 8Z" fill="black"/>
                      <path d="M7.91797 11.75C7.22761 11.75 6.66797 11.1904 6.66797 10.5C6.66797 9.80964 7.22761 9.25 7.91797 9.25C8.60832 9.25 9.16797 9.80964 9.16797 10.5C9.16797 11.1904 8.60832 11.75 7.91797 11.75Z" fill="black"/>
                      <path d="M7.91797 15.5C7.22761 15.5 6.66797 14.9404 6.66797 14.25C6.66797 13.5596 7.22761 13 7.91797 13C8.60832 13 9.16797 13.5596 9.16797 14.25C9.16797 14.9404 8.60832 15.5 7.91797 15.5Z" fill="black"/>
                      <path d="M12.0859 8C11.3956 8 10.8359 7.44036 10.8359 6.75C10.8359 6.05964 11.3956 5.5 12.0859 5.5C12.7763 5.5 13.3359 6.05964 13.3359 6.75C13.3359 7.44036 12.7763 8 12.0859 8Z" fill="black"/>
                      <path d="M12.0859 11.75C11.3956 11.75 10.8359 11.1904 10.8359 10.5C10.8359 9.80964 11.3956 9.25 12.0859 9.25C12.7763 9.25 13.3359 9.80964 13.3359 10.5C13.3359 11.1904 12.7763 11.75 12.0859 11.75Z" fill="black"/>
                      <path d="M12.0859 15.5C11.3956 15.5 10.8359 14.9404 10.8359 14.25C10.8359 13.5596 11.3956 13 12.0859 13C12.7763 13 13.3359 13.5596 13.3359 14.25C13.3359 14.9404 12.7763 15.5 12.0859 15.5Z" fill="black"/>
                    </svg>
                  </div>
                </div>
              ))}
            </div>

            <RemixTemplatesModal
              title="Add a new section"
              templates={contextData.section_templates}
              onSelectTemplate={builder.addNewMatchedSection}
            >
              <span
                style={{ display: "block" }}
                className="bg-white mt-10 p-3 border-solid border-1 border-slate-300 rounded-xl text-center cursor-pointer text-xl"
              >
                Add Section
              </span>
            </RemixTemplatesModal>
          </div>

          <div className="pt-[30px] gap-4 flex justify-between">
            <div
              onClick={() => builder.goToPreviousStep()}
              className="cursor-pointer rounded-xl w-full p-[10px] flex justify-center items-center gap-2 text-lg border-solid border-1 border-slate-300"
            >
              Back
            </div>

            <div
              onClick={() => builder.goToNextStep()}
              className="cursor-pointer rounded-xl w-full p-[10px] bg-slate-800 flex justify-center items-center text-white gap-2 text-lg"
            >
              Next
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default RemixBuilderSettingsLayouts;
