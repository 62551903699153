var WebFont = require("webfontloader");

var fonts = {
  initialize: function (callback) {
    window.builder_fonts = {
      inherit: "Your site font",
      Helvetica: "Helvetica",
      "Georgia,serif": "Georgia",
    };

    window.builder_fonts_dropdown = [];

    if (window.websiteBrandFonts) {
      window.websiteBrandFonts.forEach((font) => {
        builder_fonts_dropdown.push(font);
      });
    }

    builder_fonts_dropdown.push({
      value: "inherit",
      text: "Site font (Inherit)",
      group: "System fonts",
    });
    builder_fonts_dropdown.push({
      value: "Helvetica",
      text: "Helvetica",
      group: "System fonts",
    });
    builder_fonts_dropdown.push({
      value: "Georgia",
      text: "Georgia",
      group: "System fonts",
    });

    if (window.environment !== "test") {
      $.ajax({
        url:
          "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBDMEBPpm_XGZ8Ck5V5_8a33gBEt8kRr3E",
        method: "GET",
        dataType: "json",
        complete: function (data) {
          $.each(data.responseJSON.items, function (i, font) {
            let font_type = {
              serif: "serif",
              "sans-serif": "sans-serif",
              handwriting: "serif",
              display: "sans-serif",
            };
            let font_value = font.family + ", " + font_type[font.category];
            window.builder_fonts[font_value] = font.family;

            builder_fonts_dropdown.push({
              value: font.family,
              text: font.family,
              label: font.family,
              group: "Google fonts",
              css: font_value,
            });
          });

          ctas.builder.fonts.load_used_fonts();

          if (callback) {
            callback();
          }
        },
      });
    }
  },

  froala_dropdown: function () {
    let dropdown = $(
      ".fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content"
    );

    $.each($(dropdown).find("li").slice(0, 6), function (i, dropdown_option) {
      $(dropdown_option).attr("data-font-loaded", "true");
    });

    var dropdown_options = $(dropdown)
      .find("li")
      .filter('[data-font-loaded!="true"]');

    $.each(dropdown_options, function (i, dropdown_option) {
      $(dropdown_option)
        .off("mouseover")
        .on("mouseover", function () {
          var options_to_check = [this];
          options_to_check.push($(this).prev()[0]);
          options_to_check.push($(this).prev().prev()[0]);
          options_to_check.push($(this).prev().prev().prev()[0]);
          options_to_check.push($(this).next()[0]);
          options_to_check.push($(this).next().next()[0]);
          options_to_check.push($(this).next().next().next()[0]);

          $.each(options_to_check, function (i, option_to_check) {
            if ($(option_to_check).attr("data-font-loaded") !== "true") {
              $(option_to_check).attr("data-font-loaded", "true");
              var tag_title = $(option_to_check)
                .find("a.fr-command")
                .attr("title");

              if (tag_title) {
                var font_family = tag_title
                  .split(",")[0]
                  .replace(/['"]+/g, "")
                  .trim();
                ctas.builder.fonts.load_font_family(font_family);
              }
            }
          });
        });
    });
  },

  load_font_family: function (font_family, used) {
    window.builder_fonts_loaded = [];

    if (
      window.environment !== "test" &&
      $.inArray(font_family, [
        "Your site font",
        "Helvetica Neue",
        "Helvetica Nueue",
        "Helvetica",
        "Georgia",
        "system-ui",
        "sans-serif",
        "serif",
      ]) == -1 &&
      $.inArray(font_family, builder_fonts_loaded) == -1 &&
      Object.values(builder_fonts).filter(function (font) {
        return font == font_family;
      }).length > 0
    ) {
      let font_value = ctas.builder.fonts.get_font_value(font_family);
      if (
        used == true &&
        window.builder_fonts_dropdown.filter(
          (item) =>
            item.value.replace(", sans-serif", "") ==
              font_value.replace(", sans-serif", "") &&
            item.group == "System fonts"
        ).length == 0
      ) {
        window.builder_fonts_dropdown.splice(0, 0, {
          value: font_value,
          text: `${font_family} (In use)`,
          group: "System fonts",
        });
      }

      builder_fonts_loaded.push(font_family);
      WebFont.load({
        google: {
          families: [font_family, `${font_family}:700,900`],
        },
        classes: false,
        events: false,
      });
    }
  },

  load_used_fonts: function (selector) {
    console.warn('loading fonts!');

    if (!selector) {
      var selector = "#builder";
    }

    Object.values(builder_fonts_dropdown)
      .filter((font) => font.group == "System fonts")
      .forEach((font) => {
        ctas.builder.fonts.load_font_family(font.value, true);
      });

    $(selector)
      .find('[style*="font-family"]')
      .each(function (i, dom_element) {
        let font_family = $(dom_element)
          .css("font-family")
          .split(",")[0]
          .replace(/['"]+/g, "")
          .trim();
        ctas.builder.fonts.load_font_family(font_family, true);
      });

    $(selector)
      .find("[data-font]")
      .each(function (i, dom_element) {
        $(dom_element)
          .attr("data-font")
          .split(",")
          .forEach((font_family) => {
            var font_family = font_family.replace(/['"]+/g, "").trim();
            ctas.builder.fonts.load_font_family(font_family, true);
          });
      });
  },

  get_font_value: function (font_family) {
    return Object.keys(builder_fonts).filter(function (font_value) {
      return builder_fonts[font_value] == font_family;
    })[0];
  },
};

export default fonts;
