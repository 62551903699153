import React, { useContext } from "react";

import { RemixBuilderContext } from "./RemixBuilderContext";

const RemixBuilderSettingsBreadcrumb = (props) => {
  const [contextData] = useContext(RemixBuilderContext);

  const StepLabel = (props) => {
    return (
      <div
        data-step={props.step}
        className={`remix-step ${
          contextData.current_step == props.step ? "active" : ""
        }`}
      >
        {props.label}
      </div>
    );
  };

  return (
    <React.Fragment>
      {contextData.current_step > 0 && (
        <div className="remix-editor-settings-breadcrumb text-gray-400">
          <StepLabel step={1} label="Sections" />

          <svg
            width="18"
            height="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66667 4.66675L10 8.00008L6.66667 11.3334"
              stroke="#A5A5A5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <StepLabel step={2} label="Layouts" />

          <svg
            width="18"
            height="18"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66667 4.66675L10 8.00008L6.66667 11.3334"
              stroke="#A5A5A5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <StepLabel step={3} label="Theme" />
        </div>
      )}
    </React.Fragment>
  );
};

export default RemixBuilderSettingsBreadcrumb;
