import React, { useState, useContext, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSections from "../sections/useSections.js";
import sortElements from "../elements/sortElements.js";
import useContentToggle from "../content_toggles/useContentToggle.js";
import useFields from "../fields/useFields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import templateableElementTypesArray from "../../utils/templateableElementTypesArray";

const VariantBuilderPanelLayersItem = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [isOpen, setIsOpen] = useState(
    props.open !== undefined ? props.open : false
  );
  const [optionsIsOpen, setOptionsIsOpen] = useState(false);
  const node = useRef();
  const toggleRef = useRef();
  const { Sections } = useSections(
    props.object_type == "steps" ? props.object : null
  );
  const { ContentToggle } = useContentToggle(Sections);
  const { Fields } = useFields();

  const hasCaret =
    props.hasCaret !== false &&
    (["variants", "elements"].includes(props.object_type) == false ||
      (props.element &&
        ["row", "collapse", "carousel", "tabs", "quiz", "conditional_row"].includes(
          props.element.element_type
        )));

  const showOptionsCommands =
    props.object_type !== "variants" &&
    (props.object_type !== "sections" || !props.column);

  const object_type = props.object_type;
  const object_id = props.object_id;
  const fieldIsChild =
    props.object_type == "fields"
      ? props.object && props.object.field_id
        ? true
        : false
      : null;
  const canvasDiv = document.querySelector(`#builder ${props.canvasSelector}`);
  const canResetStyles = ["sections", "elements"].includes(props.object_type);

  useEffect(() => {
    const selected_object_layer = builder.selected_object_layer(
      contextData.selected_layer_object_type,
      contextData.selected_layer_object_id
    );
    const objectInLayer = selected_object_layer.find(obj => obj.id == props.object_id);
    let objectInColumn = true;

    if (props.object_type == "sections") {
      let sectionIndex = selected_object_layer.findIndex(obj => obj.id == props.object_id)
      let childrenColumn = selected_object_layer[sectionIndex + 1]?.column
      if (props.column && props.column.position != childrenColumn) objectInColumn = false;
    }

    if (objectInLayer && objectInColumn) {
      setIsOpen(true);
    } else if (isOpen == true && toggleRef.current){
      // If want to close the layer when the selected object is not in the layer
      // setIsOpen(false);
    }
  }, [contextData.selected_layer_object_id])

  const waitingClick = useRef(false);

  const clickWrapper = (event, action) => {
    event.stopPropagation();

    if (waitingClick.current) return;
    waitingClick.current = true;

    action();

    setTimeout(() => {
      waitingClick.current = false;
    }, 500);
  };

  const handleClick = () => {
    $("#cf-zoom-control-percentage").trigger("click");

    const container = $(
      `#builder [data-object-type="${props.object_type}"][data-object-id="${props.object_id}"]`
    )[0];
    const focusableTarget = container ? container : canvasDiv;

    if (focusableTarget) {
      $("html, body").animate(
        {
          scrollTop: $(focusableTarget).offset().top - 100,
        },
        0
      );
    }

    if (props.onClickCallback) {
      props.onClickCallback();
    }
  };

  const handleSelect = () => {
    if (props.handleSelect) {
      props.handleSelect();
    } else {
      builder.select(
        props.object_type,
        props.object_id,
        props.tab ? props.tab : null,
        props.column ? props.column.name : null
      );

      if (props.selectCallback) {
        props.selectCallback();
      }

      handleClick();
    }
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOptionsIsOpen(false);
  };

  const toggleOptions = () => {
    setOptionsIsOpen(!optionsIsOpen);
  };

  const canSaveTemplate =
    (["steps", "sections", "elements"].includes(props.object_type) &&
      (!props.element_type ||
        templateableElementTypesArray.includes(props.element_type))) ||
    (props.object_type == "fields" &&
      ["tech", "marketing"].includes(
        contextData.objects.current_user.admin_type
      ));

  const saveTemplate = () => {
    let objectType = props.wrapper_object_type || props.object_type;

    const targetController =
      objectType == "steps"
        ? "step_templates"
        : objectType == "sections"
        ? "section_templates"
        : objectType == "elements"
        ? "element_templates"
        : objectType == "fields"
        ? "field_templates"
        : null;
    const targetObjectType =
      objectType == "steps"
        ? "Step"
        : objectType == "sections"
        ? "Section"
        : objectType == "elements"
        ? "Element"
        : objectType == "fields"
        ? "Field"
        : null;

    if (contextData.history.unsaved == true) {
      alert(
        "You have unsaved changes. Save your changes to enable saving templates."
      );
    } else {
      const baseRoute = `${window.location.origin}/accounts/${contextData.objects.website.account_id}/${targetController}/new`;
      const routeParams = `?object_type=${targetObjectType}&object_id=${props.wrapper_object_id || props.object_id}`;

      window.open(baseRoute + routeParams, "_blank");
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (props.object_type == "steps") {
        Sections.sortableLayersStart();
      }

      if (props.object_type == "sections" && !props.column) {
        Sections.sortableColumnsLayersStart(props.object);
      }

      if (props.object_type == "sections" && props.column) {
        sortElements.sortableLayersStart(props.object, props.column);
      }

      if (
        props.object_type == "elements" &&
        ["collapse", "tabs", "carousel"].includes(props.element_type)
      ) {
        ContentToggle.layersSortableStart(props.element.id);
      }

      if (props.object_type == "elements" && props.element_type == "quiz") {
        Fields.layersSortableStart(props.element.id);
      }

      if (props.object_type == "fields" && !props.object.field_id) {
        Fields.sublayersSortableStart(props.object.id);
      }
    }
  }, [isOpen, contextData.device]);

  useEffect(() => {
    if (optionsIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsIsOpen]);

  return (
    <React.Fragment>
      <div
        className={`layers-item w-full ${
          props.object_type == "variants"
            ? "overflow-x-scroll"
            : props.object_type == "steps"
            ? "pt-5 pl-1 border-solid border-0 border-slate-100 dark:border-slate-800"
            : hasCaret
            ? "pl-6"
            : `pl-8`
        }`}
        ref={node}
        data-column={props.column ? props.column.name : null}
        data-device={contextData.device}
        data-object-type={props.object_type}
        data-object-id={props.object_id}
        data-wrapper-object-type={props.wrapper_object_type}
        data-wrapper-object-id={props.wrapper_object_id}
        data-step-id={
          props.object && props.object.step_id ? props.object.step_id : null
        }
        data-element-type={props.element_type ? props.element_type : null}
        data-field-is-child={fieldIsChild}
        style={{
          borderBottomWidth: "1px",
        }}
      >
        <div
          className={`layers-item-container w-full relative flex rounded-lg h-14 px-3 pt-[8px] ${
            props.object_type !== "variants" ? "pb-2" : "-mb-3"
          } text-black dark:text-slate-300 dark:hover:text-white ${contextData.selected_layer_object_id == props.object_id && !props.column ? 'bg-slate-100 dark:bg-slate-900' : 'bg-white dark:bg-slate-950'} hover:bg-slate-100 dark:hover:bg-slate-900 cursor-pointer`}
          data-object-type={props.object_type}
          data-object-id={props.object_id}
          onMouseOver={(e) => {
            if (canvasDiv) {
              canvasDiv.classList.add("editor-select");
            }
          }}
          onMouseLeave={(e) => {
            if (canvasDiv) {
              canvasDiv.classList.remove("editor-select");
            }
          }}
        >
          {hasCaret && (
            <div
              ref={toggleRef}
              className="w-8 -ml-3 cursor-pointer text-lg center"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <FontAwesomeIcon icon={`caret-${isOpen ? "down" : "right"}`} />
            </div>
          )}
          <div
            className="layers-item-icon w-8"
            data-object-type={props.object_type}
            data-field-is-child={fieldIsChild}
          >
            {props.object_type == "variants" && (
              <svg
                width="14"
                height="14"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.86628 1.6001H3.02183C2.23636 1.6001 1.59961 2.23685 1.59961 3.02232V5.86676M5.86628 14.4001H3.02183C2.23636 14.4001 1.59961 13.7633 1.59961 12.9779V10.1334M10.1329 1.6001H12.9774C13.7629 1.6001 14.3996 2.23685 14.3996 3.02232V5.86676M14.3996 10.1334V12.9779C14.3996 13.7633 13.7629 14.4001 12.9774 14.4001H10.1329"
                  stroke={contextData.dark_mode ? "white" : "black"}
                  strokeWidth="1.25"
                  strokeLinecap="round"
                />
              </svg>
            )}

            {props.object_type == "steps" && (
              <svg
                className="-mb-1"
                width="16"
                height="20"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.38429 10.0002H14.6151M3.33301 5.8335H16.6663M8.46121 14.1668H11.5381"
                  stroke={contextData.dark_mode ? "white" : "black"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}

            {props.object_type == "sections" && !props.column && (
              <svg
                width="16"
                height="20"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mt-1"
              >
                <path
                  d="M1.59961 1.6001H14.3996M1.59961 14.4001H14.3996M3.19961 10.8001H12.7996C13.6833 10.8001 14.3996 10.0838 14.3996 9.2001V6.8001C14.3996 5.91644 13.6833 5.2001 12.7996 5.2001H3.19961C2.31595 5.2001 1.59961 5.91644 1.59961 6.8001V9.2001C1.59961 10.0838 2.31595 10.8001 3.19961 10.8001Z"
                  stroke={contextData.dark_mode ? "white" : "black"}
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}

            {props.object_type == "sections" && props.column && (
              <div className="text-lg">
                <FontAwesomeIcon
                  icon={`far fa-square`}
                  className={
                    contextData.dark_mode ? "text-white" : "text-black"
                  }
                />
              </div>
            )}

            {props.object_type == "elements" && props.element && (
              <div className="text-lg">
                {["headline", "text", "image", "button", "html", "form", "survey", "quiz", "video", "divider", "icon", "list", "tabs", "carousel", "timer", "progress", "products", "collapse", "row", "conditional_row"].includes(props.element.element_type) && (
                  <FontAwesomeIcon
                    icon={`fas ${
                      props.element.element_type == "headline"
                        ? "fa-heading"
                        : props.element.element_type == "text"
                        ? "fa-font"
                        : props.element.element_type == "image"
                        ? "fa-image"
                        : props.element.element_type == "button"
                        ? "fa-mouse-pointer"
                        : props.element.element_type == "html"
                        ? "fa-code"
                        : props.element.element_type == "form"
                        ? "fa-edit"
                        : props.element.element_type == "survey"
                        ? "fa-poll-h"
                        : props.element.element_type == "quiz"
                        ? "fa-question-circle"
                        : props.element.element_type == "video"
                        ? "fa-video"
                        : props.element.element_type == "divider"
                        ? "fa-grip-lines"
                        : props.element.element_type == "icon"
                        ? "fa-heart"
                        : props.element.element_type == "list"
                        ? "fa-list"
                        : props.element.element_type == "tabs"
                        ? "fa-folder"
                        : props.element.element_type == "carousel"
                        ? "fa-tv"
                        : props.element.element_type == "timer"
                        ? "fa-clock"
                        : props.element.element_type == "progress"
                        ? "fa-minus"
                        : props.element.element_type == "products"
                        ? "fa-shopping-cart"
                        : props.element.element_type == "collapse"
                        ? "fa-caret-square-up"
                        : props.element.element_type == "row"
                        ? "fa-square"
                        : props.element.element_type == "conditional_row"
                        ? "fa-square"
                        : ""
                    }`}
                    className={
                      contextData.dark_mode ? "text-white" : "text-black"
                    }
                  />
                )}
              </div>
            )}

            {props.object_type == "content_toggle_items" && (
              <div className="text-lg">
                <FontAwesomeIcon
                  icon={`far fa-caret-square-up`}
                  className={
                    contextData.dark_mode ? "text-white" : "text-black"
                  }
                />
              </div>
            )}

            {props.object_type == "fields" && (
              <div className="text-lg">
                <FontAwesomeIcon
                  icon={`fas fa-question`}
                  className={
                    contextData.dark_mode ? "text-white" : "text-black"
                  }
                />
              </div>
            )}
          </div>
          <div
            className="layers-item-label flex-grow text-xl overflow-hidden whitespace-nowrap text-ellipsis"
            data-object-type={props.object_type}
            data-field-is-child={fieldIsChild}
            onClick={handleClick}
          >
            {props.title}
          </div>

          {typeof props.toggleForDevice == 'function' && (
            <div
              title={`${props.hidden ? "Hidden" : "Hide"} on ${contextData.device}`}
              className="layers-item-device text-lg cursor-pointer inline mr-[20px]"
              onClick={props.toggleForDevice}
              style={{
                display: props.hidden ? "block" : "none",
              }}
            >
              <FontAwesomeIcon
                icon={`fas fa-eye${props.hidden == true ? '-slash' : ''}`}
                className={contextData.dark_mode ? "text-white" : "text-black"}
              />
            </div>
          )}

          <div
            className="layers-item-options cursor-pointer absolute float-right right-[5px]"
            style={{ display: "none" }}
          >
            {showOptionsCommands ? (
              <div className="w-full -mt-[2px]" onClick={toggleOptions}>
                <FontAwesomeIcon icon="fas fa-ellipsis-h" />
              </div>
            ) : (
              <div className="w-full" onClick={handleSelect}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                    stroke={contextData.dark_mode ? "white" : "black"}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>

          {optionsIsOpen && (
            <div
              className={`w-175 h-[38px] cursor-pointer absolute overflow-hidden right-0 top-10 p-0 z-10 bg-white dark:bg-black border-solid border-white dark:border-slate-800 shadow-md`}
              data-object_type={object_type}
              data-object_id={object_id}
              style={{ borderRadius: "10px" }}
            >
              <div className={`flex items-center`}>
                {showOptionsCommands ? (
                  <React.Fragment>
                    {props.hideMoveCommands !== true && (
                      <React.Fragment>
                         <button
                            title="Move Up"
                            className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                            onClick={(e) => {
                              clickWrapper(e, () => {
                                dispatchCustomEvent("moveObjectUp", {
                                  object_type: props.wrapper_object_type || object_type,
                                  object_id: props.wrapper_object_id || object_id,
                                  editing: false,
                                });
                              });
                            }}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.75 8L9 3M9 3L14.25 8M9 3V15"
                                stroke={contextData.dark_mode ? "white" : "black"}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>

                          <button
                            title="Move Down"
                            className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                            onClick={(e) => {
                              clickWrapper(e, () => {
                                dispatchCustomEvent("moveObjectDown", {
                                  object_type: props.wrapper_object_type || object_type,
                                  object_id: props.wrapper_object_id || object_id,
                                  editing: false,
                                });
                              });
                            }}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.75 10L9 15M9 15L14.25 10M9 15V3"
                                stroke={contextData.dark_mode ? "white" : "black"}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                      </React.Fragment>
                    )}

                    <button
                      title="Edit"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={handleSelect}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    
                    <button
                      title="Duplicate"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={(e) => {
                        clickWrapper(e, () => {
                          dispatchCustomEvent("duplicateObject", {
                            object_type: props.wrapper_object_type || props.object_type,
                              object_id: props.wrapper_object_id || props.object_id,
                          });

                          setTimeout(() => {
                            if(props.onDuplicateCallback) {
                              props.onDuplicateCallback();
                            }
                          }, 1000);
                        });
                      }}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.25 9.84375L14.25 4.49994C14.25 3.25729 13.2426 2.24992 12 2.24994L6.65625 2.25M9.75 15.75L4.6875 15.75C3.75552 15.75 3 14.9945 3 14.0625L3 6.75C3 5.81802 3.75552 5.0625 4.6875 5.0625L9.75 5.0625C10.682 5.0625 11.4375 5.81802 11.4375 6.75L11.4375 14.0625C11.4375 14.9945 10.682 15.75 9.75 15.75Z"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>

                    {canResetStyles && (
                      <button
                        title="Reset To Theme Styles"
                        className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                        onClick={(e) => {
                          clickWrapper(e, () => {
                            dispatchCustomEvent("resetStyles", {
                              object_type: props.wrapper_object_type || props.object_type,
                                object_id: props.wrapper_object_id || props.object_id,
                            });
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M4.87115 14.5C5.88914 17.6939 8.80463 20 12.2424 20C16.5268 20 20 16.4183 20 12C20 7.58172 16.5268 4 12.2424 4C9.37103 4 6.86399 5.60879 5.52267 8M7.87879 9H4V5"
                            stroke={contextData.dark_mode ? `white` : "black"}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}

                    {canSaveTemplate && (
                      <button
                        title="Save To Templates"
                        className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                        onClick={saveTemplate}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.9375 19.875V14.8125C6.9375 14.1912 7.44118 13.6875 8.0625 13.6875H15.9375C16.5588 13.6875 17.0625 14.1912 17.0625 14.8125V20.4375M17.0625 4.125V6.375C17.0625 6.99632 16.5588 7.5 15.9375 7.5L8.0625 7.5C7.44118 7.5 6.9375 6.99632 6.9375 6.375L6.9375 3M20.4351 6.93513L17.0649 3.56487C16.7032 3.20319 16.2127 3 15.7012 3H4.92857C3.86344 3 3 3.86344 3 4.92857V19.0714C3 20.1366 3.86344 21 4.92857 21H19.0714C20.1366 21 21 20.1366 21 19.0714V8.29883C21 7.78734 20.7968 7.2968 20.4351 6.93513Z"
                            stroke={contextData.dark_mode ? "white" : "black"}
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </button>
                    )}

                    <button
                      title="Remove"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={(e) => {
                        clickWrapper(e, () => {
                          dispatchCustomEvent("removeObject", {
                            object_type: props.wrapper_object_type || props.object_type,
                            object_id: props.wrapper_object_id || props.object_id,
                          });

                          setTimeout(() => {
                            if(props.onDuplicateCallback) {
                              props.onDuplicateCallback();
                            }
                          }, 1000);
                        });
                      }}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 4.63235H15M6.75 2.25H11.25M7.5 12.5735V7.80882M10.5 12.5735V7.80882M11.625 15.75H6.375C5.54657 15.75 4.875 15.0389 4.875 14.1618L4.53255 5.45953C4.5148 5.00837 4.85544 4.63235 5.2819 4.63235H12.7181C13.1446 4.63235 13.4852 5.00837 13.4674 5.45953L13.125 14.1618C13.125 15.0389 12.4534 15.75 11.625 15.75Z"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </React.Fragment>
                ) : (
                  <button
                    title="Edit"
                    className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                    onClick={handleSelect}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                        stroke={contextData.dark_mode ? "white" : "black"}
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {isOpen && (
          <React.Fragment>
            <div className="w-full" style={{ minWidth: "150px" }}>
              {props.children}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderPanelLayersItem;
