class ElementWarningsService {
  static getWarning(element, variant, step = null) {
    if (!element || !variant) return undefined;
    if (step == null) {
      const section = variant.sections[element?.section_id];
      step = variant.steps[section?.step_id];
    }
    if (!step) return undefined;
    if (element.toBeDeleted) return undefined;

    const conversionElements = [
      "button",
      "form",
      "survey",
      "quiz",
      "products",
      "product_hero",
    ];
    if (!conversionElements.includes(element.element_type)) return undefined;

    const belongsToFunnelStep = Object.values(variant.sections)
      .filter((section) => section.step_id == step.id && section.toBeDeleted !== true)
      .map((section) => section.id)
      .includes(element.section_id);

    if (!belongsToFunnelStep) return undefined;

    let warning = this.getConfirmationWarning(element, variant);

    if (["products", "product_hero"].includes(element.element_type)) {
      warning = this.getProductWarning(element);
    }

    return warning;
  }

  static getConfirmationWarning(element, variant) {
    const confirmationType = element.options["confirmation-type"];

    switch (confirmationType) {
      case "step": {
        const redirectStep = element.options["redirect-step"];
        if (!redirectStep) return "Step to jump to not yet set";

        if (redirectStep !== "previous") {
          const validStepExists = Object.values(variant.steps).some(
            (s) =>
              s.id.toString() === redirectStep && s.toBeDeleted !== true
          );

          if (!validStepExists) return "Step to jump to not yet set";
        }
        break;
      }
      case "redirect": {
        if (
          !element.options["redirect-url"] &&
          element.options["confirmation-close"] !== "true"
        ) {
          return "Redirect URL not yet set";
        }
        break;
      }
      case "cta": {
        if (!element.options["confirmation-cta"])
          return "Campaign to display not yet set";
        break;
      }
      case "sms": {
        if (!element.options["sms-number"])
          return "SMS opt-in information incomplete";
        break;
      }
      case "cart":
      case "checkout": {
        if (!['products', 'product_hero'].includes(element.element_type)) {
          if (!element.options["selected-store-integration"])
            return "No ecommerce platform selected";

          if (element.options["selected-store-integration"] !== null && !element.options["selected-product-variant-ids"])
            return "No product variants selected to add to cart"
        }
        break;
      }
      default:
        break;
    }

    return undefined;
  }

  static getProductWarning(element) {
    const confirmationType = element.options["confirmation-type"];
    const actionAfterAddToCart = element.options["products-button-action-after-add-to-cart"];

    if (confirmationType !== "cart") return undefined;

    switch (actionAfterAddToCart) {
      case "step": {
        if (!element.options["products-button-redirect-step"])
          return "Step to jump to not yet set";
        break;
      }
      case "redirect": {
        if (!element.options["products-button-redirect-url"])
          return "Redirect URL not yet set";
        break;
      }
      case "cta": {
        if (!element.options["products-button-confirmation-cta"])
          return "Campaign to display not yet set";
        break;
      }
      default:
        break;
    }

    return undefined;
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ElementWarningsService = ElementWarningsService;
}
