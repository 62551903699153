import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import ColorPicker from "react-best-gradient-color-picker";

const CFColorPicker = (props) => {
  const website = props.website;
  const placeholder = props.placeholder || "#000000";
  const name = props.name || "";
  const allowGradient = props.allowGradient || false;

  let brand_color_light = "#ffffff";
  if (website.data["brand_color_light"]) {
    brand_color_light = website.data["brand_color_light"];
  }

  let brand_color_vibrant = "#0D42E4";
  if (website.data["brand_color_vibrant"]) {
    brand_color_vibrant = website.data["brand_color_vibrant"];
  }

  let brand_color_dark = "#000000";
  if (website.data["brand_color_dark"]) {
    brand_color_dark = website.data["brand_color_dark"];
  }

  const presetColors = props.presetColors || [
    brand_color_light,
    brand_color_vibrant,
    brand_color_dark,
    "#ffffff",
    "#0D42E4",
    "#000000",
    "#808080",
    "#c0c0c0",
    "#000080",
    "#0000ff",
    "#00ffff",
    "#008000",
    "#00ff00",
    "#800000",
    "#ff0000",
    "#ff00ff",
    "#800080",
    "#af33f2",
    "#f0672e",
    "#ffff00",
  ]

  const triggerRef = useRef(null);
  const pickerRef = useRef(null);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });

  const [originalValue, setOriginalValue] = useState(props.value ? props.value : "#000000");
  const [defaultValue, setDefaultValue] = useState(props.value ? props.value : "#000000");
  const [pickerVisible, setPickerVisible] = useState(false);

  const updatePickerPosition = () => {
    if (triggerRef.current && pickerVisible) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const pickerWidth = 250;
      const pickerHeight = 385;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      let top = triggerRect.bottom + window.scrollY;
      let left = triggerRect.left + window.scrollX;

      if (top + pickerHeight > windowHeight + window.scrollY) {
        top = triggerRect.top + window.scrollY - pickerHeight;
      }

      if (left + pickerWidth > windowWidth) {
        left = windowWidth - pickerWidth - 20;
      }

      setPickerPosition({ top, left });
    }
  };

  const handleClickOutside = (e) => {
    if (
      triggerRef.current?.contains(e.target) ||
      pickerRef.current?.contains(e.target) ||
      e.target.classList.contains("input-color-picker-toggle")
    ) {
      return;
    }
    setPickerVisible(false);
  };

  const updateColor = (newValue) => {
    setDefaultValue(newValue);

    if (!newValue || newValue !== originalValue) {
      setOriginalValue(newValue);
      props.onChange?.(newValue);
    }
  };

  const handleInputChange = (e) => {
    let newValue = e.target.value;
    if (
      [
        "brand_color_dark",
        "brand_color_light",
        "brand_color_vibrant",
        "BLACK",
        "MEDIUMBLUE",
        "WHITE",
      ].indexOf(newValue) == -1
    ) {
      if (newValue.indexOf("#") > -1) {
        newValue = newValue.replace("#", "");
      }
      if (newValue && newValue.indexOf("#") == -1 && newValue.indexOf("(") == -1) {
        newValue = "#" + newValue;
      }
    }
    updateColor(newValue);
  };

  useEffect(() => {
    setOriginalValue(props.value ? props.value : "");
    setDefaultValue(props.value ? props.value : "");
  }, [props.value]);

  useEffect(() => {
    if (pickerVisible) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("scroll", updatePickerPosition, true);
      window.addEventListener("resize", updatePickerPosition);
      updatePickerPosition();
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", updatePickerPosition, true);
      window.removeEventListener("resize", updatePickerPosition);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", updatePickerPosition, true);
      window.removeEventListener("resize", updatePickerPosition);
    };
  }, [pickerVisible]);

  return (
    <React.Fragment>
      <div ref={triggerRef}>
        <input
          type="text"
          name={name}
          value={defaultValue}
          className="w-full p-3 pl-[40px] bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0"
          placeholder={placeholder}
          onChange={handleInputChange}
        />

        <div
          className="position-relative w-[24px] h-[24px] cursor-pointer mt-[-29px] ml-[6px] rounded-md !border-slate-200 dark:!border-slate-700"
          style={{
            background: defaultValue || placeholder
          }}
          onClick={() => setPickerVisible(!pickerVisible)}
        />
      </div>

      {pickerVisible && createPortal(
        <div
          ref={pickerRef}
          className="fixed z-[9999] w-[250px] bg-slate-800 rounded-lg p-5 shadow-lg"
          style={{
            top: `${pickerPosition.top}px`,
            left: `${pickerPosition.left}px`
          }}
        >
          <ColorPicker
            value={defaultValue || placeholder}
            onChange={updateColor}
            width={225}
            height={150}
            hideAdvancedSliders={true}
            hideColorTypeBtns={!allowGradient}
            hideColorGuide={true}
            hideInputType={true}
            presets={presetColors}
          />
        </div>,
        document.body
      )}
    </React.Fragment>
  );
};

export default CFColorPicker;
