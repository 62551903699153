export const tabThemeSettings = {
  "tabs-layout": null,
  "tabs-alignment": null,
  "tabs-width": null,
  "tabs-width-mobile": null,
  "tabs-border-style": null,
  "tabs-border-color": null,
  "tabs-border-color-hover": null,
  "tabs-border-color-active": null,
  "tabs-border-width": null,
  "tabs-border-width-left": null,
  "tabs-border-width-top": null,
  "tabs-border-width-bottom": null,
  "tabs-border-width-right": null,
  "tabs-radius": null,
  "tabs-radius-top-left": null,
  "tabs-radius-top-right": null,
  "tabs-radius-bottom-right": null,
  "tabs-radius-bottom-left": null,
  "tabs-padding-style": null,
  "tabs-padding-value": null,
  "tabs-padding-style-mobile": null,
  "tabs-padding-value-mobile": null,
  "tabs-background-color": null,
  "tabs-background-color-hover": null,
  "tabs-background-color-active": null,
  "tabs-font-family": null,
  "tabs-font-weight": null,
  "tabs-font-size": null,
  "tabs-font-size-mobile": null,
  "tabs-font-color": null,
  "tabs-font-color-hover": null,
  "tabs-font-color-active": null,
  "tabs-content-spacing-horizontal": null,
  "tabs-content-spacing-vertical": null,
  "tabs-content-spacing-horizontal-mobile": null,
  "tabs-content-spacing-vertical-mobile": null,
  "tabs-content-background-color": null,
  "tabs-content-border-style": null,
  "tabs-content-border-color": null,
  "tabs-content-border-width": null,
  "tabs-content-border-width-left": null,
  "tabs-content-border-width-top": null,
  "tabs-content-border-width-bottom": null,
  "tabs-content-border-width-right": null,
  "tabs-content-radius": null,
  "tabs-content-radius-top-left": null,
  "tabs-content-radius-top-right": null,
  "tabs-content-radius-bottom-right": null,
  "tabs-content-radius-bottom-left": null
}; 