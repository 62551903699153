import React, { useState, useEffect, useRef } from "react";
import withLazyLoad from "../../utils/withLazyLoad";
const LazyReportCount = withLazyLoad(() => import("../reports/ReportCount"));
import FieldOptionReport from "../field_options/FieldOptionReport";
import ProductReport from "../products/ProductReport";
import ProductValueReport from "../products/ProductValueReport";
import updateAOV from "../../utils/updateAOV";
import FieldReport from "../fields/FieldReport";
import ContentToggleReport from "../content_toggles/ContentToggleReport";
import htmlToLabel from "../../utils/htmlToLabel";

const ElementReport = (props) => {
  const element = props.element;
  const field = element.element_type === "survey" ? element.fields[0] : null;
  const fields = element.fields
    .filter((field) => !field.field_id && field.field_type !== "empty")
    .sort((a, b) => a.position - b.position);
  const html = ["form", "button", "products", "product_hero"].includes(
    element.element_type
  )
    ? htmlToLabel(element.html)
    : null;

  const calculationConversionRates = (element) => {
    const conversionsSpan = document.getElementById(
      "element-" + element.id + "-conversions"
    );
    const conversions = parseInt($(conversionsSpan).text().replaceAll(",", ""));

    $(`.element-selection-report[data-element-id="${element.id}"]`).each(
      (i, elementSelectionReportDiv) => {
        const selectionsSpan = $(elementSelectionReportDiv).find(
          ".events-report-count"
        )[0];
        const conversionRateElement = $(elementSelectionReportDiv).find(
          ".conversion-rate"
        )[0];
        const conversionBarElement = $(elementSelectionReportDiv).find(
          ".element-selection-report-bar"
        )[0];

        const selections = parseInt(
          $(selectionsSpan).text().replaceAll(",", "")
        );
        let conversionRateValue =
          selections > 0 && conversions > 0
            ? (selections / conversions) * 100
            : 0;

        if (conversionRateValue > 100) {
          conversionRateValue = 100.0;
        }

        $(conversionRateElement).text(conversionRateValue.toFixed(2));

        $(conversionBarElement)
          .css("width", `${conversionRateValue}%`)
          .attr("aria-valuenow", conversionRateValue);

        if (element.element_type !== "quiz") {
          $(elementSelectionReportDiv).css("order", selections);
        }
      }
    );
  };

  useEffect(() => {
    if (['conditional_row', 'tabs', 'carousel', 'collapse'].includes(element.element_type)) {
      const elementReport = $(`.element-report[data-element-id="${element.id}"]`);
      const hasElementReports = $(elementReport).find(`.element-selection-report[data-has-reports="true"]`).length > 0;

      if (!hasElementReports) {
        $(elementReport).css("display", "none");
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="row hard-center element-report" data-element-id={element.id}>
        <div className="col-xs-12 no-padding-sides">
          <h4 className="text-2xl bold">
            <span style={{ textTransform: "capitalize" }}>
              {element.element_type.replace("_", " ")}{" "}
              {field ? ` - ${field.slug}` : html ? ` - ${html}` : ""}
            </span>
          </h4>
          {props.hide_stats !== true && (
            <p className="light">
              <LazyReportCount
                inline={true}
                id={`element-${element.id}-conversions`}
                path={`/websites/${props.cta.website_id}/events_reports`}
                payload={{
                  report_type: "distinct_count",
                  report: {
                    website_id: props.cta.website_id,
                    event_type: "Complete",
                    contactable_type: "Cta",
                    contactable_id: props.cta.id,
                    variant_id: props.step.variant_id,
                    step_id: props.step.id,
                    element_id: element.id,
                    range_start: props.range_start,
                    range_end: props.range_end,
                    field: null,
                    broadcast_id: null,
                  },
                }}
                callback={() => {
                  calculationConversionRates(element);
                }}
              />{" "}
              {["form", "survey", "quiz"].includes(element.element_type)
                ? "submitted"
                : ["products", "product_hero"].includes(element.element_type)
                ? "people selected products"
                : "clicks"}
              .{" "}
              {!props.gateReports && (
                <React.Fragment>
                  <LazyReportCount
                    id={`element-${element.id}-orders`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "count",
                      report: {
                        website_id: props.cta.website_id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        field: null,
                        value: null,
                        product_id: null,
                        broadcast_id: null,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `#element-${element.id}-orders`,
                        `#element-${element.id}-revenue`,
                        `#element-${element.id}-aov`
                      );
                    }}
                  />{" "}
                  orders with an average value of {props.currency}
                  <span id={`element-${element.id}-aov`}>0.00</span> (Total:{" "}
                  <LazyReportCount
                    id={`element-${element.id}-revenue`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "revenue",
                      report: {
                        website_id: props.cta.website_id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        field: null,
                        value: null,
                        product_id: null,
                        broadcast_id: null,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `#element-${element.id}-orders`,
                        `#element-${element.id}-revenue`,
                        `#element-${element.id}-aov`
                      );
                    }}
                    currency={props.currency}
                  />
                  )
                </React.Fragment>
              )}
            </p>
          )}
        </div>
        {["quiz", "form", "survey"].includes(element.element_type) && (
          <div
            className="col-xs-12 no-padding-sides"
            style={{
              width: "100%",
            }}
          >
            {fields
              .filter(
                (field) =>
                  [
                    "survey",
                    "dropdown",
                    "radio",
                    "select",
                    "range",
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "radio",
                    "checkbox",
                  ].includes(field.field_type) ||
                  (field.field_type == "range" &&
                    field.options["range-type"] == "increments")
              )
              .map((field) => {
                return (
                  <React.Fragment key={`field-${field.id}`}>
                    <FieldReport
                      field={field}
                      calculationConversionRates={() => {
                        calculationConversionRates(element);
                      }}
                      {...props}
                    />

                    {element.element_type == "quiz" && !field.field_id && (
                      <div
                        className="row hard-center"
                        style={{ paddingLeft: "30px" }}
                      >
                        {element.fields
                          .filter(
                            (child_field) => child_field.field_id == field.id
                          )
                          .sort((a, b) => a.position - b.position)
                          .map((child_field) => {
                            return (
                              <React.Fragment key={`field-${child_field.id}`}>
                                <FieldReport
                                  field={child_field}
                                  calculationConversionRates={() => {
                                    calculationConversionRates(element);
                                  }}
                                  {...props}
                                />
                              </React.Fragment>
                            );
                          })}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        )}
        {["products", "product_hero"].includes(element.element_type) && (
          <React.Fragment>
            {(!element.options["products-source"] ||
              element.options["products-source"].toLowerCase() ==
                "convertflow") && (
              <div
                className="col-xs-12 no-padding-sides"
                style={{
                  display: "flex",
                  width: "100%",
                  flexBasis: "100%",
                  flexWrap: "wrap",
                  flexDirection: "column-reverse",
                }}
              >
                {element.products.map((product) => {
                  return (
                    <React.Fragment key={`product-${product.id}`}>
                      <ProductReport
                        key={`product-report-${product.id}`}
                        product={product}
                        label={
                          product.title
                            ? product.title
                            : `Product ${product.position || product.id}`
                        }
                        calculationConversionRates={() => {
                          calculationConversionRates(element);
                        }}
                        {...props}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            {["Shopify", "ShopifyGraphql"].includes(
              element.options["products-source"]
            ) && (
              <div
                className="col-xs-12 no-padding-sides"
                style={{
                  display: "flex",
                  width: "100%",
                  flexBasis: "100%",
                  flexWrap: "wrap",
                  flexDirection: "column-reverse",
                }}
              >
                {props.externalProductValues.map((externalProductValue) => {
                  return (
                    <ProductValueReport
                      key={`product-value-report-${externalProductValue[0]}`}
                      label={externalProductValue[0]}
                      value={externalProductValue[0]}
                      calculationConversionRates={() => {
                        calculationConversionRates(element);
                      }}
                      {...props}
                    />
                  );
                })}
              </div>
            )}
          </React.Fragment>
        )}
        {["collapse", "tabs", "carousel", "conditional_row"].includes(element.element_type) && (
          <div
            className="col-xs-12 no-padding-sides"
            style={{
              width: "100%",
            }}
          >
            {Object.values(element.content_toggle_items).map((toggle_item) => {
              return (
                <React.Fragment key={`toggle-item-${toggle_item.id}`}>
                  <ContentToggleReport
                    toggle_item={toggle_item}
                    element={element}
                    calculationConversionRates={() => {
                      calculationConversionRates(element);
                    }}
                    {...props}
                  />
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ElementReport;
