import React from "react";

import { RemixBuilderProvider } from "./RemixBuilderContext";
import CtaRemixBuilder from "./CtaRemixBuilder";

const RemixBuilderWrapper = (props) => {
  const {
    type = 'cta',
    website,
    environment,
    PYTHON_SERVICE_URL,
    PYTHON_SERVICE_AUTH_TOKEN,
  } = props;

  return (
    <RemixBuilderProvider
      website={website}
      environment={environment}
      PYTHON_SERVICE_URL={PYTHON_SERVICE_URL}
      PYTHON_SERVICE_AUTH_TOKEN={PYTHON_SERVICE_AUTH_TOKEN}
    >
      {type === 'cta' && <CtaRemixBuilder />}
    </RemixBuilderProvider>
  );
};

export default RemixBuilderWrapper;
